/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

#pageHeaderContainer.setupPageHeader_authentication_provider {
  flex-direction: row;
  label {
    font-size: 0.875em;
    .labelWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.5em;
      .ratingBadge {
        margin-left: 0;
        margin-right: 1em;
        font-size: 0.875em;
      }
      span {
        color: $darkBlue;
        font-weight: 400;
        font-size: 1em;
      }
    }
  }
}

.setupRecordItem.authentication_provider.item.alternateLayout {
  grid-template-columns: calc( 100% - 10em ) 10em !important;
  .mainDetailsSection {
    .column {
      span {
        color: $grey;
      }
      h2 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          margin: 0;
          margin-right: 0.25em;
        }
        .ratingBadge {
          margin-left: 0.75em;
          font-size: 0.75em;
        }
      }
    }
  }
}