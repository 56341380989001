/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.exploitStatusWrapper {
  position: relative;
  &.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5em;
    strong, span {
      margin-right: 0.5em;
      order: 1;
      &.exploitStatusIcons {
        order: 2;
        margin-right: 0;
      }
      &.statusTitle {
        margin-top: -0.25em;
        order: 3;
      }
    }
  }
  .exploitStatusIcons {
    display: block;
    height: 1.25em;
    font-weight: 600;
    .inlineSVG {
      margin-right: 0.5em;
      height: 1.25em;
      width: 1.25em;
      circle {
        fill: $grey--divider;
      }
      path {
        fill: #fff;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    &.published_details {
      .inlineSVG:first-of-type {
        circle {
          fill: $risk--yellow;
        }
      }
    }
    &.poc {
      .inlineSVG:nth-of-type(1), .inlineSVG:nth-of-type(2) {
        circle {
          fill: $risk--orange;
        }
      }
    }
    &.weaponized {
      .inlineSVG {
        circle {
          fill: $risk--red;
        }
      }
    }
  }
  .helpTrigger {
    width: 1em;
    height: 1em;
    .inlineSVG {
      width: 1em;
      height: 1em;
    }
  }
}
