/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.inlineSVG {
  &.light {
    rect, line {
      stroke: #fff;
    }
    path {
      fill: #ffffff;
    }
  }
  &.dark {
    rect, line {
      stroke:  $darkBlue;
    }
    path {
      fill: $darkBlue;
    }
  }
  &.default {
    rect, line {
      stroke: $grey--icon;
    }
    path {
      fill: $grey--icon;
    }
  }
  &.grey {
    rect, line {
      stroke: $grey;
    }
    path {
      fill: $grey;
    }
  }
  &.primary {
    rect, line {
      stroke: $primaryBlue;
    }
    path {
      fill: $primaryBlue;
    }
  }
  &.status--red {
    rect, line {
      stroke: $status--red;
    }
    path {
      fill: $status--red;
    }
  }
  &.status--orange {
    rect, line {
      stroke: $status--orange;
    }
    path {
      fill: $status--orange;
    }
  }
  &.status--green {
    rect, line {
      stroke: $status--green;
    }
    path {
      fill: $status--green;
    }
  }
  &.status--blue {
    rect, line {
      stroke: $status--blue;
    }
    path {
      fill: $status--blue;
    }
  }
  &.risk--green {
    rect, line {
      stroke: $risk--green;
    }
    path {
      fill: $risk--green;
    }
  }
  &.risk--red {
    rect, line {
      stroke: $risk--red;
    }
    path {
      fill: $risk--red;
    }
  }
  &.inlineLink {
    transition: all 0.1s linear;
    width: 1em !important;
    height: 1em !important;
    margin-left: 0.3em !important;
    flex-shrink: 0;

    &.darkBlue {
      color: $primaryBlue--dark;
      path {
        fill: $primaryBlue--dark;
      }
    }
    &.primaryBlue {
      color: $primaryBlue;
      opacity: 1 !important; // because of helpItem override
      path {
        fill: $primaryBlue;
      }
    }
    &.white {
      color: white;
      path {
        fill: white;
      }
    }
  }
}

.inlineLinkWrapper {
  display: inline-flex;
  flex-direction: row;

  .inlineLink {
    align-self: center;
    margin-left: 0.2em
  }
}