/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.startButtonWrapper {
  position: relative;
  .additionalShade {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .additionalContainer {
    position: absolute;
    background: #fff;
    z-index: 2;
    padding: 1em;
    box-shadow: $boxShadow--heavy;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    width: 32em;
    // top: 3em;
    // right: 0em;
    h4 {
      font-size: 1em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      margin-bottom: 1em;
      .roundGlyphButton {
        .inlineSVG {
          width: 1.5em !important;
          height: 1.5em !important;
          margin: 0 !important;
        }
      }
    }
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      margin-bottom: 1em;
      .checkboxFieldWrapper {
        flex-shrink: 0;
      }
      span {
        margin-left: 1em;
      }
    }
    .actionsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
    button {
      &.additionalCancelButton {
        @include dsButton('minimal');
        margin-right: 1em;
      }
      &.additionalButton {
        @include dsButton('primary');
      }
    }
  }
}

.taskRunnerButton {
  @include dsButton( 'minimal-dark' );
  border-radius: $borderRadius--input;
  transition: all 0.3s ease-in-out;
  position: relative;
  .inlineSVG {
    width: 1.5em !important;
    height: 1.5em !important;
    margin-right: 0.5em !important;
    &.queued {
      display: none;
    }
    path {
      fill: $darkBlue;
    }
  }
  .queuedLabel {
    display: none;
    color: $grey--icon;
  }
  .warningDot {
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    background: $status--red;
    border-radius: $borderRadius--full;
    border: 2px solid #fff;
    top: -0.375em;
    right: -0.375em;
  }
  span {
    font-weight: 600 !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1em !important;
  }
  &.running {
    @include dsButton( 'minimal-default' );
    border-radius: $borderRadius--input;
    cursor: default;
    pointer-events: none;
    opacity: 0.6;
    .inlineSVG {
      &.running {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }
      path {
        fill: $grey;
      }
      &.stop {
        display: none;
      }
    }
    &.isStoppable, &.stopAllowed {
      cursor: pointer;
      pointer-events: unset;
      &:hover {
        opacity: 1;
        .inlineSVG {
          &.stop {
            display: block;
          }
          &.running {
            display: none;
          }
        }
      }
    }
    .queuedLabel {
      display: none;
      color: $grey--icon !important;
    }
    &.disabled, &:disabled {
      color: $grey !important;
    }
  }
  &.queued {
    @include dsButton( 'minimal-default' );
    background: $grey--background;
    border-radius: $borderRadius--input;
    pointer-events: none;
    color: $grey--icon;
    .queuedLabel {
      display: block;
      color: $grey--icon !important;
    }
    .taskStartLabel {
      display: none;
    }
    .inlineSVG {
      &.queued {
        display: block;
      }
      &.start {
        display: none;
      }
      path {
        fill: $grey--icon;
      }
    }
  }
  &.download {
    @include dsButton( 'minimal-green' );
    border-radius: $borderRadius--input;
    .inlineSVG {
      path {
        fill: $risk--green;
      }
    }
  }
  &.rerun {
    .inlineSVG {
      &.running {
        display: none;
      }
    }
  }
  &.disabled, &:disabled {
    @include dsButton( 'minimal-default' );
    pointer-events: none;
    cursor: default;
    background: $grey--background;
    border-radius: $borderRadius--input;
    pointer-events: none;
    color: $grey--icon !important;
    .queuedLabel {
      display: none;
    }
    .taskStartLabel {
      color: $grey--icon !important;
    }
    .inlineSVG {
      path {
        fill: $grey--icon;
      }
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}
