/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.graphModelEdgeGroup {
  .graphEdgePath, .edgeArrow, .edgeHoverPath {
    cursor: pointer;
  }
  .edgeArrowDirection {
    cursor: pointer;
    transform-origin: center;
    &.n {
      transform: rotate(-90deg);
    }
    &.ne {
      transform: rotate(-45deg);
    }
    &.e {
      transform: rotate(0deg);
    }
    &.se {
      transform: rotate(45deg);
    }
    &.s {
      transform: rotate(90deg);
    }
    &.sw {
      transform: rotate(135deg);
    }
    &.w {
      transform: rotate(180deg);
    }
    &.nw {
      transform: rotate(225deg);
    }
  }
}