/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.svgBarWrapper {
  svg {
    width: 100%;
    height: 100%;
    .fullBarWrapper {
      cursor: default;
      .barContainer {
        fill-opacity: 0.5;
      }
      &.clickable {
        .barContainer {
          fill-opacity: 1;
        }
        cursor: pointer;
      }
      &:hover, &.isHovered {
        &.clickable {
          .barContainer {
            fill: $grey--divider;
          }
          .barSegment {
            opacity: 0.5;
          }
        }
      }
    }
  }
}