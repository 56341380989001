/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../styles/globals.scss" as *;

.modalContainer.remediation_plan {
  padding-bottom: 4em;
  background: none;
  box-shadow: none;
  .modalHeader {
    padding: 0;
    position: relative;
    h2 {
      display: none;
    }
    button {
      position: absolute;
      top: 4.25em;
      right: 2em;
    }
  }
  .modalBody {
    padding: 0;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    overflow: hidden;
    .tabWrapper {
      padding: 2em;
      height: 100%;
      background: #fff;
      margin: 0;
      border-radius: 0 $borderRadius 0 0;
      border-top: none;
      h2 {
        font-size: 1.125em;
        padding-bottom: 0;
        margin-bottom: 1.5em;
        grid-column: 1 / 3;
      }
      .stepDirections {
        background: $grey--background;
        padding: 1em 1.5em;
        border-radius: $borderRadius;
        grid-column: 1 / 3;
        margin-bottom: 1.75em;
      }
    }
  }
  .loadingContainerWrapper {
    min-height: 20em;
  }

  .modalActions {
    .stepText {
      position: fixed;
      bottom: 1.75em;
      left: 50vw;
      text-align: center;
      width: 6em;
      margin-left: -3em;
      font-weight: 600;;
    }
    .buttonsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      button {
        @include dsButton('minimal-dark');
        .inlineSVG {
          margin-right: 0;
          margin-left: 0.5em;
        }
        &:last-of-type {
          @include dsButton('dark');
          margin-left: 1em;
          .inlineSVG {
            margin-right: 0;
            margin-left: 0.5em;
          }
          &.disabled {
            cursor: default;
            pointer-events: none;
          }
        }
      }
    }
  }
}