/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.sensitiveAssetItemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .nodeIconWrapper {
      width: 2em;
      height: 2em;
      border-radius: 9999em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: $grey--background;
      margin: 0;
      padding: 0;
      margin-right: 0.5em;
      .recordTypeHeaderIcon {
        width: 1.25em;
        height: 1.25em;
      }
    }
    .labelAndType {
      span.type {
        margin-top: 0.25em;
        color: $grey;
        text-transform: uppercase;
        font-size: 0.875em;
      }
    }
  }
  .right {
    flex-shrink: 0;
    margin-left: 0.25em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    label {
      margin-bottom: 0.25em;
      color: $grey;
      font-size: 0.875em;
    }
    strong {
      color: $status--red;
    }
  }
  &.standAlone {
    padding: 0.5em 0.75em;
    border: 1px solid $grey--divider;
    border-radius: $borderRadius;
  }
}