/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.recordCardContainer.recordCardType_node {
  .contentGridWrapper {
    .mainContent {
      .recordCardContent {
        ul {
          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            .inlineSVG {
              flex-shrink: 0;
              width: 1.25em;
              height: 1.25em;
              margin-right: 0.5em;
              path {
                fill: $status--red;
              }
            }
          }
        }
      }
    }
  }
}