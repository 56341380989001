/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.modalContainer.reactSetupModal.vulnerability_source {
  max-height: 80vh;
  min-height: 80vh;
  .modalBody {
    .formWrapper {
      .fieldGroupWrapper {
        &.selector {
          .contentBlock {
            img.recordTypeHeaderIcon.full {
              width: 50%;
              display: block;
              margin: 0em auto 1em auto;
            }
            margin-bottom: 0em;
          }
        }
      }
    }
  }
}

.setupRecordItem.vulnerability_source.item.alternateLayout {
  grid-template-columns: 1fr 18em !important;
  .logoWrapper {
    width: 3.5em;
    height: 3.5em;
    border-radius: $borderRadius--full;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: $grey--background;
    margin-right: 1.5em;
    img, .inlineSVG {
      margin: 0;
    }
  }
  section.mainDetailsSection {
    .column {
      // border-left: 1px solid $grey--divider;
      // padding-left: 1em;
      span {
        color: $darkBlue;
        font-weight: 400;
      }
    }
  }
  .itemActions {
    .taskRunnerButton {
      margin-left: 1em;
    }
  }
}