/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../styles/globals.scss" as *;

.modalContainer.remediationExportModal {
  .modalBody {
    padding: 0em 2em 5em 2em;
    // overflow: hidden;
    h2 {
      margin-bottom: 1em;
    }
    .tableActionsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 1em;
    }
    .toggleExportedButton {
      @include dsButton('minimal-primary');
      margin: 0;
      .inlineSVG {
        margin-left: 0.5em;
      }
    }
    .exportRemediationTicketsTableWrapper {
      position: relative;
      width: calc( 100% + 4em );
      margin: 0 -2em;
      .exportRemediationTicketsTable {
        .tableRow {
          grid-template-columns: 3em 1fr 1fr 1fr 1fr;
          .tableCell {
            flex-grow: 0;
            flex-shrink: 0;
            width: 100%;
            .noOptions {
              text-align: center;
              justify-content: space-around;
              width: 100%;
              color: $grey;
            }
            h3 {
              font-weight: 600;
              font-size: 1em;
              margin: 0;
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              strong {
                line-height: 1em;
              }
              .recordIconWrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                width: 1.75em;
                height: 1.75em;
                border-radius: $borderRadius--full;
                background: $grey--background;
                margin-right: 0.5em;
                .inlineSVG {
                  width: 1.125em;
                  height: 1.125em;
                  path {
                    fill: $grey;
                  }
                }
              }
              &.risk--critical {
                .recordIconWrapper {
                  background: $critical--10;
                  .inlineSVG {
                    path {
                      fill: $critical;
                    }
                  }
                }
              }
              &.risk--high {
                .recordIconWrapper {
                  background: $high--10;
                  .inlineSVG {
                    path {
                      fill: $high;
                    }
                  }
                }
              }
              &.risk--moderate {
                .recordIconWrapper {
                  background: $moderate--10;
                  .inlineSVG {
                    path {
                      fill: $moderate;
                    }
                  }
                }
              }
              &.risk--low {
                .recordIconWrapper {
                  background: $low--10;
                  .inlineSVG {
                    path {
                      fill: $low;
                    }
                  }
                }
              }
              &.risk--minimal {
                .recordIconWrapper {
                  background: $minimal--10;
                  .inlineSVG {
                    path {
                      fill: $minimal;
                    }
                  }
                }
              }
            }
            label {
              .labelWrapper {
                display: block;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 0.875em;
                margin-bottom: 0.25em;
                color: $grey;
              }
              padding-left: 0em;
              &:nth-of-type(2) {
                padding-left: 0.5em;
              }
            }
            .selectFieldWrapper {
              width: 100%;
              .dropdownWrapper.asSelect {
                .dropdownTrigger.asSelect {
                  font-size: 1em;
                  .inlineSVG, img {
                    margin-right: 0.5em;
                  }
                }
              }
            }
            input[type='email'], input[type='text'] {
              padding: 0.5em 0.625em;
              margin: 0;
              background: $grey--background--light;
              border: 1px solid $grey;
              border-radius: $borderRadius;
              width: 100%;
              &:focus {
                outline: none;
              }
              &.invalid {
                border: 1px solid $status--red;
                color: $status--red;
              }
            }
            &.tableCell_task {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
            }
            &.headerCell_options {
              span {
                display: none;
              }
            }
            &.tableCell_options {
              display: flex;
              .labelWrapper {
                font-weight: 500;
                flex-shrink: 0;
                padding-right: 0.5em;
              }
              .selectFieldWrapper, input[type="email"] {
                width: unset;
                flex-grow: 1;
              }
            }
          }
          &.tableHeader {
            border-top: 1px solid $grey--divider;
          }
        }
      }
    }
  }
}