/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.appliedFiltersWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  flex-shrink: 1;
  margin-top: -0.5em;
  padding-left: 0.5em;
  position: relative;
  .clearAllFiltersButton {
    margin-top: 0.5em;
  }
}