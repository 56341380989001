/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.attackScenarioCountLink {
  text-decoration: none !important;
  color: $darkBlue !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 3px !important;
  border-radius: $borderRadius--input !important;
  background: $darkBlue !important;
  margin-bottom: 0.5em !important;
  transition: all 300ms ease-in-out !important;
  .iconAndCountWrapper {
    background: rgba( #fff, 0.9 );
    border-radius: $borderRadius;
    padding: 0.5em 0.75em 0.5em 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0.5em;
    flex-shrink: 0;
    font-weight: 600;
    .inlineSVG {
      margin-right: 0.5em;
      width: 1.125em;
      height: 1.125em;
      opacity: 0.75 !important;
      transition: all 300ms ease-in-out !important;
      path {
        transition: all 300ms ease-in-out !important;
        fill: $darkBlue !important;
      }
    }
  }
  .labelAndLinkWrapper {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding-right: 0.5em;
    .inlineSVG {
      margin-left: 0.5em;
      width: 1em;
      height: 1em;
      opacity: 0.75;
      transition: all 300ms ease-in-out !important;
      path {
        transition: all 300ms ease-in-out !important;
        fill: $darkBlue;
      }
    }
  }
  &.lpe {
    background: rgba( $attackScenario--lpe--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $attackScenario--lpe !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $attackScenario--lpe !important;
      .inlineSVG {
        path {
          fill: $attackScenario--lpe !important;
        }
      }
    }
    &:hover {
      background: $attackScenario--lpe--30 !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.remote_client {
    background: rgba( $attackScenario--remote_client--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $attackScenario--remote_client !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $attackScenario--remote_client !important;
      .inlineSVG {
        path {
          fill: $attackScenario--remote_client !important;
        }
      }
    }
    &:hover {
      background: $attackScenario--remote_client--30 !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.remote_service {
    background: rgba( $attackScenario--remote_service--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $attackScenario--remote_service !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $attackScenario--remote_service !important;
      .inlineSVG {
        path {
          fill: $attackScenario--remote_service !important;
        }
      }
    }
    &:hover {
      background: $attackScenario--remote_service--30 !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.mitm {
    background: rgba( $attackScenario--mitm--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $attackScenario--mitm !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $attackScenario--mitm !important;
      .inlineSVG {
        path {
          fill: $attackScenario--mitm !important;
        }
      }
    }
    &:hover {
      background: $attackScenario--mitm--30 !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.cred_theft {
    background: rgba( $attackScenario--cred_theft--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $attackScenario--cred_theft !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $attackScenario--cred_theft !important;
      .inlineSVG {
        path {
          fill: $attackScenario--cred_theft !important;
        }
      }
    }
    &:hover {
      background: $attackScenario--cred_theft--30 !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.exploitable_vulns_at_risk_count {
    background: rgba( $status--red, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $status--red !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $status--red !important;
      .inlineSVG {
        path {
          fill: $status--red !important;
        }
      }
    }
    &:hover {
      background: rgba( $status--red, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.critical, &.Critical {
    background: rgba( $critical, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $critical !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $critical !important;
      .inlineSVG {
        path {
          fill: $critical !important;
        }
      }
    }
    &:hover {
      background: rgba( $critical, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.high, &.High {
    background: rgba( $high, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $high !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $high !important;
      .inlineSVG {
        path {
          fill: $high !important;
        }
      }
    }
    &:hover {
      background: rgba( $high, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.moderate, &.Moderate {
    background: rgba( $moderate, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $moderate !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $moderate !important;
      .inlineSVG {
        path {
          fill: $moderate !important;
        }
      }
    }
    &:hover {
      background: rgba( $moderate, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.low, &.Low {
    background: rgba( $low, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $low !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $low !important;
      .inlineSVG {
        path {
          fill: $low !important;
        }
      }
    }
    &:hover {
      background: rgba( $low, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.minimal, &.Minimal {
    background: rgba( $minimal, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $minimal !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $minimal !important;
      .inlineSVG {
        path {
          fill: $minimal !important;
        }
      }
    }
    &:hover {
      background: rgba( $minimal, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.unknown, &.Unknown, &.default, &.Default {
    background: rgba( $darkBlue, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: $darkBlue !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: $darkBlue !important;
      .inlineSVG {
        path {
          fill: $darkBlue !important;
        }
      }
    }
    &:hover {
      background: rgba( $darkBlue, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.user {
    .iconAndCountWrapper {
      justify-content: space-around;
      padding: 0.5em;
      .inlineSVG {
        margin-right: 0em;
      }
    }
  }
}