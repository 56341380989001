/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.streamDownloadButton {
  opacity: 1;
  @include dsButton('secondary');
  .inlineSVG {
    margin-right: 0;
    height: 1.25em;
    margin-left: 0.5em;
    flex-shrink: 0;
  }
  &:hover {
    opacity: 1;
    @include dsButton('status-green');
    .inlineSVG {
      margin-right: 0;
      height: 1.25em;
      margin-left: 0.5em;
      flex-shrink: 0;
    }
  }
}
