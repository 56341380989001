/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.svgGaugeWrapper {
  svg {
    .gaugeSegment {
      transition: all 0.3s ease-in-out;
    }
  }
  circle, line, text {
    transition: all 0.3s ease-in-out;
  }
}