/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.recordStatisticWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease-in-out;
  margin-bottom: 1em;
  .statisticLabel {
    color: $darkBlue;
    font-size: 0.875em;
    margin-bottom: 0.5em;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s ease-in-out;
    .inlineSVG {
      width: 1em;
      height: 1em;
      margin-left: 0.5em;
      transition: all 0.3s ease-in-out;
      path {
        transition: all 0.3s ease-in-out;
        fill: $grey--icon;
        &.whiteFill {
          fill: #fff;
        }
      }
    }
  }
  .statisticValue {
    font-weight: 600;
    color: $darkBlue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    transition: all 0.3s ease-in-out;
    font-size: 1.25em;
    transition: all 0.3s ease-in-out;
    .inlineSVG {
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      transition: all 0.3s ease-in-out;
      path {
        transition: all 0.3s ease-in-out;
        fill: $grey--icon;
        &.whiteFill {
          fill: #fff;
        }
      }
    }
    .exploitStatusWrapper {
      margin-top: 0.25em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .inlineSVG {
        opacity: 1;
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.625em;
        path {
          fill: #fff;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .statusTitle {
        margin-top: 0.5em;
        font-size: 0.75em;
        text-align: center;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          path {
            fill: $grey--icon;
          }
          &:hover {
            path {
              fill: $darkBlue;
            }
          }
        }
      }
    }
  }
  &.asLink {
    padding: 1em 2em;
    border-radius: $borderRadius;
    &:hover {
      text-decoration: none;
      background: $primaryBlue--background;
      .statisticLabel {
        color: $primaryBlue;
        .inlineSVG {
          path {
            fill: $primaryBlue;
          }
        }
      }
      .statisticValue {
        color: $primaryBlue;
        .inlineSVG {
          path {
            fill: $primaryBlue;
          }
        }
      }
    }
  }
  &.exploitable_vulns_at_risk_count {
    background: $status--red--10;
    .statisticValue {
      .inlineSVG {
        path {
          fill: $status--red;
        }
      }
    }
    &:hover {
      background: $status--red--20;
      color: $status--red;
      .statisticLabel{
        color: $status--red;
      }
      .statisticValue {
        color: $status--red;
        .inlineSVG {
          path {
            fill: $status--red;
          }
        }
      }
    }
  }
  &.lpe {
    background: rgba( $attackScenario--lpe--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: $attackScenario--lpe;
        }
      }
    }
    &:hover {
      background: $attackScenario--lpe--30;
      color: $attackScenario--lpe;
      .statisticLabel{
        color: $attackScenario--lpe;
      }
      .statisticValue {
        color: $attackScenario--lpe;
        .inlineSVG {
          path {
            fill: $attackScenario--lpe;
          }
        }
      }
    }
  }
  &.remote_client {
    background: rgba( $attackScenario--remote_client--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: $attackScenario--remote_client;
        }
      }
    }
    &:hover {
      background: $attackScenario--remote_client--30;
      color: $attackScenario--remote_client;
      .statisticLabel{
        color: $attackScenario--remote_client;
      }
      .statisticValue {
        color: $attackScenario--remote_client;
        .inlineSVG {
          path {
            fill: $attackScenario--remote_client;
          }
        }
      }
    }
  }
  &.remote_service {
    background: rgba( $attackScenario--remote_service--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: $attackScenario--remote_service;
        }
      }
    }
    &:hover {
      background: $attackScenario--remote_service--30;
      color: $attackScenario--remote_service;
      .statisticLabel{
        color: $attackScenario--remote_service;
      }
      .statisticValue {
        color: $attackScenario--remote_service;
        .inlineSVG {
          path {
            fill: $attackScenario--remote_service;
          }
        }
      }
    }
  }
  &.mitm {
    background: rgba( $attackScenario--mitm--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: $attackScenario--mitm;
        }
      }
    }
    &:hover {
      background: $attackScenario--mitm--30;
      color: $attackScenario--mitm;
      .statisticLabel{
        color: $attackScenario--mitm;
      }
      .statisticValue {
        color: $attackScenario--mitm;
        .inlineSVG {
          path {
            fill: $attackScenario--mitm;
          }
        }
      }
    }
  }
  &.cred_theft {
    background: rgba( $attackScenario--cred_theft--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: $attackScenario--cred_theft;
        }
      }
    }
    &:hover {
      background: $attackScenario--cred_theft--30;
      color: $attackScenario--cred_theft;
      .statisticLabel{
        color: $attackScenario--cred_theft;
      }
      .statisticValue {
        color: $attackScenario--cred_theft;
        .inlineSVG {
          path {
            fill: $attackScenario--cred_theft;
          }
        }
      }
    }
  }
}