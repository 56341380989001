/***************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.logOutputHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-weight: 600;
  padding-bottom: 1em;
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  padding: 1em 2em;
  z-index: 2;
  .iconWrapper {
    background: $grey--divider;
    width: 2em;
    height: 2em;
    border-radius: $borderRadius--full;
    align-items: center;
    justify-content: space-around;
    margin-right: 0.75em;
  }
  .inlineSVG {
    width: 1.5em;
    height: 1.5em;
    path {
      fill: $darkBlue;
    }
  }
}

.logOutputWrapper {
  .upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: flex-start;
    border-bottom: 1px solid $grey--divider;
    padding: 1em 2em;
    background: #fff;
    padding: 1em 2em;
    z-index: 3;
    span {
      width: 50%;
    }
    &.withinModal {
      position: absolute;
      top: 4em;
      left: 0;
    }
  }
  .logContentWrapper {
    background-color: #002b36;
    border-radius: $borderRadius;
    padding: 1em 1.25em;
    color: #93a1a1;
    font-family: monospace, monospace;
    font-size: 1.125em;
    font-weight: 400;
    z-index: 1;
    max-height: 60vh;
    overflow: auto;
    .logRow {
      margin-bottom: 0.5em;
      padding-left: 2.5em;
      .lineInfo {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: -2.5em;
      }
      span {
        display: inline;
        &.timestamp, &.ipAddress {
          flex-shrink: 0;
          margin-right: 0.5em;
        }
         &.ipAddress {
          color: #839496;
        }
        &.timestamp {
          color: #FFF;
          opacity: 0.8;
        }
        &.content {
          line-height: 1.625em;
        }
      }
      &.INFO {
        span {
          &.content {
            // color: #2aa198;
            color: $status--green;
          }
        }
      }
      &.DEBUG {
        span {
          &.content {
            color: #FFF;
          }
        }
      }
      &.WARNING {
        span {
          &.content {
            // color: #b58900;
            color: $status--yellow;
          }
        }
      }
      &.ERROR {
        span {
          &.content {
            // color: #b58900;
            color: $status--red;
          }
        }
      }
    }
    &.withinModal {
      margin-bottom: 2em;
      margin-top: 7em;
      max-height: unset;
      overflow: unset;
    }
  }
  .emptyState {
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}