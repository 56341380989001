/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.hosts_comparison,
.dashboardWidgetWrapper.patches_comparison,
.dashboardWidgetWrapper.vulnerabilities_comparison,
.dashboardWidgetWrapper.vulnerability_instances_comparison,
.dashboardWidgetWrapper.risk_comparison,
.dashboardWidgetWrapper.sensitive_assets_comparison,
.dashboardWidgetWrapper.compliance_comparison,
.dashboardWidgetWrapper.compliance_current {
  color: $darkBlue;
  &.number {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .widgetHeaderContainer {
      display: none;
    }
    .widgetContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .overtimeNumberWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      h2 {
        font-size: 1.125em;
        font-weight: 400;
        margin-bottom: 1em;
        color: $darkBlue;
        strong {
          font-weight: 600;
        }
      }
      .countWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1em;
        .inlineSVG {
          width: 2.5em;
          height: 2.5em;
          margin-right: 0.75em;
          path {
            fill: $grey--icon;
          }
        }
        strong {
          font-size: 3.5em;
        }
        &.risk {
          border-radius: 1em;
          background: $grey--background;
          padding: 1.5em 2.5em;
          strong {
            font-weight: 500;
          }
          &.riskClass--critical {
            background: $critical--10;
            .inlineSVG {
              path {
                fill: $critical;
              }
            }
            strong {
              color: $critical;
            }
          }
          &.riskClass--high {
            background: $high--10;
            .inlineSVG {
              path {
                fill: $high;
              }
            }
            strong {
              color: $high;
            }
          }
          &.riskClass--moderate {
            background: $moderate--10;
            .inlineSVG {
              path {
                fill: $moderate;
              }
            }
            strong {
              color: $moderate;
            }
          }
          &.riskClass--low {
            background: $low--10;
            .inlineSVG {
              path {
                fill: $low;
              }
            }
            strong {
              color: $low;
            }
          }
          &.riskClass--minimal {
            background: $minimal--10;
            .inlineSVG {
              path {
                fill: $minimal;
              }
            }
            strong {
              color: $minimal;
            }
          }
          &.riskClass--primary {
            background: $primary--10;
            .inlineSVG {
              path {
                fill: $primary;
              }
            }
            strong {
              color: $primary;
            }
          }
        }
      }
      .trendWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1em;
        .trendIconWrapper {
          width: 1.5em;
          height: 1.5em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          background: $grey--background;
          border-radius: $borderRadius--full;
          margin-right: 0.5em;
          .inlineSVG {
            width: 1em;
            height: 1em;
            path {
              fill: $grey;
            }

          }
          span {
            font-weight: 600;
            color: $grey;
            line-height: 1.25em;
          }
          &.up {
            background: $status--red--10;
            .inlineSVG {
              path {
                fill: $status--red;
              }
            }
            &.compliance {
              background: $status--green--10;
              .inlineSVG {
                path {
                  fill: $status--green;
                }
              }
            }
          }
          &.down {
            background: $status--green--10;
            .inlineSVG {
              path {
                fill: $status--green;
              }
            }
            &.compliance {
              background: $status--red--10;
              .inlineSVG {
                path {
                  fill: $status--red;
                }
              }
            }
          }
        }
      }
    }
  }
  &.barchart {
    .overtimeBarWrapper, .overtimeMultiBarWrapper {
      padding-top: 0.5em;
      display: grid;
      grid-template-columns: 8em 1fr 16em;
      height: 100%;
      max-height: 100%;
      .axisContainer {
        &.yAxis {
          height: 100%;
          max-height: 100%;
        }
      }
      .svgBarWrapper, .svgMultiBarWrapper {
        padding-bottom: 0.5em;
        padding-left: 0.5em;
        border-bottom: 2px solid $grey--icon;
        margin-left: -0.5em;
        max-height: 100%;
      }
      .chartLegend {
        padding-left: 2em;
      }
      .svgMultiBarWrapper {
        height: 100%;
        max-height: 100%;
        .multiBarChart {
          width: 100%;
          height: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .curentComparisonContentWrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 9em 1fr;
    padding-top: 0.5em;
    .axisContainer {
      &.yAxis {
        height: calc( 100% - 5.5em );
      }
    }
    .horizontalBarChartWrapper {
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        border-top: 2px solid $grey--icon;
        position: absolute;
        bottom: 5.5em;
        left: -0.5em;
      }
    }
  }
  .emptyState {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}