/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.riskInsightIndexReportHeader {
  position: relative;
  .addToPlanMenuWrapper {
    position: absolute;
    top: -4.5em;
    right: 3em;
  }
  .riskInsightIndexHeaderRow {
    color: $darkBlue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      .recordIconWrapper {
        width: 2.25em;
        height: 2.25em;
        background: $grey--background;
        border-radius: $borderRadius--full;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-right: 0.5em;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          path {
            fill: $darkBlue;
          }
        }
      }
      h2 {
        font-size: 1.125em;
        font-weight: 400;
        padding: 0;
        margin: 0;
        margin-right: 0.5em;
        strong {
          margin-left: 0.25em;
          font-weight: 600;
        }
      }
      .noOrderByOptions {
        font-size: 1.125em;
        margin-right: 0.5em;
        margin-left: -0.25em;
        font-weight: 600;
      }
      .selectFieldWrapper {
        margin-right: 0.5em;
        &.riskTypeWrapper, &.patchableWrapper, &.groupTypeSelector {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          .labelWrapper {
            font-size: 1.125em;
            margin-right: 0.5em;
          }
        }
      }
      .includeRiskFieldWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .labelWrapper {
          font-size: 1em;
          font-weight: 500;
          margin-left: 0.25em;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding-right: 3em;
      .paginationContainer {
        margin: 0;
        padding: 0;
        margin-left: 1em;
        border-left: none;
        button {
          padding: 0.5em 1em;
        }
      }
    }
  }
  .showVisualToggle {
    position: absolute;
    top: 0em;
    right: 0em;
    z-index: 9999;
  }
  .appliedFilters {
    margin-top: 0.625em;
    padding-right: 11.5em;
    padding-left: 16.5em;
    min-height: 2.75em;
  }
  .toggleWrapper.instanceVisualModeToggle {
    position: absolute;
    left: 0;
    top: 3.625em;
    z-index: 9999;
    .toggleButton {
      padding: 0.375em 1.375em;
      font-weight: 600;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
      }
    }
  }
  .advancedFilterFormWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2em;
    max-height: 34em;
    overflow: auto;
    padding-top: 0.5em;
    .advancedFormColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 0;
      align-self: flex-start;
      h2 {
        display: none;
      }
      .filterWrapper {
        // font-size: 1em;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
  .exportOptionsMenuWrapper {
    position: absolute;
    right: 3em;
    top: 0em;
    z-index: 9999;
    .exportOptionsMenuTrigger {
      @include dsButton( 'dark' );
    }
  }

  &.visualCollapsed {
    .advancedFilterFormWrapper, .riskInsightVisualWrapper, .inlineReportCreatorContainer {
      height: 0;
      min-height: 0;
      overflow: hidden;
      padding: 0;
      margin: 0;
      border-top: none;
    }
  }
}

.dropdownMenu.visible.exportOptionsMenu {
  width: 100%;
  margin-left: -2.5em;
}