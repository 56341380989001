/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.widgetEditorAndSelctorModal {
  .modalBody {
    display: grid;
    grid-template-columns: 26em 1fr;
    grid-column-gap: 2em;
    .widgetVariantSelector {
      padding-right: 2em;
      border-right: 1px solid $grey--divider;
      padding-bottom: 2em;
      ul {
        border: 1px solid $grey--icon;
        border-radius: $borderRadius--input;
        padding: 1em;
        background: $grey--background--light;
      }
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: $darkBlue;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin: 0;
        margin-bottom: 0.5em;
        padding: 0.375em 0.75em;
        border-radius: 0.25em;
        position: relative;
        cursor: pointer;
        &:hover {
          background: $grey--divider;
        }
        &.selected {
          background: $grey--divider;
          padding-left: 2em;
          &:before {
            content: "";
            flex-shrink: 0;
            height: 0.75em;
            width: 0.75em;
            border-radius: $borderRadius--full;
            background: $primaryBlue;
            position: absolute;
            top: 50%;
            margin-top: calc( 0.75em / -2 );
            left: 0.75em;
          }
        }
        &:last-child {
          margin-bottom: none;
        }
      }
    }
  }
  &.editMode {
    width: 36em;
    left: calc( 50% - 18em );
  }
}