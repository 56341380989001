/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.svgDonutWrapper {
  svg {
    .donutSegment {
      transition: all 0.3s ease-in-out;
      &.isHoverable {
        cursor: default;
      }
      &.isClickable {
        cursor: pointer;
      }
      &.isFaded {
        stroke-opacity: 0.3;
      }
      &:hover, &.isHovered, &.isSelected {
        stroke-opacity: 1;
      }
    }
  }
  text {
    transition: all 0.3s ease-in-out;
  }
}