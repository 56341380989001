/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.password {
  .passwordFieldWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    .setPasswordVisibleButton {
      background: none;
      border: none;
      width: 1.5em;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0.5em;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin-right: 1em;
      svg {
        width: 1.5em;
        opacity: 0.5;
        &.visible {
          display: none;
        }
        &.notVisible {
          display: block;
        }
      }
      &:focus {
        outline: none;
      }
      &.visible {
        svg {
          opacity: 1;
          &.visible {
            display: block;
          }
          &.notVisible {
            display: none;
          }
        }
      }
    }
    .generatePasswordButton {
      padding: 0.75em 0 0.5em 0;
      color: $primaryBlue;
      margin: 0;
      border: none;
      background: none;
      cursor: pointer;
      text-align: left;
      margin-left: 0.5em;
      &:hover {
        text-decoration: underline;
      }
      &:focus {
        outline: none;
      }
    }
    &.editMode {
      flex-direction: row;
      width: 100%;
      input {
        flex-grow: 1;
        transition: none;
      }
      .setPasswordVisibleButton {
        right: 4.5em;
      }
      .passwordEditButton {
        @include dsButton( 'minimal-dark' );
        margin-left: 0.5em;
      }
      &.needsGenerate {
        .setPasswordVisibleButton {
          right: 14em;
        }
      }
    }
  }
}
