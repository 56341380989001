/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.modalContainer.legacyFileUploader {
  .modalBody {
    padding: 1em 0em 5em 0em;
    .uploaderContainer {
      .file-manager-buttons-root {
        display: grid;
        grid-gap: 1em;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        padding: 1em 2em;
        button {
          @include dsButton('secondary');
        };

      }
      .file-manager-files-table-root {
        border: none;
        display: grid;
        .file-manager-table-header {
          border-bottom: none;
          font-size: 1.125em;
          font-family: $fixed;
          font-weight: 600;
          padding: 1em;
          margin: 1em;
          border-radius: $borderRadius;
          background: $grey--background;
        }
        .file-manager-files-table {
          .file-manager-row {
            background: #fff;
            border: none;
            padding: 1em;
            display: grid;
            grid-template-columns: 1em 3fr 1fr 1fr;
            grid-column-gap: 0.5em;
            &:nth-of-type(odd) {
              background: $grey--background;
            }
            &.file-manager-table-header {
              background: #fff;
              font-family: $sans;
              font-size: 1em;
              font-weight: 400;
              padding: 0;
              padding-bottom: 0.5em;
              color: $darkBlue;
              border-bottom: 1px solid $grey--icon;
              border-radius: 0;
              margin: 0;
              a {
                color: $darkBlue;
              }
            }
          }
        }
        center {
          color: $grey;
          font-size: 1.25em;
          padding: 2em;
        }
      }
    }
  }
}