/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.setupRecordItem.certificate.alternateLayout.item {
  grid-template-columns: calc( 100% - 16em - 16em - 16em ) 16em 16em 16em !important;
  .mainDetailsSection {
    .column {
      span {
        color: $grey;
      }
    }
  }
}