/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.multiSelectOptionsWrapper {
  position: fixed;
  z-index: 99999999;
  .multiSelectOptions {
    font-family: $sans;
    border: 1px solid $grey--icon;
    border-radius: $borderRadius--input;
    background: #fff;
    width: 100%;
    box-shadow: $boxShadow;
    z-index: 59;
    .option {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5em;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      text-transform: none;
      color: $darkBlue;
      .assetTagListItem {
        padding: 0.5em 1em;
        border-radius: $borderRadius--full;
        font-weight: 600;
        margin: 0;
        list-style: none;
        font-size: 0.75em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
      &:hover {
        background: $grey--divider;
      }
      &:first-of-type {
        border-radius: $borderRadius $borderRadius 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 $borderRadius $borderRadius;
      }
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.5em;
        flex-shrink: 0;
        &.unchecked {
          opacity: 0.6;
        }
      }
    }
    .emptyOption {
      padding: 0.5em;
      color: $grey;
      text-align: center;
    }
  }
  .multiselectShade {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 58;
  }
}

.filterWrapper.select {
  // &.risk_type {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   width: 100% !important;
  //   max-width: 100% !important;
  //   .labelWrapper {
  //     width: unset;
  //     margin-right: 0.5em;
  //     font-size: 1em !important;
  //     color: $darkBlue;
  //   }
  //   .toggleWrapper {
  //     font-size: 0.875em;
  //   }
  // }

  .multiSelectWrapper {
    display: relative;
    .selectFieldWrapper.noInput {
      padding: 0.5em 1em 0.5em 0.5em;
      background: #fff;
      border: 1px solid rgba( $grey--icon, 0.75 );
      border-radius: $borderRadius--input;
      color: rgba( $darkBlue, 0.85 );
      box-shadow: $boxShadow--input;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: 20em;
      z-index: 59;
      min-height: 3.125em;
      line-height: 1em;
      .carretIcon {
        width: 1.25em;
        height: 1.25em;
        transition: all 0.3s ease-in-out;
        flex-shrink: 0;
      }
      &.open {
        .carretIcon {
          transform: rotate( 180deg );
        }
      }
      .selectedOptions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-right: 0.5em;
        margin-bottom: -0.5em;
        width: calc( 100% - 1.75em );
        flex-grow: 1;
        flex-shrink: 1;
        .placeholder {
          text-transform: none;
          margin-left: 0.5em;
          margin-bottom: 0.5em;
        }
        .selected {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          text-transform: none;
          color: $darkBlue;
          font-size: 0.875em;
          border-radius: 0.25em;
          background: $grey--divider;
          padding: 0.25em 0.5em;
          margin-right: 0.5em;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          flex-shrink: 0;
          flex-grow: 1;
          margin-bottom: 0.5em;
          .assetTagListItem {
            padding: 0.5em 1em;
            border-radius: $borderRadius--full;
            width: calc( 100% - 1.5em );
            font-weight: 600;
            margin: 0;
            list-style: none;
          }
          strong {
            width: calc( 100% - 1.5em );
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .inlineSVG, .removeButton {
            width: 1.25em;
            height: 1.25em;
          }
          .removeButton {
            margin-left: 0.5em;
            opacity: 0.6;
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            background: rgba( $grey--icon, 0.6 );
            .removeButton {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.asset_tag_ids {
    .multiSelectWrapper {
      .selectFieldWrapper.noInput {
        .selectedOptions {
          .selected {
            background: none;
          }
        }
      }
    }
  }
}