/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.signatureContentvulnerabilityItemWrapper {
  width: 100% !important;
  align-items: center !important;
  padding: 0.5em 0.75em;
  border-radius: $borderRadius;
  margin-bottom: 0.25em !important;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  span.name {
    width: 9em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5em;
    flex-shrink: 0;
    font-weight: 600;
    white-space: nowrap;
  }
  .riskPercentageBarWrapper {
    flex-shrink: 1;
    margin-right: 0.5em;
  }
  strong {
    width: 4em;
    text-align: right;
    flex-shrink: 0;
  }
  &.selected {
    background: $grey--divider;
    &:before {
      content: "";
      width: 0.75em;
      height: 0.75em;
      background: $primaryBlue;
      border-radius: $borderRadius--full;
      flex-shrink: 0;
      margin-right: 0.5em;
    }
  }
  &:hover {
    background: $grey--divider;
  }
}

.signatureContentvulnerabilitiesSectionWrapper {
  position: relative;
  min-height: 4em;
}

.signatureRecordCardContentWrapper {
  .infoSection {
    margin-bottom: 1em;
    label {
      font-weight: 600;
      margin-bottom: 0.5em;
    }
    .markdownWrapper {
      margin: 1em 0;
    }
    pre, .markdownWrapper {
      margin-top: 0;
      max-height: 20vh;
      overflow: auto;
    }
    &.inline {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      label {
        margin: 0;
        margin-right: 0.25em !important;
        flex-shrink: 0;
      }
      &.rating {
        label {
          margin-right: 0.5em !important;
        }
        align-items: center;
      }
    }
  }
  .collapsibleSectionWrapper {
    .collapsibleSectionBody {
      max-height: 20vh;
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0.5em;
      }
    }
  }
  .emptyState {
    font-size: 1.25em;
    background: $grey--background;
    border-radius: $borderRadius;
  }
}