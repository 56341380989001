/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.recordCardContainer.recordCardType_signature {
  .contentGridWrapper {
    .mainContent {
      .recordCardHeader.signature {
        .recordIconWrapper {
          .recordTypeHeaderIcon {
            display: block;
            width: 1.25em;
            height: 1.25em;
          }
        }
      }
    }
  }
}