/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.chartLegend {
  position: relative;
}

.chartLegend, .chartLegendModal {
  color: $darkBlue;
  h4 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 1em;
    text-indent: 0.5em;
  }
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25em;
    padding: 0.35em 0.5em;
    border-radius: $borderRadius;
    transition: all 0.3s ease-in-out;
    .labelAndKeyWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-self: flex-start;
    }
    span {
      flex-shrink: 0;
      &.splitLegendKeyWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0.5em;
        .legendKeyHalfLeft, .legendKeyHalfRight {
          flex-shrink: 0;
          width: 0.5em;
          height: 1em;
        }
        .legendKeyHalfLeft {
          border-radius: $borderRadius--full 0 0 $borderRadius--full;
        }
        .legendKeyHalfRight {
          border-radius: 0 $borderRadius--full $borderRadius--full 0;
        }
        &.isTag {
          opacity: 0.8;
        }
      }
      &.legendKey {
        width: 1em;
        height: 1em;
        border-radius: $borderRadius--full;
        margin-right: 0.5em;
        &.isTag {
          opacity: 0.8;
        }
      }
      &.legendLabel {
        margin-right: 0.25em;
      }
    }
    .helpTrigger {
      width: 1em;
      height: 1em;
      margin: 0 0.5em 0 0.125em;
      .inlineSVG {
        width: 1em;
        height: 1em;
        path {
          fill: $grey !important;
        }
      }
      &:hover {
        .inlineSVG {
          path {
            fill: $darkBlue !important;
          }
        }
      }
    }
    .removeFilterIcon {
      width: 1.25em;
      height: 1.25em;
      margin-left: 0.5em;
    }
    &.disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.4;
    }
    &.isHoverable {
      cursor: default;
    }
    &.isClickable {
      cursor: pointer;
    }
  }
  &.horizontal {
    padding: 0.5em;
    font-size: 0.875em;
    width: 100%;
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      flex-wrap: wrap;
    }
    li {
      margin: 0;
      width: unset !important;
      margin-left: 0em;
      margin-bottom: 0.5em;
    }
  }
  .overflowCount {
    padding: 0.25em 0.5em;
    border: none;
    background: none;
    border-radius: $borderRadius--input;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-top: 0.25em;
    width: 10em;
    &:hover {
      background: $grey--divider;
    }
  }
}

.chartLegendModal {
  font-family: $sans;
  color: $darkBlue;
  position: absolute;
  top: 2em;
  right: 2em;
  background: #fff;
  padding: 0em 1em 1em 1em;
  border-radius: $borderRadius;
  z-index: 99999;
  border: 1px solid $grey--icon;
  box-shadow: $boxShadow;
  width: 26em;
  .closeButton {
    width: 2.5em;
    height: 2.5em;
    border-radius: $borderRadius--full;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    transition: all 0.3s ease-in-out;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    background: $grey--background;
    opacity: 1;
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 0.75em;
    .inlineSVG {
      flex-shrink: 0;
      width: 1.5em;
      height: 1.5em;
      path {
        fill: $darkBlue;
        transition: all 0.3s ease-in-out;
      }
    }
    &:hover {
      background: $grey--divider;
      .inlineSVG {
        path {
          fill: $darkBlue;
        }
      }
    }
  }
}