/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

ul.setupRecordsList {
  li.setupRecordItem.alternateLayout.sensitive_asset_policy.item {
    grid-template-columns: calc( 100% - 9em ) 9em !important;
    background: #fff;
    section.mainDetailsSection {
      .column {
        h2 {
          font-size: 1.125em;
          margin-bottom: 0.5em;
        }
        span.policySentenceWrapper {
          color: $darkBlue !important;
          font-size: 1em;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          span {
            color: $darkBlue !important;
            font-weight: 400 !important;
            font-size: 1em;
            padding: 0.125em 0.5em;
            border-radius: $borderRadius;
            display: block;
            margin-right: 0.25em;
            margin-bottom: 0.25em;
            code {
              font-weight: 600 !important;
            }
            &.impact {
              border: 1px solid rgba( $filter--teal, 0.25 );
              background: rgba( $filter--teal, 0.05 ) !important;
            }
            &.scope {
              border: 1px solid rgba( $filter--blue, 0.25 );
              background: rgba( $filter--blue, 0.05 ) !important;
            }
            &.tag {
              border: 1px solid rgba( $filter--purple, 0.25 );
              background: rgba( $filter--purple, 0.05 ) !important;
            }
            &.node {
              border: 1px solid rgba( $filter--red, 0.25 );
              background: rgba( $filter--red, 0.05 ) !important;
            }
          }
        }
      }

      // h2 {
      //   font-size: 0.875em;
      //   font-weight: 600;
      //   margin-bottom: 1em;
      // }
      // .subSection {
      //   padding: 0.25em 0.75em;
      //   border-radius: $borderRadius;
      //   background: $grey--background;
      //   color: $darkBlue !important;
      //   font-weight: 400 !important;
      //   // font-size: 1.25em !important;
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: flex-start;
      //   align-items: center;
      //   flex-wrap: wrap;
      //   strong {
      //     text-transform: uppercase;
      //     color: $grey;
      //   }
      //   code {
      //     margin-left: 0.375em;
      //     margin-right: 0.375em;
      //     font-weight: 600;
      //   }
      //   span {
      //     color: $darkBlue !important;
      //     font-weight: 400 !important;
      //     flex-shrink: 0;
      //   }
      // }
    }
  }
}
