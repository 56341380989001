/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.horizontalBarChartWrapper {
  width: 100%;
  height: 100%;
  color: $darkBlue;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  .barPlusLabelWrapper {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    margin-right: 0.75em;
    flex-grow: 1;
    width: 1em;
    &.withLabel {
      grid-template-rows: 1fr 5em;
      grid-row-gap: 1em;
      label {
        width: 100%;
        position: relative;
        height: 5em;
        span {
          display: block;
          transform: rotate( -25deg );
          width: 11em;
          text-align: right;
          position: absolute;
          top: 2em;
          right: 0;
        }
      }
    }
  }
  .fullBarWrapper {
    width: 100%;
    position: relative;
    transition: all 0.3s ease-in-out;
    height: 100%;
    .barContainer {
      background: $grey--background;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
    }
    .barSegment {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    &.isHoverable {
      &:hover {
        opacity: 0.75;
      }
    }
  }
}