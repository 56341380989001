/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

#pageContent.explore_modelPageContainer {
  #exploreModelContentWrapper {
    height: calc( 100% + 2em );
    width: calc( 100% + 2em );
    margin: -1em;
    overflow: hidden;
    position: relative;
    .collapsiblePanel {
      position: absolute;
      left: unset;
      left: 1em;
      top: 1em;
      background: #fff;
      padding: 1em;
      border-radius: $borderRadius--input;
      box-shadow: $boxShadow--medium;
      border: 1px solid rgba( $grey--icon, 0.5 );
      overflow: hidden;
      .panelHeader {
        font-size: 1em;
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: move;
        h2 {
          padding: 0;
          margin: 0;
          font-size: 1em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          .inlineSVG {
            margin-right: 0.5em;
            width: 1.5em;
            height: 1.5em;
            path {
              fill: $primaryBlue;
            }
          }
          span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .filterForm {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: #fff;
        border: 1px solid rgba( $grey--icon, 0.75 );
        border-radius: $borderRadius--input;
        color: rgba( $darkBlue, 0.85 );
        box-shadow: $boxShadow--input;
        padding: 0.375em;
        margin: 0;
        .filterWrapper {
          margin: 0;
          padding: 0;
          width: unset;
          border-bottom: none;
          font-size: 1em;
          flex-grow: 0;
          &:nth-of-type( 2 ) {
            flex-grow: 1;
          }
          &.hidden {
            width: 0;
            flex-grow: 0;
          }
          label {
            .labelWrapper {
              display: none;
            }
          }
          .selectFieldWrapper {
            border: none;
            box-shadow: none;
            border-radius: none;
            select {
              border: none;
              box-shadow: none;
              border-radius: 0;
              padding: 0.375em 2.5em 0.375em 1em;
              appearance: unset;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              border-right: 1px solid $grey--divider;
              &:hover {
                background-color: $grey--background;
                border-radius: $borderRadius;
                border-right: 1px solid transparent;
              }
              &:focus {
                outline: $primaryBlue;
                background-color: $grey--background;
                border-radius: $borderRadius;
                border-right: 1px solid transparent;
              }
            }
          }
          input[type="text"] {
            border: none;
            box-shadow: none;
            border-radius: $borderRadius;
            flex-grow: 1;
            padding: 0;
            padding: 0.375em 1em;
            margin-left: 0.5em;
            &:focus {
              background: $grey--background;
            }
          }
          label {
            &:nth-of-type(2) {
              flex-grow: 1;
            }
          }
          .debouncedTextFieldWrapper {
            flex-grow: 1;
          }
        }
      }
      .collapsibleSectionWrapper {
        max-height: calc( 100vh - 34.5em );
        .collapsibleSectionBody {
          max-height: 62.5vh;
        }
        &.bothOpen {
          max-height: calc( 50vh - 15.5em );
          .collapsibleSectionBody {
            max-height: 50vh;
          }
        }
      }
      .graphOptionsHeader {
        margin: 1em 0 0 0;
        padding: 0.75em 1em;
        color: $darkBlue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        border-radius: $borderRadius $borderRadius 0 0;
        border: 1px solid $grey--icon;
        box-shadow: $boxShadow;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          margin-right: 0.5em;
          path {
            fill: $grey--icon;
          }
        }
      }
      .graphOptions {
        border-radius: 0 0 $borderRadius $borderRadius;
        background: $grey--background--light;
        border: 1px solid $grey--icon;
        border-top: none;
        padding: 0.5em;
        box-shadow: $boxShadow;
        color: $darkBlue;
        li {
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          border-radius: $borderRadius;
          padding: 0.5em 1em;
          margin-bottom: 0.25em;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          font-weight: 500;
          .inlineSVG {
            width: 1.25em;
            height: 1.25em;
            margin-right: 0.5em;
            path {
              fill: $grey--icon;
            }
          }
          &:hover {
            background: rgba( $grey, 0.25 );
          }
          &.delete {
            color: $risk--red;
            .inlineSVG {
              path {
                fill: $high--10;
              }
            }
            &:hover {
              background: $high--10;
            }
          }
        }
      }
      &.collapsed {
        top: 0.5em;
        border: 1px solid rgba( $grey--icon, 0.5 );
        transform: none !important;
        padding: 0.5em 0.5em 0.5em 0.75em;
        .panelHeader {
          cursor: default;
          margin: 0;
          .selectedCount {
            color: $grey;
            font-weight: 400;
            margin-left: 0.375em;
          }
          h2 {
            button {
              margin: 0;
              margin-left: 0.5em;
            }
          }
        }
        .filterForm, .collapsibleSectionWrapper, .graphOptionsHeader, .graphOptions {
          display: none;
        }
      }
    }
    #manualPackingGraph {
      #panZoomWrapper {
        transition: all 0.3s ease-in-out;
      }
      &.emptyGraph {
        height: 0 !important;
      }
      &.isPanning, &.isScrollZooming {
        #panZoomWrapper {
          transition: unset;
        }
      }
    }
    .emptyState {
      width: 100vw;
      height: calc( 100vh - 4em );
      padding: 2em 2em 2em 17em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    .resetZoomButton {
      @include dsButton( 'secondary' );
      position: absolute;
      bottom: 1em;
      left: 1em;
    }
  }
}