/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.aboutHeaderActionsWrapper {
  display: flex;
  background: #fff;
  border-radius: $borderRadius;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  .modalTrigger {
    @include dsButton('minimal-dark');
    margin-left: 1em;
  }
  .downloadButton {
    @include dsButton('minimal-green');
    text-decoration: none;
    margin-left: 1em;
    padding: 0.375em 1em !important;
    .inlineSVG {
      height: 1.25em;
      width: 1.25em;
      margin-right: 0.5em;
      animation: spin 2s linear infinite;
    }
  }
}

#react-about-container {
  width: 100%;
}
.reactAboutPage {
  color: $darkBlue;
  section {
    background: #fff;
    border-radius: $borderRadius;
    padding: 1em 2em;
    margin-bottom: 1em;
    h2 {
      margin: 0;
      padding: 0;
      font-size: 1.125em;
      margin-bottom: 2em;
    }
    .infoSection {
      margin-bottom: 1em;
      label {
        font-weight: 600;
        margin-bottom: 0.5em;
      }
      strong {
        font-weight: 400;
      }
    }
    .inlineLink {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 0.25em;
      padding: 0.25em 0.5em;
      width: auto;
      .inlineSVG {
        width: 1em;
        height: 1em;
      }
    }
    code {
      display: inline;
      margin: 0;
      display: inline;
      padding: 0.5em 0.75em;
      background: $grey--background;
      border-radius: $borderRadius;
    }
  }
  .usageDataContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
  .usageVisual {
    span {
      font-weight: 400;
      margin-bottom: 1em;
      strong {
        font-weight: 600;
      }
    }
    .legend {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      border-top: 1px solid $grey--divider;
      padding-top: 1em;
      margin-top: 1em;
      .otherInfo {
        span {
          text-align: right;
        }
      }
    }
    .legendItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 1em;
      span {
        margin-bottom: 0;
      }
      .color {
        height: 1.5em;
        width: 1.5em;
        border-radius: $borderRadius--full;
        margin-right: 1em;
        background: $status--blue;
      }
      &.users {
        .color {
          background: $status--green;
        }
      }
    }
    .progressWrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 2em;
      justify-content: flex-start;
      align-items: flex-start;
      background: $grey--background;
      overflow: hidden;
      &.over {
        background: $status--red;
      }
      .hostUsageBar, .userUsageBar {
        flex-grow: 0;
        flex-shrink: 0;
        height: 2em;
        background: $status--blue;
      }
      .userUsageBar {
        background: $status--green;
      }
    }
  }
}

.reactAboutPageModal {
  h2 {
    margin-top: 1em;
  }
  p {
    line-height: 1.5em;
    font-family: monospace, monospace;
  }
  pre {
    padding: 0;
    border: none;
    margin: 0;
    background: #fff;
  }
  .eulaContent, .openSourceContent {
    padding-bottom: 6em;
  }
}
