/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.vulnerability_instances_cvss_breakdown {
  .widgetContent {
    display: grid;
    grid-template-columns: 1fr 11em;
    grid-column-gap: 1em;
    height: unset;
    .svgDonutWrapper, .chartWrapper {
      padding-top: 0.5em;
      min-height: 14em;
      max-height: 14em;
    }
  }
  &.donut {
    &.widgetHeight-4 {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 12.5em;
          max-height: 12.5em;
        }
      }
      &.withDescription {
        .widgetContent {
          .svgDonutWrapper {
            min-height: 10em;
            max-height: 10em;
          }
        }
      }
    }
    &.widgetHeight-5 {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 17em;
          max-height: 17em;
        }
      }
      &.withDescription {
        .widgetContent {
          .svgDonutWrapper {
            min-height: 14.5em;
            max-height: 14.5em;
          }
        }
      }
    }
    &.widgetHeight-6 {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 21.5em;
          max-height: 21.5em;
        }
      }
      &.withDescription {
        .widgetContent {
          .svgDonutWrapper {
            min-height: 18.5em;
            max-height: 18.5em;
          }
        }
      }
    }
    &.widgetHeight-7 {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 26em;
          max-height: 26em;
        }
      }
      &.withDescription {
        .widgetContent {
          .svgDonutWrapper {
            min-height: 23em;
            max-height: 23em;
          }
        }
      }
    }
    &.widgetHeight-8 {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 30em;
          max-height: 30em;
        }
      }
      &.withDescription {
        .widgetContent {
          .svgDonutWrapper {
            min-height: 27em;
            max-height: 27em;
          }
        }
      }
    }
  }
  &.barchart {
    .widgetContent {
      overflow: hidden;
      grid-template-columns: 1fr 11.5em;
      .chartWrapper {
        min-height: 14em;
        max-height: 14em;
      }
      .cvssBarChartWrapper {
        height: 10em;
      }
      .axisContainer.yAxis {
        height: calc( 10.5em + 1px );
      }
      .svgBarWrapper {
        height: 10em;
      }
      .axisContainer.xAxis {
        margin-left: 6.5em;
        width: calc( 100% - 6.5em );
        .axisLabel {
          font-size: 0.625em;
        }
      }
    }
    &.widgetHeight-4 {
      .widgetContent {
        .chartWrapper {
          min-height: 11em;
          max-height: 11em;
        }
        .cvssBarChartWrapper {
          height: 8em;
        }
        .axisContainer.yAxis {
          height: calc( 8.5em + 1px );
        }
        .svgBarWrapper {
          height: 8em;
        }
        .axisContainer.xAxis {
          margin-left: 6.5em;
          width: calc( 100% - 6.5em );
          .axisLabel {
            font-size: 0.625em;
          }
        }
      }
      &.withDescription {
        .chartWrapper {
          min-height: 6.5em;
          max-height: 6.5em;        }
        .cvssBarChartWrapper {
          height: 3.5em;
        }
        .axisContainer.yAxis {
          height: calc( 4em + 1px );
        }
        .svgBarWrapper {
          height: 3.5em;
        }
        .axisContainer.xAxis {
          margin-left: 6.5em;
          width: calc( 100% - 6.5em );
          .axisLabel {
            font-size: 0.625em;
          }
        }
      }
    }
    &.widgetHeight-5 {
      .widgetContent {
        .chartWrapper {
          min-height: 16.5em;
          max-height: 16.5em;        }
        .cvssBarChartWrapper {
          height: 11.5em;
        }
        .axisContainer.yAxis {
          height: calc( 12em + 1px );
        }
        .svgBarWrapper {
          height: 11.5em;
        }
        .axisContainer.xAxis {
          margin-left: 6.5em;
          width: calc( 100% - 6.5em );
          .axisLabel {
            font-size: 0.625em;
          }
        }
      }
      &.withDescription {
        .widgetContent {
          .chartWrapper {
            min-height: 11.5em;
            max-height: 11.5em;        }
          .cvssBarChartWrapper {
            height: 7.5em;
          }
          .axisContainer.yAxis {
            height: calc( 8em + 1px );
          }
          .svgBarWrapper {
            height: 7.5em;
          }
          .axisContainer.xAxis {
            margin-left: 6.5em;
            width: calc( 100% - 6.5em );
            .axisLabel {
              font-size: 0.625em;
            }
          }
        }
      }
    }
    &.widgetHeight-6 {
      .widgetContent {
        .chartWrapper {
          min-height: 22em;
          max-height: 22em;        }
        .cvssBarChartWrapper {
          height: 17em;
        }
        .axisContainer.yAxis {
          height: calc( 17.5em + 1px );
        }
        .svgBarWrapper {
          height: 17em;
        }
        .axisContainer.xAxis {
          margin-left: 6.5em;
          width: calc( 100% - 6.5em );
          .axisLabel {
            font-size: 0.625em;
          }
        }
      }
      &.withDescription {
        .widgetContent {
          .chartWrapper {
            min-height: 17em;
            max-height: 17em;        }
          .cvssBarChartWrapper {
            height: 12em;
          }
          .axisContainer.yAxis {
            height: calc( 12.5em + 1px );
          }
          .svgBarWrapper {
            height: 12em;
          }
          .axisContainer.xAxis {
            margin-left: 6.5em;
            width: calc( 100% - 6.5em );
            .axisLabel {
              font-size: 0.625em;
            }
          }
        }
      }
    }
    &.widgetHeight-7 {
      .widgetContent {
        .chartWrapper {
          min-height: 26.5em;
          max-height: 26.5em;        }
        .cvssBarChartWrapper {
          height: 21.5em;
        }
        .axisContainer.yAxis {
          height: calc( 22em + 1px );
        }
        .svgBarWrapper {
          height: 21.5em;
        }
        .axisContainer.xAxis {
          margin-left: 6.5em;
          width: calc( 100% - 6.5em );
          .axisLabel {
            font-size: 0.625em;
          }
        }
      }
      &.withDescription {
        .widgetContent {
          .chartWrapper {
            min-height: 21.5em;
            max-height: 21.5em;        }
          .cvssBarChartWrapper {
            height: 16.5em;
          }
          .axisContainer.yAxis {
            height: calc( 17em + 1px );
          }
          .svgBarWrapper {
            height: 16.5em;
          }
          .axisContainer.xAxis {
            margin-left: 6.5em;
            width: calc( 100% - 6.5em );
            .axisLabel {
              font-size: 0.625em;
            }
          }
        }
      }
    }
    &.widgetHeight-8 {
      .widgetContent {
        .chartWrapper {
          min-height: 30em;
          max-height: 30em;        }
        .cvssBarChartWrapper {
          height: 25em;
        }
        .axisContainer.yAxis {
          height: calc( 25.5em + 1px );
        }
        .svgBarWrapper {
          height: 25em;
        }
        .axisContainer.xAxis {
          margin-left: 6.5em;
          width: calc( 100% - 6.5em );
          .axisLabel {
            font-size: 0.625em;
          }
        }
      }
      &.withDescription {
        .widgetContent {
          .chartWrapper {
            min-height: 25.5em;
            max-height: 25.5em;        }
          .cvssBarChartWrapper {
            height: 20.5em;
          }
          .axisContainer.yAxis {
            height: calc( 21em + 1px );
          }
          .svgBarWrapper {
            height: 20.5em;
          }
          .axisContainer.xAxis {
            margin-left: 6.5em;
            width: calc( 100% - 6.5em );
            .axisLabel {
              font-size: 0.625em;
            }
          }
        }
      }
    }
    // &.widgetHeight-4 {
    //   .widgetContent {
    //     .cvssBarChartWrapper {
    //       height: calc( 100% - 11em );
    //       .svgBarWrapper{
    //         height: calc( 100% - 7em );
    //       }
    //       .axisContainer.yAxis {
    //         height: calc( 100% - 6.5em );
    //       }
    //     }
    //   }
    //   &.withDescription {
    //     .widgetContent {
    //       .cvssBarChartWrapper {
    //         height: calc( 100% - 14em );
    //         .svgBarWrapper{
    //           height: calc( 100% - 10em );
    //         }
    //         .axisContainer.yAxis {
    //           height: calc( 100% - 9.5em );
    //         }
    //       }
    //     }
    //   }
    // }
    // &.widgetHeight-5 {
    //   .widgetContent {
    //     .cvssBarChartWrapper {
    //       height: calc( 100% - 7em );
    //       .svgBarWrapper{
    //         height: calc( 100% - 3em );
    //       }
    //       .axisContainer.yAxis {
    //         height: calc( 100% - 2.5em );
    //       }
    //     }
    //   }
    //   &.withDescription {
    //     .widgetContent {
    //       .cvssBarChartWrapper {
    //         height: calc( 100% - 10em );
    //         .svgBarWrapper{
    //           height: calc( 100% - 6em );
    //         }
    //         .axisContainer.yAxis {
    //           height: calc( 100% - 5.5em );
    //         }
    //       }
    //     }
    //   }
    // }
    // &.widgetHeight-6 {
    //   .widgetContent {
    //     .cvssBarChartWrapper {
    //       height: calc( 100% - 3em );
    //       .svgBarWrapper{
    //         height: calc( 100% );
    //       }
    //       .axisContainer.yAxis {
    //         height: calc( 100% + 0.5em );
    //       }
    //     }
    //   }
    //   &.withDescription {
    //     .widgetContent {
    //       .cvssBarChartWrapper {
    //         height: calc( 100% - 6em );
    //         .svgBarWrapper{
    //           height: calc( 100% - 2em );
    //         }
    //         .axisContainer.yAxis {
    //           height: calc( 100% - 1.5em );
    //         }
    //       }
    //     }
    //   }
    // }
  }
}