/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.checkButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0.5em 1em;
  border-radius: $borderRadius--input;
  font-size: 1em;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  background: $grey--background;
  color: $grey;
  font-weight: 500;
  svg {
    margin-right: 0.5em;
    transition: all 200ms ease-in-out;
    path {
      transition: all 200ms ease-in-out;
      fill: $grey;
      &.checkbox {
        fill: none;
        stroke: $grey;
      }
    }
  }
  &:hover {
    background: $grey--divider;
    color: $darkBlue;
    svg {
      path {
        fill: $darkBlue;
        &.checkbox {
          fill: none;
          stroke: $darkBlue;
        }
      }
    }
  }
  &.owner {
    &.checked, &:hover {
      background: $status--blue--10;
      color: $status--blue;
      svg {
        path {
          fill: $status--blue;
          &.checkbox {
            fill: none;
            stroke: $status--blue;
          }
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      background: none;
      color: $grey--icon;
      svg {
        path {
          fill: $grey--icon;
          &.checkbox {
            fill: none;
            stroke: $grey--icon;
          }
        }
      }
    }
  }
  &.viewer {
    &.checked, &:hover {
      background: $status--green--10;
      color: $status--green;
      svg {
        path {
          fill: $status--green;
          &.checkbox {
            fill: none;
            stroke: $status--green;
          }
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      background: none;
      color: $grey--icon;
      svg {
        path {
          fill: $grey--icon;
          &.checkbox {
            fill: none;
            stroke: $grey--icon;
          }
        }
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: none;
    color: $grey--icon;
    svg {
      path {
        fill: $grey--icon;
        &.checkbox {
          fill: none;
          stroke: $grey--icon;
        }
      }
    }
  }
}