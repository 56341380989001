/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.scanningDashboardWidgetsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-top: 2em;
  color: $darkBlue;
  .dashboardWidgetWrapper.donut {
    .widgetContent {
      display: grid;
      grid-template-columns: 1fr 18em;
      grid-column-gap: 1em;
      .chartAndLabelWrapper {
        h3 {
          margin: 0;
          margin-top: 1em;
          color: $grey;
          width: 100%;
          text-align: center;
          font-weight: 400;
        }
        .svgDonutWrapper {
          max-height: 14em;
        }
      }
    }
  }
  &.collapsed {
    height: 0;
    overflow: hidden;
  }
}