/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.filterBuilderWrapper {
  z-index: 999999999;
  position: relative;
  width: calc( 100% - 15em );
  margin-right: 14em;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: row;
  .filterBuilderTrigger {
    // @include dsButton('minimal-primary');
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    max-height: 2.5em;
    padding: 3px;
    padding-right: 1.25em;
    border-radius: $borderRadius--input;
    border: none;
    background: rgba( $primary, 0.2 );
    color: $primary;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    .iconWrapper {
      background: rgba( #fff, 0.9 );
      border-radius: $borderRadius;
      padding: 0.375em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-right: 0.75em;
      flex-shrink: 0;
      font-weight: 600;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: rgba( $primary, 0.9 );
        }
      }
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .inlineSVG {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        path {
          fill: $primary;
        }
      }
    }
    &:hover {
      background: rgba( $primary, 0.3 );
    }
  }
  .filterBuilderShade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba( $darkBlue, 0.2 );
    z-index: 999998;
  }
  .filterBuilderForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5em;
    border: 1px solid $grey--divider;
    border-radius: $borderRadius--input;
    background: #fff;
    box-shadow: $boxShadow--light;
    flex-shrink: 0;
    position: absolute;
    top: -0.5em;
    left: 0em;
    z-index: 999999;
    max-width: calc( 100% + 14em );
    .fieldWrapper {
      z-index: 2;
      margin-bottom: 0;
      .labelWrapper {
        display: none;
      }
      input {
        border-radius: $borderRadius;
      }
    }
    .filterAttributeWrapper {
      position: relative;
      flex-shrink: 0;
      input.filterAttributeInput {
        border: none;
        padding: 0.5em 1em;
        background: $grey--background;
        box-shadow: none;
        width: auto;
        padding: 0.5em 1em;
        border-radius: $borderRadius;
      }
      &.isFocused {
        input.filterAttributeInput {
          padding: 0.5em 1em;
          margin: 0;
          transition: all 0.3s ease-in-out;
          background: #fff;
          border: 1px solid rgba( $grey--icon, 0.75 );
          color: rgba( $darkBlue, 0.85 );
          box-shadow: $boxShadow--input;
          border-radius: $borderRadius;
          &:focus {
            outline: none;
            color: $darkBlue;
          }
        }
      }
      &.isValid {
        input.filterAttributeInput {
          background: $grey--background;
          color: $darkBlue;
        }
      }
    }
    .filterComparatorWrapper {
      margin: 0 0.75em;
      flex-shrink: 0;
      .singleComparatorWrapper {
        padding: 0.5em 1em;
        background: $grey--background;
        box-shadow: none;
        border-radius: $borderRadius--input;
        font-weight: 600;
        color: rgba( $darkBlue, 0.85);
        border: 1px solid $grey--background;
        box-sizing: border-box;
      }
      &.isValid {
        .singleComparatorWrapper {
          background: $grey--background;
          color: $darkBlue;
          border: 1px solid $status--green--10;
        }
      }
    }
    .autoCompleteOptionsWrapper {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      width: calc( 100% + 1em );
      top: 3em;
      left: -0.5em;
      padding: 0.5em;
      padding: 0.5em;
      border: 1px solid rgba( $grey--icon, 0.75 );
      border-radius: $borderRadius--input;
      color: $darkBlue;
      box-shadow: $boxShadow;
      background: #fff;
      max-height: 50vh;
      overflow: auto;
      li {
        padding: 0;
        margin: 0;
        width: 100%;
        margin-bottom: 0.375em;
        button {
          @include dsButton('minimal');
          padding: 0.375em 0.75em;
          text-align: left;
          justify-content: flex-start;
          transition: all 300ms ease-in-out;
          width: 100% !important;
          border-radius: $borderRadius;
          color: $darkBlue;
          font-weight: 400;
          opacity: 1;
          border: 1px dashed transparent;
          &.current {
            background: $grey--background;
            border: 1px dashed $grey--icon
          }
        }
      }
    }
    .fieldWrapper {
      &.select2 {
        .selectV2Wrapper {
          .selectV2Trigger.isMultiSelect {
            flex-wrap: wrap;
            .selectionsWrapper {
              flex-wrap: wrap;
              margin-bottom: -0.25em;
              .selectionWrapper {
                margin-bottom: 0.25em;
              }
            }
          }
        }
        .selectionOverflow {
          flex-shrink: 0;
        }
      }
      &.textarea {
        textarea {
          padding: 0.375em 1em;
          margin: 0;
          min-width: 40em;
          height: 2.5em;
        }
      }
      &.date {
        input[ type="date" ] {
          margin-top: 0;
        }
      }
    }
    .finishFilterButton {
      @include dsButton( 'minimal-green' );
      padding: 0.5em 1em;
      margin-left: 0.5em;
      flex-shrink: 0;
      .inlineSVG {
        path {
          fill: $status--green;
        }
      }
    }
  }
}