/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

strong.riskReduction {
  color: $risk--darkRed;
  &.high {
    color: $risk--red;
  }
  &.moderate {
    color: $risk--orange;
  }
  &.low {
    color: $risk--yellow;
  }
  &.minimal {
    color: $risk--green;
  }
  &.negligible {
    color: $primaryBlue;
  }
  &.none {
    color: $grey;
  }
  &.unknown {
    color: $grey--icon;
  }
}