/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.modalContainer.accepted_risk_plan {
  .modalBody {
    padding: 0em;
    padding-top: 2em;
    min-height: 50vh;
    .flashMessage.asNotification {
      margin: 1em 2em;
      width: calc( 100% - 4em );
    }
    .formWrapper {
      padding: 0 2em;
      .fieldGroupWrapper {
          padding: 0;
          margin: 0;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1em;
        &.task_vulnerability_selected, &.task_host_selected {
          .fieldWrapper {
            grid-column: 1 / 3;
            &#reason_vulnerability_select_fieldWrapper,
            &#reason_host_select_fieldWrapper {
              grid-column: 1 / 2;
            }
            &#expiration_vulnerability_date_fieldWrapper,
            &#expiration_host_date_fieldWrapper {
              grid-column: 2 / 3;
              input {
                margin-top: 0;
                padding: 0.75em 0.5em 0.75em 1em;
              }
            }
          }
        }
      }
    }
    .itemsContainer {
      .accordionWrapper {
        border-bottom: none;
        .accordionHeader {
          h3 {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 1em;
            span.itemsCount {
              font-weight: 400;
              margin-left: 0.25em;
            }
          }
        }
        .accordionBody {
          overflow: auto;
          max-height: 25em;
          .selectAllButton {
            padding: 0;
            margin: 0;
            margin-bottom: 0.5em;
            cursor: pointer;
            background: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border: none;
            .inlineSVG {
              transition: all 0.3s ease-in-out;
              opacity: 0.6;
              margin-right: 0.5em;
            }
            &:hover {
              .inlineSVG {
                opacity: 1;
              }
            }
          }
          label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 0.75em;
            cursor: pointer;
            .labelWrapper {
              margin-left: 0.5em;
            }
          }
        }
      }
    }
  }
}