/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.riskContributionWrapper {
  .recordRiskAndRatingWrapper {
    margin-bottom: 1em;
    margin-left: 0;
  }
  .description {
    background: $grey--background;
    padding: 0.5em 1em;
    border-radius: $borderRadius;
    color: $darkBlue;
  }
  .collapsibleSectionWrapper {
    .collapsibleSectionHeader {
      .headerLeft {
        align-items: center;
      }
    }
  }
}