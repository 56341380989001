/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@media screen and (max-width: 1200px) {
  // #topBar {
  //   .smallScreenMenuContainer {
  //     display: flex;
  //   }
  //   .tabsContainer {
  //     display: none;
  //   }
  // }
}
