/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.ratingBadge {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 2em;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  width: 8em;
  border: 1px solid $grey;
  background: $grey--background;
  color: $grey;
  border-radius: $borderRadius--full;
  font-family: $sans;
  &.success, &.active, &.green {
    background: $status--green--10;
    color: $status--green;
    border-color: $status--green;
  }
  &.red, &.alert {
    background: $status--red--10;
    color: $status--red;
    border-color: $status--red;
  }
  &.critical, &.Critical {
    background: $critical--10;
    color: $critical;
    border-color: $critical;
  }
  &.high, &.High, &.important, &.Important {
    background: $high--10;
    color: $high;
    border-color: $high;
  }
  &.moderate, &.Moderate, &.medium, &.Medium {
    background: $moderate--10;
    color: $moderate;
    border-color: $moderate;
  }
  &.low, &.Low {
    background: $low--10;
    color: $low;
    border-color: $low;
  }
  &.minimal, &.Minimal {
    background: $minimal--10;
    color: $minimal;
    border-color: $minimal;
  }
  &.negligible {
    background: $primary--10;
    color: $primary;
    border-color: $primary;
  }
  &.unknown {
    background: $grey--background;
    color: $grey;
    border-color: $grey;
  }
  &.altVersion {
    border: 1px solid $grey;
    background: $grey;
    color: #fff;
    &.success, &.active, &.green {
      background: $status--green;
      color: #fff;
      border-color: $status--green;
    }
    &.red, &.alert {
      background: $status--red;
      color: #fff;
      border-color: $status--red;
    }
    &.critical, &.Critical {
      background: $critical;
      color: #fff;
      border-color: $critical;
    }
    &.high, &.High, &.important, &.Important {
      background: $high;
      color: #fff;
      border-color: $high;
    }
    &.moderate, &.Moderate, &.medium, &.Medium {
      background: $moderate;
      color: #fff;
      border-color: $moderate;
    }
    &.low, &.Low {
      background: $low;
      color: #fff;
      border-color: $low;
    }
    &.minimal, &.Minimal {
      background: $minimal;
      color: #fff;
      border-color: $minimal;
    }
    &.negligible {
      background: $primary;
      color: #fff;
      border-color: $primary;
    }
    &.unknown {
      background: $grey;
      color: #fff;
      border-color: $grey;
    }
  }
  &.beta {
    background: $primary--10;
    color: $primary;
    border-color: $primary;
    text-transform: capitalize;
    height: 1.75em;
    font-size: 0.75em;
    width: 4em;
    display: inline-flex;
    margin-left: 1em;
    &.altVersion {
      background: $primary;
      color: #fff;
    }
  }
  &.new {
    background: $status--green--10;
    color: $status--green;
    border-color: $status--green;
    text-transform: capitalize;
    height: 1.75em;
    font-size: 0.75em;
    width: 4em;
    display: inline-flex;
    margin-left: 1em;
    &.altVersion {
      background: $status--green;
      color: #fff;
    }
  }
  &.autoprovisioning {
    width: 13em;
  }
  &.cvssRatingBadge {
    width: 11em;
  }

}