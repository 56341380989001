/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.userDetails_pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .avatarInitials {
      height: 2em;
      width: 2em;
      line-height: 2em;
      // font-size: ;
    }
    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: $darkBlue;
      font-size: 1.25em;
      font-weight: 600;
      margin: 0;
      padding: 0;
      margin-left: 0.5em;
      .ratingBadge {
        margin-left: 0.5em;
        width: unset;
        padding-right: 1em;
        padding-left: 1em;
        &.Admin {
          color: $status--blue;
          border-color: $status--blue;
          background: $status--blue--10;
        }
        &.Report {
          color: $status--green;
          border-color: $status--green;
          background: $status--green--10;
          &.Consumer {
            color: $grey;
            border-color: $grey;
            background: $grey--background;
          }
        }
      }
    }
  }
  .right {
    button {
      @include dsButton('minimal-primary');
      .inlineSVG {
        path {
          fill: $primary;
        }
      }
    }
  }
}

.userDetails__MainPanel__Body {
  padding: 1em;
  background: #fff;
  border-radius: $borderRadius--input;
}
.userDetails__MainPanel__Body {
  padding: 2em;
  .userDetails__MainPanel__Header {
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 1em;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: $darkBlue;
    .inlineSVG {
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      path {
        fill: $grey--icon;
      }
    }
  }
  .collapsibleSectionWrapper {
    border-bottom: none;
    .collapsibleSectionHeader {
      padding: 1em;
      padding-left: 0;
      .headerLeft {
        align-items: center;
        .checkButton {
          margin-right: 1em;
        }
      }
    }
    .collapsibleSectionBody {
      ul {
        li {
          margin-bottom: 0;
          padding: 1em 0;
          border-top: 1px solid $grey--divider;
        }
      }
    }
    &.collapsed {
      border-bottom: 1px solid $grey--divider;
    }
  }
  &.access_and_credentials {
    .formWrapper {
      margin-bottom: 1em;
      margin-top: 1em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2em;
    }
    .accessActionsWrapper {
      .accessActionsHeader {
        padding-top: 1em;
        border-top: 1px solid $grey--divider;
        color: $darkBlue;
        font-size: 1.125em;
        margin-bottom: 1em;
      }
    }
  }
  &.details_and_preferences {
    .formWrapper {
      margin-bottom: 1em;
      margin-top: 1em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2em;
    }
    .notificationSubscriptionsWrapper.userDetails__MainPanel__NotificationSubscriptions {
      margin-top: 1em;
      border-top: 1px solid $grey--divider;
      padding: 1em 0 0 0;
      &:before {
        content: 'Notifications and Subscriptions';
        color: $darkBlue;
        font-weight: 500;
        font-size: 1.125em;
        margin-bottom: 1em;
      }
      .subscriptionHeader {
        span {
          color: $darkBlue;
          font-weight: 500;
          text-transform: none;
        }

      }
      .formWrapper {
        display: grid;
        grid-template-columns: 1fr;
        .fieldGroupWrapper {
          width: 100%;
        }
      }
      .modalActions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding-top: 1em;
        margin-top: 1em;
        border-top: 1px solid $grey--divider;
        width: 100%;
        button {
          @include dsButton('minimal-primary');
          &.cancelButton {
            display: none;
          }
        }
      }
    }
  }
  .accessActions {
    width: 100%;
    padding: 1em;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid $grey--divider;
    button {
      @include dsButton('minimal-primary');
    }
  }
}
