/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
 @use "../../../../../../styles/globals.scss" as *;

 .fieldWrapper.number {
   &.focused {
     .editIcon {
      margin-right: 2em;
     }
   }
 }