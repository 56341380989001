/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.setupPageContainer.remediation_plan {
  padding: 0;
  .setupRecordsList.remediationPlans.collapsed {
    display: none;
  }
  .notification.alert {
    margin: 0 1em 2em 1em;
  }
}