/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.acsURL {
  .URLWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $grey--background;
    border-radius: $borderRadius--input;
    padding: 0.5em;
    width: 100%;
    pre {
      padding: 0;
      border: none;
      background: none;
      color: $darkBlue;
      margin: 0;
      white-space: nowrap;
    }
    input[type="text"] {
      background: $grey--divider;
      border: 1px solid $grey--icon;
      border-radius: $borderRadius--input;
      color: $darkBlue;
      width: auto;
      margin: 0 0.25em;
      padding: 0.25em 0.75em;
      margin-top: 0;
    }
  }
}
