/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.notificationSettingsPageContainer {
  .notificationSubscriptionsWrapper {
    padding: 1em;
    border-radius: $borderRadius;
    background: #fff;
  }
}