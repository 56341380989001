/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.flashMessage {
  border-radius: 0.75em;
  border: 2px solid rgba( $status--red, 0.5 );
  background: $status--red--background;
  font-family: $sans;
  position: relative;
  padding: 0.25em 0.25em 0.25em 1.25em;
  width: 36em;
  max-width: 50vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $darkBlue;
  margin-bottom: 1em;
  box-shadow: $boxShadow--medium;
  a, a:visited {
    color: $status--red;
    opacity: 1;
    text-decoration: underline;
  }
  .inlineSVG {
    width: 2em;
    height: 2em;
    flex-shrink: 0;
    path {
      fill: #fff;
    }
    path.circle {
      fill: $status--red;
    }
  }
  .flashMessageBody {
    margin-left: 1.5em;
    flex-grow: 1;
    display: block;
  }
  h2 {
    font-size: 1.125em;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-bottom: 0.5em !important;
    color: $status--red;
  }
  .flashMessageActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-top: 0.25em;
    padding-right: 0.375em;
    button {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: $status--red;
        }
      }
      &.copyToClipBoardButton {
        margin: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        margin-top: 0.5em;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
        }
      }
    }
  }
  &.success {
    border: 2px solid rgba( $status--green, 0.5 );
    background: $status--green--background;
    a, a:visited {
      color: $status--green;
    }
    h2 {
      color: $status--green;
    }
    .inlineSVG {
      path.circle {
        fill: $status--green;
      }
    }
    .flashMessageActions {
      button {
        .inlineSVG {
          path {
            fill: $status--green;
          }
        }
      }
    }
  }
  &.warning {
    border: 2px solid rgba( $status--yellow, 0.5 );
    background: $status--yellow--background;
    a, a:visited {
      color: $status--yellow;
    }
    h2 {
      color: $status--yellow;
    }
    .inlineSVG {
      path.circle {
        fill: $status--yellow;
      }
    }
    .flashMessageActions {
      button {
        .inlineSVG {
          path {
            fill: $status--yellow;
          }
        }
      }
    }
  }
  &.info {
    border: 2px solid rgba( $status--blue, 0.5 );
    background: $status--blue--background;
    a, a:visited {
      color: $status--blue;
    }
    h2 {
      color: $status--blue;
    }
    .inlineSVG {
      path.circle {
        fill: $status--blue;
      }
    }
    .flashMessageActions {
      button {
        .inlineSVG {
          path {
            fill: $status--blue;
          }
        }
      }
    }
  }
  &:hover {
    .flashMessageActions {
      button {
        &.copyToClipBoardButton {
          opacity: 0.3;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  &.fadeOut {
    opacity: 0;
  }
  &.asNotification {
    max-width: unset;
    width: 100%;
    box-shadow: none;
    padding-top: 1em;
    padding-bottom: 1em;
    margin: 1em 0;
  }
}

@keyframes slideFromRight {
  100% {
    margin-right: 0em;
  }
}