/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.demoModeContentWrapper {
  background: #fff;
  border: 1px solid rgba( $grey--icon, 0.5 );
  box-shadow: $boxShadow--light;
  border-radius: $borderRadius--input;
  padding: 1em;
  color: $darkBlue;
  margin-bottom: 1em;
  .trialButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    button {
      height: 2.5em;
      width: 12.25em;
      border-radius: $borderRadius--full;
      padding: 0.5em 0.5em 0.5em 1.25em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      background: $primaryGradient;
      color: #fff;
      border: none;
      font-size: 1.25em;
      font-weight: 600;
      position: relative;
      z-index: 1;
      transition: all 0.3s ease-in-out;
      margin-right: 0.5em;
      margin-top: 0.5em;
      cursor: pointer;
      .iconWrapper {
        width: 1.5em;
        height: 1.5em;
        border-radius: $borderRadius--full;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-left: 1em;
        .inlineSVG {
          width: 1em;
          height: 1em;
          path {
            fill: $primaryGradient2;
          }
        }
      }
      &:before {
        z-index: -1;
        content: "";
        background: $primaryGradient;
        position: absolute;
        height: 3em;
        width: 12.875em;
        border-radius: $borderRadius--full;
        left: calc( 0.625em / -2 );
        top: -0.25em;
        opacity: 0.3;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .buttonSubText {
      margin-top: 0.75em;
      color: $grey;
    }
  }
  .demoModeContentHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1em;
    padding-left: 0.5em;
    .iconWrapper {
      background: $primary--10;
      width: 2.5em;
      height: 2.5em;
      border-radius: $borderRadius--full;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-right: 0.5em;
      flex-shrink: 0;
      .inlineSVG {
        path {
          fill: $primary;
        }
      }
    }
    h2 {
      font-weight: 400;
      strong {
        font-weight: 600;
      }
      span, strong {
        margin-right: 0.25em;
      }
    }
  }
  .featuresWrapper {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    justify-content: space-between;
    padding: 1em;
    border-radius: $borderRadius;
    background: $primaryGradient--10;
    width: 100%;
    .startTrial {
      margin-bottom: 1.25em;
      font-size: 1.125em;
      flex-wrap: wrap;
    }
    ul {
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: $darkBlue;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          margin-right: 0.5em;
          flex-shrink: 0;
          path {
            fill: $primaryBlue;
          }
        }
      }
    }
    &.default {
      .startTrial {
        margin-bottom: 0;
      }
    }
    .trialButtonWrapper {
      flex-shrink: 0;
      margin-left: 2em;
    }
  }
}
.demoContentfeatureVisual {
  display: block;
  margin: 1em;
  object-fit: contain;
  max-height: calc( 100vh - 35em );
  border-radius: $borderRadius;
}