/***************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.filterWrapper.buttons {
  color: $darkBlue;
  .labelWrapper {
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 0.5em;
  }
  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    button {
      @include dsButton( 'minimal--dark' );
      border-radius: $borderRadius--input;
      background: $grey--background;
      color: $grey--icon;
      margin-right: 0.75em;
      text-transform: uppercase;
      padding: 0.625em 1.5em;
      &:hover {
        background: $grey--icon;
        color: $darkBlue;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &.selected {
        background: $grey--icon;
        color: $darkBlue;
      }
      &.high {
        &:hover, &.selected {
          background: $high--10;
          color: $high;
        }
      }
      &.medium {
        &:hover, &.selected {
          background: $moderate--10;
          color: $moderate;
        }
      }
      &.low {
        &:hover, &.selected {
          background: $low--10;
          color: $low;
        }
      }
    }
  }
}
