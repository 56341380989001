/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.fieldGroupWrapper {
  h2 {
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  .sectionHeader {
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 1em;
    display: inline-block;
  }
  &.asSentence {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .sectionHeader {
      width: 100%;
    }
  }
  &.demoMode {
    h2, .sectionHeader {
      opacity: 0.6;
    }
  }
}