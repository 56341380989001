/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// uses the necessary classes and default styling provided by the react-grid-layout library, plus
// custom styling
@use "../../../../../styles/globals.scss" as *;

#pageHeaderContainer.reportingDashboardPageHeader {
  // display: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: $darkBlue;
    .dsLogo {
      width: 1.5em;
      height: 1.5em;
      margin-right: 0.5em;
    }
    .editModeButton {
      @include dsButton( 'minimal-default' );
      background: $grey--background;
      font-size: 0.875em;
      margin-left: 1em;
      color: $darkBlue;
      .inlineSVG {
        path {
          fill: $darkBlue;
        }
      }
      &:hover {
        background: $grey--divider;
      }

    }
    .copyDashboardButton {
      width: 2em;
      height: 2em;
      border-radius: $borderRadius--full;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      transition: all 0.3s ease-in-out;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      background: $grey--background;
      opacity: 1;
      z-index: 1;
      margin-left: 1em;
      .inlineSVG {
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        path {
          fill: $darkBlue;
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover {
        background: $grey--divider;
        .inlineSVG {
          path {
            fill: $darkBlue;
          }
        }
      }
      &:disabled {
        pointer-events: none;
        cursor: default;
        .inlineSVG {
          path {
            fill: $grey--icon;
          }
        }
      }
    }
  }
  .exportActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .checkboxWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: $darkBlue;
      cursor: pointer;
      label {
        cursor: pointer;
      }
      .inlineSVG {
        margin-right: 0.5em;
        width: 1.25em;
        height: 1.25em;
        path {
          fill: $primaryBlue;
        }
      }
    }
    .exportMenuTrigger {
      @include dsButton( 'minimal-dark' );
      margin-left: 1em;
      color: $grey--icon !important;
      &:disabled {
        .inlineSVG {
          path {
            fill: $grey--icon;
          }
        }
      }
    }
  }

}
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  background: none;
  border: 1px dashed $grey;
  border-radius: $borderRadius--input;

  cursor: move;
  .react-resizable-handle {
    position: absolute;
    width: 0.75em;
    height: 0.75em;
    border-radius: $borderRadius--full;
    z-index: 2;
    background: $grey;
    &.react-resizable-handle-e {
      right: calc( -0.375em - 1px );
      top: calc( 50% - 0.375em );
      cursor: ew-resize;
    }
    &.react-resizable-handle-s {
      cursor: ns-resize;
      left: calc( 50% - 0.375em );
      bottom: calc( -0.375em - 1px );
    }
  }
  &.cssTransforms {
    transition-property: transform;
  }
  &.resizing {
    z-index: 3;
    will-change: width, height;
  }
  &.react-resizable {
    position: relative;
  }
  &.react-resizable-hide {
    border: 1px solid transparent;
    // border-top: 1px solid $grey--divider;
    cursor: default;
    .react-resizable-handle {
      display: none;
    }
  }
  &.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
    .draggableIcon {
      path {
        fill: $primaryBlue;
      }
    }
  }
  &.dropping {
    visibility: hidden;
  }
  &.react-grid-placeholder {
    background: $grey--background--light;
    border: 1px dashed $grey;
    transition-duration: 100ms;
    z-index: 2;
    border-radius: $borderRadius;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  &.resizing, &.react-draggable-dragging {
    opacity: 0.75;
  }
  &:hover, &.resizing, &.react-draggable-dragging {
    box-shadow: $boxShadow--medium;
    border: 1px dashed $darkBlue;
    .react-resizable-handle {
      background: $darkBlue;
    }
    &.react-resizable-hide {
      box-shadow: none;
      border: 1px solid transparent;
      // border-top: 1px solid $grey--divider;
    }
  }
}

// custom styling
.dashboardsGridWrapper {
  font-family: $sans;
  min-height: 50vh;
  overflow: hidden;
  position: relative;
  .loadingContainer {
    border-radius: $borderRadius;
  }
  .pagesContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .pageWrapper {
      height: calc( 62px * 12 );
      width: 100%;
      border-bottom: 1px dashed $grey--icon;
      position: relative;
      &:after {
        content: 'Page Break';
        background: $grey--background;
        color: $grey;
        text-align: center;
        padding: 1em;
        position: absolute;
        bottom: -1.5em;
        width: 9em;
        left: calc( 50% - 4.5em );
      }
    }
  }

}

// for printing
@media print {
  #pageContent {
    padding: 0 !important;
  }
  .reporting_dashboardPageContainer.reportingContainer {
    background: none;
    height: unset;
    max-height: unset;
    width: 10in !important;
    .dashboardsGridWrapper {
      padding-top: 0 !important;
      width: 10in !important;
      .pagesContainer {
        display: none !important;
      }
      .react-grid-item {
        page-break-inside: avoid;
      }
      .dashboardWidgetWrapper {
        font-size: 0.875em;
        overflow: hidden;
        margin: 0;
        page-break-inside: avoid;
        // specifically specify the heights of different widget containers
        // 12 height = 1 page height
        // 6 width = 1 page width
        &.widgetHeight-3 {
          height: calc( 7.5in * ( 3 / 12 ) );
        }
        &.widgetHeight-4 {
          height: calc( 7.5in * ( 4 / 12 ) );
        }
        &.widgetHeight-5 {
          height: calc( 7.5in * ( 5 / 12 ) );
        }
        &.widgetHeight-6 {
          height: calc( 7.5in * ( 6 / 12 ) );
        }
        &.widgetHeight-7 {
          height: calc( 7.5in * ( 7 / 12 ) );
        }
        &.widgetHeight-8 {
          height: calc( 7.5in * ( 8 / 12 ) );
        }
        &.widgetHeight-9 {
          height: calc( 7.5in * ( 9 / 12 ) );
        }
        &.widgetHeight-10 {
          height: calc( 7.5in * ( 10 / 12 ) );
        }
        &.widgetHeight-11 {
          height: calc( 7.5in * ( 11 / 12 ) );
        }
        &.widgetHeight-12 {
          height: 7.5in;
        }
        &.widgetWidth-1 {
          width: calc( 10in * ( 1 / 6 ) );
        }
        &.widgetWidth-2 {
          width: calc( 10in * ( 2 / 6 ) );
        }
        &.widgetWidth-3 {
          width: calc( 10in * ( 3 / 6 ) );
        }
        &.widgetWidth-4 {
          width: calc( 10in * ( 4 / 6 ) );
        }
        &.widgetWidth-5 {
          width: calc( 10in * ( 5 / 6 ) );
        }
        &.widgetWidth-6 {
          width: 10in;
        }
        .widgetHeaderContainer {
          margin-bottom: 0.5em;
          font-size: 1em;
        }
        .widgetDescriptionContainer {
          margin: 0.5em 0;
          font-size: 0.875em;
          p {
            margin-bottom: 0;
          }
        }
        .widgetContent {
          height: 100% !important;
          page-break-inside: avoid;
          width: 100% !important;
          .overtimeMultiAreaWrapper, .overtimeAreaWrapper,
          .overtimeBarWrapper, .overtimeMultiBarWrapper {
            grid-template-columns: 6em 1fr;
            .axisLinesContainer {
              &.yAxis, &.xAxis {
                padding-left: 6em;
              }
            }
            &.withLegend {
              grid-template-columns: 6em 1fr 10em;
              .multiAreaPlusBarWrapper, .areaPlusBarWrapper {
                width: 100%;
              }
              .axisLinesContainer {
                &.yAxis, &.xAxis {
                  padding-right: 10em;
                }
              }
            }
          }
          .chartLegend {
            font-size: 0.5em;
          }
          .axisLinesContainer {
            .XAxisLineBox {
              border-right-width: 1px !important;
            }
            .YAxisLineBox {
              border-top-width: 1px !important;
            }
          }
          .axisContainer {
            &.yAxis {
              border-right-width: 1px !important;
            }
            .axisLabel, .axisTic {
              font-size: 0.5em !important;
              &:after {
                display: none;
              }
              &.last {
                top: 2em;
              }
            }
            .axisTic {
              padding-right: 1em;
            }
            &.xAxis {
              width: calc( 100% - 10em );
              padding-top: 0.5em;
              padding-left: 6em;
              &.fullWidth {
                width: calc( 100% - 3em );
              }
            }
          }
          .overtimeBarWrapper, .overtimeMultiBarWrapper {
            grid-template-columns: 6em 1fr 10em;
            .svgBarWrapper, .multiAreaPlusBarWrapper, .areaPlusBarWrapper {
              width: 100%;
              border-bottom-width: 1px;
            }
            .axisLinesContainer {
              &.yAxis, &.xAxis {
                padding-right: 10em;
              }
            }
          }
          .overtimeMultiAreaWrapper {
            svg {
              border-bottom-width: 1px !important;
            }
          }
          .tableWidgetWrapper {
            width: 100%;
            overflow: hidden;
            page-break-inside: unset;
            margin: 0;
            .tableRow {
              width: 100%;
            }
          }
        }
        // &.withLegend {
        //   .widgetContent {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     justify-content: flex-start;
        //     .svgDonutWrapper, .widgetChartWrapper {
        //       width: 100%;
        //     }
        //   }
        // }
        // specific widgets, mainly donuts, needing extra attention
        &.vulnerability_instances_exploit_status_breakdown,
        &.vulnerability_instances_tag_breakdown,
        &.vulnerability_instances_os_family_breakdown,
        &.vulnerability_instances_cvss_breakdown {
          .widgetContent {
            grid-template-columns: 1fr 9.5em;
            align-items: center;
            .svgDonutWrapper {
              width: 100%;
              svg {
                width: 100%;
              }
            }
          }
        }
        &.vulnerability_instances_cvss_breakdown.barchart {
          .widgetContent {
            .chartWrapper {
              .cvssBarChartWrapper {
                grid-template-columns: 6em 1fr;
              }
              .axisContainer.xAxis {
                border-top-width: 1px;
                width: calc( 100% - 5.5em ) !important;
                width: 100%;
                padding-left: 0.5em;
                padding-right: 0;
                margin-left: 5.5em;
                margin-right: 0;
              }
            }
          }
        }
        &.vulnerability_instances_global {
          .widgetContent {
            padding-top: 0;
          }
          .excludeNotScannedToggle {
            display: none;
          }
          svg {
            .legendLine {
              display: none;
            }
          }
          .legendsContainer {
            font-size: 0.875em;
            margin-top: -4em;
            .categoryLegendWrapper {
              width: 20em;
              &.for_review {
                width: calc( 100% - 40em );
                margin-left: 0;
                margin-right: 0;
                .legendItemsWrapper {
                  grid-gap: 0.5em;
                }
              }
            }
            .legendColumn {
              .instanceCategoryLabel {
                flex-wrap: wrap;
                .instanceCategoryLabelWrapper {
                  flex-wrap: wrap;
                }
              }
            }
          }
        }
        &.risk_over_time {
          .selectFieldWrapper {
            display: none;
          }
        }
      }
    }
  }
}