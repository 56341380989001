/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.sensitiveAssetItemListWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25 0.5em;
  color: $darkBlue;
  cursor: default;
  border-radius: $borderRadius--input;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .nodeIconWrapper {
      background: $grey;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 2em;
      height: 2em;
      border-radius: $borderRadius--input;
      margin-right: 0.75em;
      box-shadow: 0 0 0 0.25em $grey--divider;
      position: relative;
      flex-shrink: 0;
      svg {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: $grey;
          &.svgNodeIconOutline {
            fill: #fff;
          }
        }
      }
      .dot {
        position: absolute;
        top: -0.5em;
        right: -0.5em;
        width: 1em;
        height: 1em;
        background: $status--red;
        border: 2.5px solid #fff;
        border-radius: $borderRadius--full;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875em;
    flex-shrink: 0;
    padding-left: 0.25em;
    label {
      width: 100%;
      text-align: center;
      margin-bottom: 0.25em;
      color: $grey;
    }
    strong {
      color: $darkBlue;
      width: 100%;
      text-align: center;
    }
  }
  &:hover {
    text-decoration: none;
    color: $darkBlue;
  }
  &.asLink {
    cursor: pointer;
    transition: all 300ms ease-in-out;
    padding: 0.5em 0.75em;
    .left {
      .nodeIconWrapper {
        transition: all 300ms ease-in-out;
        svg {
          path {
            transition: all 300ms ease-in-out;
          }
        }
      }
    }
    &:hover {
      background: $grey--background;
      text-decoration: none;
      color: $darkBlue;
      .left {
        .nodeIconWrapper {
          background: $primary;
          box-shadow: 0 0 0 0.375em rgba( $primary, 0.3 );
          svg {
            path {
              fill: $primary;
              &.svgNodeIconOutline {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
  &.exposed {
    .left {
      .nodeIconWrapper {
        background: $status--red--80;
        box-shadow: 0 0 0 0.25em $status--red--20;
        svg {
          path {
            fill: $status--red--80;
            &.svgNodeIconOutline {
              fill: #fff;
            }
          }
        }
      }
    }
    .right {
      strong {
        color: $status--red;
      }
    }
    &:hover {
      background: $status--red--10;
      .left {
        .nodeIconWrapper {
          background: $status--red;
          box-shadow: 0 0 0 0.375em rgba( $status--red, 0.3 );
          svg {
            path {
              fill: $status--red;
              &.svgNodeIconOutline {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}