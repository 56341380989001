/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

// universal field styling
.fieldWrapper {
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  margin-bottom: 1.5em;
  display: none;
  label {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all 0.3s ease-in-out;
    .labelWrapper {
      width: 100%;
      margin-bottom: .5em;
      // font-size: .875em;
      font-weight: 500;
      // text-transform: uppercase;
      color: $darkBlue;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      line-height: 1.5em;
      span.required {
        margin-left: 0.5em;
        margin-bottom: -0.25em;
        font-size: 1.25em;
        color: $status--red;
      }
    }
  }
  .editIcon {
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    right: 0.75em;
    top: 2em;
    opacity: 0.5;
    transition: margin-right 0.3s ease-in-out;
    display: none;
  }
  .removeFieldButton {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    .inlineSVG {
      height: 1.5em;
      opacity: 0.4;
    }
    &:hover {
      .inlineSVG {
        opacity: 1;
      }
    }
  }
  &.included {
    display: flex;
  }
  &.invalid {
    span.labelWrapper {
      color: $status--red;
    };
  };
  // adds red dot
  &.invalid, &.updated, &.hasWarning {
    span.labelWrapper {
      padding-left: 1.5em;
    }
    label {
      &:before {
        content: "";
        position: absolute;
        height: 0.75em;
        width: 0.75em;
        border-radius: $borderRadius--full;
        background: $status--red;
        top: 0.375em;
        left: 0em;
      }
    }
  }
  // adds yellow dot
  &.updated {
    label {
      &:before {
        background: $status--yellow;
      }
    }
    &.invalid {
      label {
        &:before {
          background: $status--red;
        }
      }
    }
  }
  // adds orange dot
  &.hasWarning {
    label {
      &:before {
        background: $status--orange;
      }
    }
  }
  &.disabled {
    color: $grey--icon;
    cursor: not-allowed;
    label {
      color: $grey--icon;
      cursor: not-allowed;
      .labelWrapper {
        color: $grey--icon;
        cursor: not-allowed;
      }
      input, select, textarea {
        color: $grey--icon;
        cursor: not-allowed;
        box-shadow: none;
      }
      input {
        border: 1px solid $grey--divider;
        background: $grey--background--light;
        box-shadow: none;
      }
    }
  }
  &.dontTrack {
    select {
      option.original {
        background: none;
        font-weight: 400;
      }
    }
  }
  .fieldErrors {
    width: 100%;
    padding: 0.5em 0 0.5em 0;
    margin: 0;
    color: $status--red;
    &.warning {
      color: $status--orange;
    }
  }
  &.asSentence {
    padding: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 0.375em 0.5em 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    label {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: unset;
      flex-grow: 0;
      flex-shrink: 0;
      .labelWrapper {
        margin: 0;
        text-transform: none;
        color: $darkBlue;
        font-size: 1em;
        flex-grow: 0;
        flex-shrink: 0;
        width: unset;
        font-weight: 600;
        margin-right: 0.375em;
        .required {
          display: none;
        }
        .helpTrigger {
          width: 0.875em;
          height: 0.875em;
          .inlineSVG {
            width: 0.875em;
            height: 0.875em;
          }
        }
        &.post {
          margin-left: 0.375em;
          margin-right: 0em;
        }
      }
      .selectFieldWrapper {
        flex-grow: 0;
        flex-shrink: 0;
        width: unset;
        select {
          margin-top: 1px;
          margin-bottom: 1px;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          padding-right: 2.25em;
          border-radius: $borderRadius;
        }
      }
      .numberFieldWrapper, .textFieldWrapper {
        width: unset;
        flex-grow: 0;
        flex-shrink: 0;
        input[ type='text' ], input[ type='number' ] {
          margin-top: 1px;
          margin-bottom: 1px;
          background: rgba( #fff, 0.75 );
          border: 1px solid $grey--icon;
          border-top: 1px solid $grey--icon;
          border-radius: $borderRadius;
          color: $darkBlue;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          width: unset;
          flex-grow: 0;
          flex-shrink: 0;
        }
        .editIcon {
          display: none;
        }
      }
    }
    &:last-of-type {
      padding-right: 0;
      .additiveDivider {
        display: none;
      }
    }
    &.included {
      width: auto;
      height: auto;
    }
    &.invalid {
      label {
        &:before {
          top: 50%;
          margin-top: -0.375em;
        }
        .labelWrapper {
          padding-left: 1.25em;
          color: $status--red;
        }
        .fieldErrors {
          margin-left: 0.5em;
        }
      }
    }
  }

  // specific styling for basic field types, more complex styling for advanced fields in respective scss files
  input[type="date"] {
    margin: 0;
    margin-top: 1em;
    padding: 0.5em 0.25em 0.5em 0.75em;
    border: 1px solid rgba( $grey--icon, 0.75 );
    background: #fff;
    border-radius: $borderRadius--input;
    color: $darkBlue;
    box-shadow: $boxShadow--input;
    &:focus {
      outline: none;
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='time'] {
    padding: 0.625em 1.125em;
    margin: 0;
    transition: all 0.3s ease-in-out;
    background: #fff;
    border: 1px solid rgba( $grey--icon, 0.75 );
    border-radius: $borderRadius--input;
    color: rgba( $darkBlue, 0.85 );
    box-shadow: $boxShadow--input;
    &:focus {
      outline: none;
      color: $darkBlue;
      background: $grey--background;
    }
  }
  input[type="text"] {
    padding-right: 2.5em;
  }
  select, textarea {
    color: $darkBlue;
    background: $grey--background--light;
    border: 1px solid rgba( $grey--icon, 0.75 );
    border-radius: $borderRadius--input;
    box-shadow: $boxShadow--input;
    padding: 1em 1em;
    margin: 0;
    &:focus {
      outline: none;
    }
  }
  select {
    padding: 0.625em 0.75em;
    background: #fff;
    background-repeat: no-repeat;
    background-size: auto;
    background-image: url('./../../../../images/icons/input/select-arrow.svg');
    background-position: calc(100% - 0.75em) 50%;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $grey--icon;
    font-weight: 400;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $grey--icon;
    font-weight: 400;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $grey--icon;
    font-weight: 400;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $grey--icon;
    font-weight: 400;
  }
  &.hidden {
    margin: 0;
    margin-bottom: 0;
    height: 0;
    visibility: hidden;
    label {
      margin: 0;
      margin-bottom: 0;
      .labelWrapper {
        margin-bottom: 0;
        margin: 0;
      }
      input {
        padding: 0;
        margin: 0;
      }
    }
  }
}