/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.recordDetails__MainPanel_BodyRow.evidenceSummary {
  padding: 1em 0 !important;
  border-bottom: none !important;
  position: relative;
  .mainPanel__SectionHeader {
    span {
      font-weight: 400;
      strong {
        font-weight: 600;
        margin-right: 0.25em;
      }
      .evidenceCount {
        margin-left: 0.25em;
        font-weight: 600;
      }
    }
  }
}