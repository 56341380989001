/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.modalContainer.reactSetupModal.integration {
  .moreConfigurationOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    a {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .inlineSVG {
        margin-left: 0.5em;
        height: 1.25em;
        width: 1.25em;
      }
    }
    .primaryButton {
      @include dsButton('minimal-primary');
    }
  }
}

.setupPageContainer.integration {
  ul.setupRecordsList {
    li.setupRecordItem.integration {
      grid-template-columns: 2fr 1fr 1fr;
      section.mainDetailsSection {
        .column {
          span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            color: $darkBlue;
            font-weight: 400;
            strong {
              font-weight: 600;
              margin-right: 0.25em;
            }
            .ratingBadge {
              margin-left: 0.5em;
            }
          }
        }
      }
    }
  }
}