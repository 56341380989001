/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.vulnerabilityEvidenceCollapsibleSection {
  .collapsibleSectionHeader {
    transition: all 0.3s ease-in-out;
    &:hover {
      .headerLeft {
        opacity: 1;
      }
      .headerRight {
        .ratingBadge {
          opacity: 1;
        }
      }
    }
    .headerLeft {
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 1em;
      span {
        margin-left: 0.25em;
        font-weight: 400;
      }
      .recordIconWrapper {
        width: 1.75em;
        height: 1.75em;
        border-radius: $borderRadius--full;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background: $grey--background;
        margin: 0;
        padding: 0;
        margin-right: 0.5em;
        flex-shrink: 0;
        .inlineSVG {
          width: 1em;
          height: 1em;
          margin: 0;
          padding: 0;
          opacity: 1;
          path {
            fill: $grey;
          }
        }
        &.critical {
          background: $critical--10;
          .inlineSVG {
            path {
              fill: $critical;
            }
          }
        }
        &.high {
          background: $high--10;
          .inlineSVG {
            path {
              fill: $high;
            }
          }
        }
        &.moderate {
          background: $moderate--10;
          .inlineSVG {
            path {
              fill: $moderate;
            }
          }
        }
        &.low {
          background: $low--10;
          .inlineSVG {
            path {
              fill: $low;
            }
          }
        }
        &.minimal {
          background: $minimal--10;
          .inlineSVG {
            path {
              fill: $minimal;
            }
          }
        }
        &.primary {
          background: $primary--10;
          .inlineSVG {
            path {
              fill: $primary;
            }
          }
        }
        &.unknown {
          background: $grey--background;
          .inlineSVG {
            path {
              fill: $grey;
            }
          }
        }
      }
    }
    .headerRight {
      .ratingBadge {
        margin-right: 1em;
      }
    }
  }
  .collapsibleSectionBody {
    .bodySectionInformationWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 1em;
      .bodySectionItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        label {
          margin-right: 0.25em;
          font-weight: 600;
        }
        strong {
          &.critical {
            color: $critical;
          }
          &.high {
            color: $high;
          }
          &.moderate {
            color: $moderate;
          }
          &.low {
            color: $low;
          }
          &.minimal {
            color: $minimal;
          }
        }
      }
    }
    .sourceLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 0.5em;
      label {
        font-weight: 600;
        margin-right: 0.5em;
      }
      .recordTypeHeaderIcon {
        width: 1.5em;
        height: 1.5em;
        display: block;
        margin-right: 0.25em;
      }
      span {
        margin-right: 0.25em;
      }
    }
    .emptyState {
      font-size: 1.125em;
      background: $grey--background;
      padding: 1em;
      border-radius: $borderRadius;
      margin: 1em 0;
    }
  }
}

.noEvidenceWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.625em 0;
  border-bottom: 1px solid $grey--divider;
  .headerLeft {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 1em;
    span {
      margin-left: 0.25em;
      font-weight: 400;
    }
    .recordIconWrapper {
      width: 1.75em;
      height: 1.75em;
      border-radius: $borderRadius--full;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: $grey--background;
      margin: 0;
      padding: 0;
      margin-right: 0.5em;
      flex-shrink: 0;
      .inlineSVG {
        width: 1em;
        height: 1em;
        margin: 0;
        padding: 0;
        opacity: 1;
        path {
          fill: $grey;
        }
      }
      &.critical {
        background: $critical--10;
        .inlineSVG {
          path {
            fill: $critical;
          }
        }
      }
      &.high {
        background: $high--10;
        .inlineSVG {
          path {
            fill: $high;
          }
        }
      }
      &.moderate {
        background: $moderate--10;
        .inlineSVG {
          path {
            fill: $moderate;
          }
        }
      }
      &.low {
        background: $low--10;
        .inlineSVG {
          path {
            fill: $low;
          }
        }
      }
      &.minimal {
        background: $minimal--10;
        .inlineSVG {
          path {
            fill: $minimal;
          }
        }
      }
      &.primary {
        background: $primary--10;
        .inlineSVG {
          path {
            fill: $primary;
          }
        }
      }
      &.unknown {
        background: $grey--background;
        .inlineSVG {
          path {
            fill: $grey;
          }
        }
      }
    }
  }
  .headerRight {
    .ratingBadge {
      margin-right: 3em;
    }
  }
}