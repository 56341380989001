/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.dashboardSelectorWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.dashboardSelector {
  position: relative;
  strong {
    color: $darkBlue;
  }
  .dropdownTrigger {
    @include dsButton('minimal-primary');
    width: 20em !important;
    border-radius: $borderRadius !important;
    justify-content: flex-end !important;
    padding: 0.375em 1em !important;
    font-size: 1em !important;
    box-shadow: none !important;
    .triggerTextWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 20em;
      span {
        text-align: left;
        padding: 0;
        margin: 0;
      }
      .inlineSVG {
        width: 1em !important;
        height: 1em !important;
        margin-left: 1em !important;
      }
    }
  }
}

.dropdownMenu.dashboardSelectorMenu {
  position: absolute;
  top: 3em;
  background: #fff;
  border: 1px solid $grey--icon;
  border-radius: $borderRadius--input;
  box-shadow: $boxShadow--heavy;
  width: 100%;
  max-height: calc( 100vh - 6.5em );
  overflow: auto;
  ul {
    padding: 0.5em;
  }
  li {
    width: 100%;
    margin-bottom: 0;
    height: 'unset';
    .existingLayout {
      width: 100%;
      padding: 0.5em 1em;
      border-radius: none;
      background: #fff;
      text-decoration: none;
      color: $darkBlue;
      text-align: left;
      padding: 0.5em 1em;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      &.current {
        background-color: $darkBlue;
        color: #fff;
        position: relative;
        padding-left: 2em;
        &:before {
          content: '';
          position: absolute;
          top: calc( 50% - ( 0.75em / 2 ) );
          left: 0.75em;
          width: 0.75em;
          height: 0.75em;
          background: $primaryBlue;
          border-radius: $borderRadius--full;
        }
        &:hover {
          background: $darkBlue;
        }
      }
      &:hover {
        background: $grey--divider;
      }
    }
    label {
      text-transform: uppercase;
      font-weight: 600;
      color: $grey--icon;
      font-size: 0.75em;
      padding: 0em 1.5em;
    }
    .menuCreateNewButton {
      width: 100%;
      margin: 0 !important;
      margin-top: 0.5em !important;
      transition: none !important;
      text-align: center !important;
      justify-content: space-around !important;
      border-radius: $borderRadius !important;
    }
  }
  .menuCreateNewButton {
    @include dsButton( 'minimal-primary' );
    &:disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}
