/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.vulnerability_instances_exploit_status_breakdown {
  .widgetContent {
    display: grid;
    grid-template-columns: 1fr 16em;
    grid-column-gap: 1em;
    .svgDonutWrapper {
      padding-top: 0.5em;
      min-height: 100%;
      max-height: 100%;

    }
  }
}