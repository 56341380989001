/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.recordRiskAndRatingWrapper {
  padding: 1em 2em;
  border-radius: $borderRadius;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  position: relative;
  margin-left: 1.5em;
  .recordStatisticWrapper {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .riskExplanationTriggerButton {
    position: absolute;
    bottom: -2em;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    right: 0;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
    color: $grey;
    &:hover {
      opacity: 1;
      &.riskClass--critical, &.riskClass--Critical {
        color: $critical;
      }
      &.riskClass--high, &.riskClass--High {
        color: $high;
      }
      &.riskClass--moderate, &.riskClass--Moderate {
        color: $moderate;
      }
      &.riskClass--low, &.riskClass--Low {
        color: $low;
      }
      &.riskClass--minimal, &.riskClass--Minimal {
        color: $minimal;
      }
      &.riskClass--unknown, &.riskClass--Unknown {
        color: $grey;
      }
      &.riskClass--primary, &.riskClass--Primary {
        color: $primary;
      }
    }
  }
  &.riskClass--critical, &.riskClass--Critical {
    border: 1px solid $critical;
    background: $critical--10;
  }
  &.riskClass--high, &.riskClass--High {
    border: 1px solid $high;
    background: $high--10;
  }
  &.riskClass--moderate, &.riskClass--Moderate {
    border: 1px solid $moderate;
    background: $moderate--10;
  }
  &.riskClass--low, &.riskClass--Low {
    border: 1px solid $low;
    background: $low--10;
  }
  &.riskClass--minimal, &.riskClass--Minimal {
    border: 1px solid $minimal;
    background: $minimal--10;
  }
  &.riskClass--unknown, &.riskClass--Unknown {
    border: 1px solid $grey;
    background: $grey--background;
  }
  &.riskClass--primary, &.riskClass--Primary {
    border: 1px solid $primary;
    background: $primary--10;
  }
  &.horizontal {
    grid-template-columns: 1fr 1fr;
  }
}