/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.collapsibleSectionWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid $grey--divider;
  .collapsibleSectionHeader, .collapsibleSectionBody {
    width: 100%;
  }
  .collapsibleSectionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    cursor: pointer;
    .headerLeft, .headerRight {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .headerLeft {
      align-items: flex-start;
      position: relative;
      .inlineSVG {
        opacity: 0.3;
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
      }
      .statWarning {
        background: $status--red;
        width: 0.875em;
        height: 0.875em;
        border-radius: $borderRadius--full;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        top: -0.375em;
        left: 0.5em;
        border: 2px solid #fff;
      }
      h3 {
        font-weight: 400;
        font-size: 1em;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0 !important;
      }
      .toggleWrapper {
        margin-top: -0.75em;
        margin-left: 1em;
        font-size: 0.875em;
      }
    }
    .headerRight {
      justify-content: flex-end;
      align-items: center;
      strong {
        font-size: 1.25em;
        margin-right: 0.5em;
        .expandCount {
          font-size: 0.75em;
          color: $grey--icon;
          font-weight: 400;
        }
      }
      .combinedImpactCountWrapper {
        font-size: 0.75em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-weight: 400;
        color: $darkBlue;
        strong {
          font-size: 1.125em;
          margin-left: 0.25em;
          color: $status--red;
        }
      }
      .carretWrapper {
        height: 1.5em;
        width: 1.5em;
        background: $grey--divider;
        display: flex;
        align-items: center;
        justify-content: space-around;
        transition: all 0.3s ease-in-out;
        border-radius: $borderRadius--full;
        .inlineSVG {
          width: 0.75em;
          height: 0.75em;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    &:hover {
      .headerRight {
        .carretWrapper {
          background: $grey--icon;
        }
      }
    }
  }
  .collapsibleSectionBody {
    overflow: auto;
    max-height: 50vh;
    position: relative;
    ul {
      margin-bottom: 1em;
    }
    .emptyState {
      font-size: 1em;
    }
    .exposedAssetsWrapper {
      padding-top: 1em;
      border-top: 1px solid $grey--divider;
      h4 {
        font-size: 1em;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-bottom: 1em;
        color: $darkBlue;
        strong {
          font-weight: 600;
        }
      }
    }
  }
  &.collapsed {
    .collapsibleSectionHeader {
      .headerRight {
        .carretWrapper {
          background: none;
          transform: rotate( 180deg );
        }
      }
      &:hover {
        .headerRight {
          .carretWrapper {
            background: $grey--divider;
          }
        }
      }
    }
    .collapsibleSectionBody {
      height: 0;
      overflow: hidden;
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
}