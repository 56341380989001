/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.hosts_over_time,
.dashboardWidgetWrapper.patches_over_time,
.dashboardWidgetWrapper.vulnerabilities_over_time,
.dashboardWidgetWrapper.vulnerability_instances_over_time,
.dashboardWidgetWrapper.risk_simplified_over_time,
.dashboardWidgetWrapper.sensitive_assets_over_time {
  color: $darkBlue;
  .overtimeMultiAreaWrapper, .overtimeAreaWrapper {
    padding-top: 0.5em;
    display: grid;
    grid-template-columns: 9em 1fr;
    height: 100%;
    position: relative;
    .axisLinesContainer {
      &.yAxis, &.xAxis {
        position: absolute;
        z-index: 1;
        padding-top: 0.5em;
        padding-left: 9.5em;
        padding-right: 3.5em;
      }
    }
    .axisContainer {
      &.yAxis {
        padding-bottom: 0;
        height: 100%;
      }
    }
    // the svg
    .multiAreaPlusBarWrapper, .areaPlusBarWrapper {
      // padding-bottom: 0.5em;
      padding-left: 0.5em;
      border-bottom: 2px solid $grey--icon;
      margin-left: -0.5em;
      height: 100%;
      width: calc( 100% - 3em );
      z-index: 2;
    }
    .chartLegend {
      padding-left: 2em;
    }
    &.withLegend {
      grid-template-columns: 9em 1fr 14em;
      .multiAreaPlusBarWrapper, .areaPlusBarWrapper {
        width: 100%;
      }
      .axisLinesContainer {
        &.yAxis, &.xAxis {
          padding-right: 14.5em;
        }
      }
    }
  }
  .axisContainer {
    &.xAxis {
      width: calc( 100% - 14.5em );
      padding-top: 0.5em;
      padding-left: 9.5em;
      &.fullWidth {
        width: calc( 100% - 3em );
      }
    }
  }
  .emptyState {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .chartHoverIndicatorsWrapper {
    height: 100%;
    width: calc( 100% - 9.875em );
    margin-left: 9.5em;
    z-index: 99999999;
    .areaPointsContainer.areaHeight--100 {
      top: 1em;
      height: calc( 100% - 1.625em );
    }
    .hoverBar.areaHeight--100 {
      height: calc( 100% + 1.5em );
    }
  }
}