/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.emptyState {
  width: 100%;
  text-align: center;
  font-family: $sans;
  color: $grey;
  padding: 2em;
  font-size: 1.5em;
  line-height: 1.75em;
}
