/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.scanningStatusIndicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-radius: $borderRadius--full;
  padding: 0.5em 1.25em 0.5em 0.75em;
  background: $grey--background;
  .labelWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .inlineSVG {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5em;
      path {
        fill: $grey;
      }
    }
    strong {
      color: $darkBlue;
      margin-right: 0.25em;
    }
    span {
      color: $darkBlue;
      font-weight: 400;
    }
  }
  .timeago {
    font-weight: 600;
    color: $grey;
  }
  &.status--red {
    background: $status--red--10;
    .labelWrapper {
      .inlineSVG {
        path {
          fill: $status--red;
        }
      }
    }
    .timeago {
      color: $status--red
    }
  }
  &.status--orange {
    background: $status--orange--10;
    .labelWrapper {
      .inlineSVG {
        path {
          fill: $status--orange;
        }
      }
    }
    .timeago {
      color: $status--orange
    }
  }
  &.status--yellow {
    background: $status--yellow--10;
    .labelWrapper {
      .inlineSVG {
        path {
          fill: $status--yellow;
        }
      }
    }
    .timeago {
      color: $status--yellow
    }
  }
  &.status--green {
    background: $status--green--10;
    .labelWrapper {
      .inlineSVG {
        path {
          fill: $status--green;
        }
      }
    }
    .timeago {
      color: $status--green
    }
  }
  &.status--blue {
    background: $status--blue--10;
    .labelWrapper {
      .inlineSVG {
        path {
          fill: $status--blue;
        }
      }
    }
    .timeago {
      color: $status--blue
    }
  }
}