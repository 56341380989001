/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

ul.setupRecordsList {
  li.setupRecordItem.alternateLayout.scan_credential.item {
    grid-template-columns: 30em calc( 100% - 42em ) 12em !important;
    background: #fff;
    section.mainDetailsSection {
      .column {
        span {
          margin-top: 0.5em;
          color: $grey;
          strong {
            color: $darkBlue;
          }
        }
      }
    }
  }
}