/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@import "/appliance/share/webroot/third-party/google-fonts/Open-Sans.css";
@import "/appliance/share/webroot/third-party/google-fonts/Roboto-Slab.css";

/***************************************************************
* fonts *
***************************************************************/
$serif: "Roboto Slab";
$sans: "Open Sans";
$fixed: "Courier New", monospace;

/***************************************************************
* colors *
***************************************************************/

// risk colors and variants
$critical: #c33937;
$high: #ea5e50;
$moderate: #ff961a;
$low: #ffdf0d;
$minimal: #43d988;
$primary: #1ab3ec;

$critical--75: #cd5958;
$high--75: #ed796d;
$moderate--75: #ffa740;
$low--75: #ffe640;
$minimal--75: #62df9b;
$primary--75: #40bfef;

$critical--50: #de9190;
$high--50: #f3a69e;
$moderate--50: #ffc580;
$low--50: #ffee80;
$minimal--50: #97eabd;
$primary--50: #80d5f4;

$critical--30: #ebbdbd;
$high--30: #f8cac5;
$moderate--30: #ffdcb3;
$low--30: #fff5b3;
$minimal--30: #c1f3d8;
$primary--30: #b3e6f9;

$critical--15: #f5dede;
$high--15: #fce4e2;
$moderate--15: #ffeed9;
$low--15: #fffad9;
$minimal--15: #e0f9eb;
$primary--15: #d9f2fc;

$critical--10: #f9e9e9;
$high--10: #fdeeec;
$moderate--10: #fff4e6;
$low--10: #fffce6;
$minimal--10: #ebfbf2;
$primary--10: #e6f7fd;

$status--blue--75: #60b3e3;
// status--green--75: #60b3e3;
$status--yellow--75: #fbda77;
$status--orange--75: #f3b176;
$status--red--75: #f78c83;

$critical--5: #fdf6f6;
$high--5: #fef8f7;
$moderate--5: #fffbf5;
$low--5: #fffef5;
$minimal--5: #f7fdfa;

// primary colors
$primaryBlue: #00AAE9;
$primaryBlue--light: #5DD3FF;
$primaryBlue--background: $primary--15;
$primaryBlue--minimal: #e5f6fd;
$primaryBlue--dark: #298AC5;
$darkBlue: #334D6E;
$darkBlue--75: #667a92;
$darkBlue--50: #8594a8;

$primaryOrange: #F9A01B;
$purple: #BB6BD9;

// greys
$grey: #90A0B7;
$grey--75: #acb8c9;
$grey--icon: #C2CFE0;
$grey--divider: #EBEFF2;
$grey--background: #F7F7FA;
$grey--background--light: #FCFCFD;
// grey-ish blue for every other table row
$tableBlue: #f9fbfc;

// risk colors
$risk--green: $minimal;
$risk--minimal: $minimal;
$risk--yellow: $low;
$risk--low: $low;
$risk--orange: $moderate;
$risk--moderate: $moderate;
$risk--medium: $moderate;
$risk--red: $high;
$risk--high: $high;
$risk--darkRed: $critical;
$risk--critical: $critical;

// gradient colors (used in marketing related elements (demo mode, etc.) ) to align with website styling
$primaryGradient1: $primaryBlue;
$primaryGradient2: #20729F;
$primaryGradient: linear-gradient(90deg, $primaryGradient1, #20729F);
$primaryGradient--10: linear-gradient(90deg, $primary--10, rgba( #20729F, 0.1 ));
// status colors
$status--blue: #2D9CDB;
$status--green: #6FCF97;
$status--yellow: #F9CD49;
$status--orange: #F2994A;
$status--red: #F7685B;

$status--green--75: #93dbb1;
$status--green--50: #b7e7cb;
$status--green--25: #dbf3e5;
$status--green--10: #f1fbf5;
$status--green--5: #f8fcfa;

$status--red--5: #fff8f7;
$status--red--10: #fff0ef;
$status--red--20: #fde1de;
$status--red--40: #fcc3bd;
$status--red--60: #faa49d;
$status--red--80: #f9867c;

$status--blue--10: #eaf6fc;
$status--yellow--10: #fffaed;
$status--orange--10: #fef5ed;

$status--blue--background: #f4fafd;
$status--green--background: #f8fcfa;
$status--yellow--background: #fefcf6;
$status--red--background: #fef7f7;

$status--darkRed: $risk--darkRed;
$status--darkBlue: $darkBlue;

// special filter colors, used in policy editor for example, to break up the sections,
// needed to be unrelated to any other colors
$filter--teal: #1ABC9C;
$filter--blue: #3498DB;
$filter--purple: #9B59B6;
$filter--red: #E74C3C;

// attack scenario colors, just a nice teal to orange gradient that is different than anything else we have
$attackScenario--lpe: #003333;
$attackScenario--lpe--30: #b3c2c2;
$attackScenario--remote_client: #41796a;
$attackScenario--remote_client--30: #c6d7d3;
$attackScenario--remote_service: #82a67d;
$attackScenario--remote_service--30: #dae5d8;
$attackScenario--mitm: #d4aa57;
$attackScenario--mitm--30: #f3e6cd;
$attackScenario--cred_theft: #ff8c00;
$attackScenario--cred_theft--30: #ffddb3;

// status colors light background variants
$status--blue--light: #FFFFFF;
$status--green--light: #F2FAF5;
$status--yellow--light: #FEF8EE;
$status--red--light: #FDF1EF;

// status variants for buttons
$status--green--hover: #9dd6ae;
$status--green--active: #6ba37b;
$status--red--hover: #eb8e7f;
$status--red--active: #b95d4d;

$pillBlue: #d3e4f9;

// size, spacing, etc.
$borderRadius: 0.375em;
$borderRadius--input: 0.5em;
$borderRadius--full: 9999em;

$boxShadow: 0px 0px 4px 0px rgba(#000000, .16);
$boxShadow--input: 1px 1px 2px 1px rgb(#000000, .05);
$boxShadow--light: 1px 2px 8px 1px rgb(#000000, .05);
$boxShadow--light--reverse: -4px 2px 8px 1px rgb(#000000, .05);
$boxShadow--medium: 0px 4px 16px 0px rgba(#000000, .12);
$boxShadow--heavy: 0px 8px 32px 0px rgba(#000000, .12);
$boxShadow--veryHeavy: 0px 8px 32px 0px rgba(#000000, .48);

$boxShadow--inset: 0px 4px 16px 0px rgba(#000000, .12) inset;

.inlineLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: $borderRadius;
  padding: 0.375em 0.75em;
  background: $primaryBlue--background;
  font-weight: 600;
  text-decoration: none;
  width: 100%;
  color: $primaryBlue;
  transition: all 0.3s ease-in-out;
  &:hover {
    text-decoration: underline;
    background: $primaryBlue--minimal;
  }
  &.newTabLink {
    justify-content: space-between;
    .inlineSVG {
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      margin-left: 0.5em;
      path {
        fill: $primaryBlue;
      }
    }
  }
  &.inline {
    display: inline;
    justify-content: flex-start;
    width: unset;
    padding: 0.375em 0;
    margin: 0.25em;
    background: none;
    .inlineSVG {
      margin-left: 0.25em;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
    }
  }
}

@mixin tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: $borderRadius;
  background: $grey--divider;
  padding: 0.5em 1em;
  border: 1px solid $grey;
  color: $grey;
}

// mixins
@mixin dsButton($type: 'none') {
  line-height: 1.5em;
  display: flex;
  color: #fff;
  text-align: center;
  padding: .375em 1em;
  border-radius: $borderRadius--input;
  margin: 0;
  border: none;
  cursor: pointer;
  // transition: all 0.3s ease-in-out;
  font-weight: 600;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
  .inlineSVG {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.25em;
  }
  span {
    line-height: 1.5em;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  @if $type == primary {
    background: $primaryBlue;
    opacity: 1;
    line-height: 1.5em;
    &:hover {
      background: $primaryBlue--light;
    }
    &:focus {
      background: $primaryBlue--dark;
    }
    &:disabled, &.disabled {
      background: $primaryBlue;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == secondary {
    background: $grey;
    line-height: 1.5em;
    color: #fff;
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: $grey--icon;
    }
    &:disabled, &.disabled {
      background: $grey;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == dark {
    background: $darkBlue;
    color: #fff;
    line-height: 1.5em;
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: $grey--icon;
    }
    &:disabled, &.disabled {
      background: $darkBlue;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal {
    background: none;
    border: none;
    color: $darkBlue;
    padding: 0.375em 1em;
    opacity: 0.7;
    line-height: 1.5em;
    &:hover {
      opacity: 1;
      background: $grey--divider;
      box-shadow: none;
      color: $darkBlue;
    }
    &:focus {
      opacity: 1;
      background: $grey--divider;
      box-shadow: none;
      color: $darkBlue;
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.2;
      cursor: default;
      pointer-events: none;
      color: $grey;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal-primary {
    color: $primaryBlue;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $primary--15;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    line-height: 1.5em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: $primary--30;
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $primary--15;
      cursor: default;
      pointer-events: none;
      color: $primary;
      opacity: 0.4;
      &:hover {
        background: $primary--15;
        box-shadow: none;
      }
    }
  } @else if $type == minimal-red {
    line-height: 1.5em;
    color: $risk--red;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $high--15;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: $high--30;
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $high--15;
      cursor: default;
      pointer-events: none;
      color: $high;
      opacity: 0.4;
      &:hover {
        background: $high--15;
        box-shadow: none;
      }
    }
  } @else if $type == minimal-darkRed {
    line-height: 1.5em;
    color: $critical;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $critical--15;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: $critical--30;
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $critical--15;
      cursor: default;
      pointer-events: none;
      color: $critical;
      opacity: 0.4;
      &:hover {
        background: $critical--15;
        box-shadow: none;
      }
    }
  } @else if $type == minimal-orange {
    line-height: 1.5em;
    color: $moderate;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $moderate--15;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: $moderate--30;
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $moderate--15;
      cursor: default;
      pointer-events: none;
      color: $moderate;
      opacity: 0.4;
      &:hover {
        background: $moderate--15;
        box-shadow: none;
      }
    }
  } @else if $type == minimal-yellow {
    line-height: 1.5em;
    color: $risk--low;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $low--15;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: $low--30;
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $low--15;
      cursor: default;
      pointer-events: none;
      color: $low;
      opacity: 0.4;
      &:hover {
        background: $low--15;
        box-shadow: none;
      }
    }
  } @else if $type == minimal-green {
    line-height: 1.5em;
    color: $risk--minimal;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $minimal--15;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: $minimal--30;
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $minimal--15;
      cursor: default;
      pointer-events: none;
      color: $minimal;
      opacity: 0.4;
      &:hover {
        background: $minimal--15;
        box-shadow: none;
      }
    }
  } @else if $type == minimal-default {
    line-height: 1.5em;
    color: $grey;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $grey--divider;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: $grey;
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgba($grey, 0.3);
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $grey--divider;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &.next {
        margin-left: 1em !important;
      }
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal-dark {
    color: $darkBlue;
    line-height: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgba( $darkBlue, 0.1 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgba( $darkBlue, 0.25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $grey--divider;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == outline-dark {
    background: none;
    border: 1px solid $darkBlue;
    color: $darkBlue;
    &:hover {
      color: $darkBlue;
      opacity: 0.7;
    }
    &:focus {
      color: $darkBlue;
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == outline-add {
    background: none;
    border: 1px solid $status--green;
    color: $status--green;
    .inlineSVG {
      path {
        fill: $status--green;
      }
    }
    &:hover {
      color: $status--green;
      opacity: 0.7;
    }
    &:focus {
      color: $status--green;
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == outline-remove {
    background: none;
    border: 1px solid $status--red;
    color: $status--red;
    .inlineSVG {
      path {
        fill: $status--red;
      }
    }
    &:hover {
      color: $status--red;
      opacity: 0.7;
    }
    &:focus {
      color: $status--red;
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == destructive {
    background: $grey;
    color: #fff;
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: $grey--icon;
    }
    &:disabled, &.disabled {
      background: $grey;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == status-green {
    background: $status--green;
    &:hover {
      background: $status--green--hover;
    }
    &:focus {
      background: $status--green--active;
    }
    &:disabled, &.disabled {
      background: $status--green;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == status-red {
    background: $status--red;
    &:hover {
      background: $status--red--hover;
    }
    &:focus {
      background: $status--red--active;
    }
    &:disabled, &.disabled {
      background: $status--red;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == risk-critical {
    background: $risk--darkRed;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .inlineSVG {
      path {
        fill: #fff;
      }
    }
    &:hover {
      opacity: 0.75;
    }
  } @else if $type == risk-high {
    background: $risk--red;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .inlineSVG {
      path {
        fill: #fff;
      }
    }
    &:hover {
      opacity: 0.75;
    }
  } @else if $type == risk-moderate {
    background: $risk--orange;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .inlineSVG {
      path {
        fill: #fff;
      }
    }
    &:hover {
      opacity: 0.75;
    }
  } @else if $type == risk-minimal {
    background: $risk--green;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .inlineSVG {
      path {
        fill: #fff;
      }
    }
    &:hover {
      opacity: 0.75;
    }
  } @else if $type == risk-low {
    background: $risk--yellow;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .inlineSVG {
      path {
        fill: #fff;
      }
    }
    &:hover {
      opacity: 0.75;
    }
  } @else if $type == none {
    background: #fff;
    color: $darkBlue;
    &:hover {
      box-shadow: 0px 0px 0px 3px rgba($primaryBlue, 0.4);
    }
  } @else if $type == disabled {
    background: $primaryBlue;
    opacity: 0.15;
    cursor: default;
    pointer-events: none;
    color: #fff;
    &:hover {
      box-shadow: none;
    }
  }

  // &:disabled, &.disabled {
  //   background: $grey--icon;
  //   opacity: 0.4;
  //   cursor: default;
  //   pointer-events: none;
  //   color: #fff;
  //   &:hover {
  //     box-shadow: none;
  //   }
  // }
}

@mixin pulseOpacity {
  animation: pulseOpacity 5s infinite;
}

@mixin pulseShadow {
  z-index: 1000;
  background: rgba($primaryBlue--dark, 0.1);
  padding: 1em;
  border-radius: $borderRadius;
  animation: pulse 2.5s infinite;
}

// global animations
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($primaryBlue--dark, 0.6);
  }
  70% {
      -webkit-box-shadow: 0 0 0 20px rgba($primaryBlue--dark, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba($primaryBlue--dark, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($primaryBlue--dark, 0.6);
    box-shadow: 0 0 0 0 rgba($primaryBlue--dark, 0.6);
  }
  70% {
      -moz-box-shadow: 0 0 0 20px rgba($primaryBlue--dark, 0);
      box-shadow: 0 0 0 20px rgba($primaryBlue--dark, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba($primaryBlue--dark, 0);
      box-shadow: 0 0 0 0 rgba($primaryBlue--dark, 0);
  }
}

@keyframes pulseOpacity {
  0% {
    opacity: 0.5;
  }
  70% {
      opacity: 1
  }
  100% {
      opacity: 0.5;
  }
}


.toggleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: $borderRadius--input;
  background: $grey--divider;
  padding: 0.25em;
  // border: 1px solid $grey--icon;
  button {
    background: none;
    color: $darkBlue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5em 1.5em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    border: none;
    color: $darkBlue;
    margin: 0em;
    border-radius: $borderRadius;
    margin-right: 0.25em;
    .inlineSVG {
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      transition: all 0.3s ease-in-out;
      path {
        transition: all 0.3s ease-in-out;
        fill: $grey--icon;
      }
    }
    &:hover {
      background: #fff;
      color: $darkBlue;
      .inlineSVG {
        path {
          fill: $primaryBlue;
        }
      }
    }
    &.toggled {
      background: $darkBlue;
      color: #fff;
      box-shadow: $boxShadow--input;
      z-index: 1;
      .inlineSVG {
        path {
          fill: $primaryBlue;
        }
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  // &.optionCount--3 {
  //   button {
  //     &:nth-of-type(2) {
  //       position: relative;
  //       &:before, &:after {
  //         content: '';
  //         height: calc( 100% - 1em );
  //         width: 1px;
  //         background: $grey--icon;
  //         position: absolute;
  //         top: 0.5em;
  //         left: 0;
  //       }
  //       &:after {
  //         left: unset;
  //         right: 0;
  //       }
  //       &.toggled {
  //         &:before, &:after {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  // }
 }
