/***************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.scanLogOutputModal {
  padding: 0em !important;
  max-height: 90vh;
  top: 5vh;
  width: 90vw;
  left: 5vw;
  overflow: hidden;
  .modalBody {
    padding: 1em 2em 4em 2em !important;
    max-height: unset;
  }
  .closeButton {
    z-index: 4 !important;
  }
}

#pageHeaderContainer.scanLogsHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  .filterForm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    .filterWrapper {
      width: unset;
      margin: 0;
      padding: 0;
      border-bottom: none;
      flex-grow: 0;
      margin-right: 1em;
      label {
        width: unset;
        flex-direction: column;
        select, input {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
      }
    }
  }
  .paginationContainer.indeterminantPagination {
    border-left: 1px solid $grey--divider;
    border-top: none;
    padding-right: 0;
  }
}
