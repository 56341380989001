/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

#chartHoverCardPortal {
  z-index: 9999999;
  .hoverCard {
    color: $darkBlue;
    font-family: $sans;
    background: #fff;
    min-width: 15em;
    position: fixed;
    border-radius: $borderRadius--input;
    border: 1px solid $grey--divider;
    box-shadow: $boxShadow;
    padding: 0.5em 1em;
    transition: all 150ms ease-in-out;
    z-index: 9999999;
    h3 {
      width: 100%;
      text-align: left;
      font-weight: 600;
      font-size: 1em;
      margin-bottom: 1em;
      padding: 0;
      display: flex;
      text-align: center;
      justify-content: flex-start;
      align-items: center;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.5em;
        path {
          fill: $grey--icon;

        }
      }
    }
    ul {
      li {
        font-size: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1.25em;
        .color {
          width: 1em;
          height: 1em;
          border-radius: $borderRadius--full;
          margin-right: 0.5em;
          flex-shrink: 0;
        }
      }
    }
  }
}

.chartHoverIndicatorsWrapper {
  width: 100%;
  padding-right: 3.5em;
  height: 100%;
  position: absolute;

  .hoverPoint {
    position: absolute;
    border-radius: $borderRadius--full;
    z-index: 6;
    background: #fff;
    transition: all 150ms ease-in-out;
  }
  .hoverBar {
    position: absolute;
    z-index: 5;
    transition: all 150ms ease-in-out;
    &.areaHeight--50 {
      top: -0.5em;
      height: calc( 200% + 3.5em );
    }
    // area plus a single bar
    &.areaHeight--66 {
      top: -0.75em;
      height: calc( 150% + 2.5em );
    }
    // area all by itself
    &.areaHeight--100 {
      top: -1.25em;
      height: calc( 100% + 2.5em );
    }
  }
  .hoverBar {
    height: calc( 100% + 6em );
    top: -1em;
  }
  .fullHoverContainer {
    position: absolute;
    z-index: 7;
    opacity: 1;
    height: 100%;
    &:hover {
      .hoverBar {
        opacity: 0.5;
      }
      .hoverPoint {
        opacity: 1;
      }
      .hoverCard {
        opacity: 1;
      }
    }
  }
  .areaPointsContainer {
    width: calc( 100% - 3.5em );
    position: absolute;
    top: 0.75em;
    left: 0;
    // background: rgba( blue, 0.3 );
    &.withLegend {
      width: calc( 100% - 14.75em );
    }
    // area plus both center bars
    &.areaHeight--50 {
      top: 0.125em;
      height: calc( 50% - 1.375em );
    }
    // area plus a single bar
    &.areaHeight--66 {
      top: 0.25em;
      height: calc( 66.6667% - 1.25em );
    }
    // area all by itself
    &.areaHeight--100 {
      top: 0.75em;
      height: calc( 100% - 1.5em );
    }
  }
  &.withXAxis {
    height: calc( 100% - 3.75em );
  }
  &.withLegend {
    padding-right: 14.5em;
  }
}