/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../../styles/globals.scss" as *;

.modalContainer.remediation_plan {
  .planFormWrapper {
    padding: 0 1.5em;
    .formWrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 1em;
      margin-bottom: 1.5em;
      padding-bottom: 0em;
      .fieldGroupWrapper {
        border-top: none !important;
        .fieldWrapper {
          textarea {
            min-height: 9.25em;
            resize: none;
          }
          &:last-of-type {
            margin-bottom: 0em;
          }
        }
      }
    }
  }
}