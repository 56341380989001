/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.itemPercentageBarWrapper {
  width: 100%;
  height: 0.875em;
  background: $grey--background;
  border-radius: $borderRadius--full;
  position: relative;
  .itemPercentageBar {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: $borderRadius--full;
    height: 0.875em;
    min-width: 0.875em;
  }
}