/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.widgetHeadingContentWrapper {
  font-size: 2em;
  margin: 0;
  padding: 0;
  // border-top: 1px solid $grey--divider;
  padding-top: 0.5em;
  margin-bottom: 0.5em;
}

.textWidgetWrapper {
  justify-content: space-around;
  color: $darkBlue;
  line-height: 1.5em;
  font-size: 1.25em;
}