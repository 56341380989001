/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

#vulnerabilityRecordCardPortal {
  z-index: 9999998;
  .recordCardContainer {
    z-index: 9999999;
    display: none;
    &.visible {
      display: block;
    }
  }
}

.recordCardContainerShade {
  width: 100vw;
  height: 100vh;
  z-index: 9999998;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba( $darkBlue, 0.1 );
}

.recordCardContainer {
  position: fixed;
  z-index: 9999999;
  background: #fff;
  border-radius: $borderRadius;
  box-shadow: $boxShadow--medium;
  padding: 1em;
  font-family: $sans;
  color: $darkBlue;
  max-height: calc( 100vh - 3em );
  .contentGridWrapper {
    display: grid;
    grid-template-columns: 1fr;
    max-height: calc( 100vh - 5em );
    &.twoColumn {
      grid-template-columns: 1fr 1fr;
    }
  }
  .collapsibleSectionHeader {
    .headerLeft {
      flex-grow: 1;
      .recordCardHeader {
        flex-grow: 1;
        border: none;
        padding-bottom: 0;
        padding-right: 1em;
        margin-bottom: 0;
        .recordCardHeaderRow {
          &:last-of-type {
            margin-bottom: 0;;
          }
        }
        &.signatureHeader {
          .recordCardHeaderRow {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.cardContext_explore {
    top: 1em;
    right: 1em;
    width: 30em;
    max-height: calc( 100vh - 7em );
    .recordCardContent {
      .edgeContentvulnerabilitiesSectionWrapper {
        .collapsibleSectionWrapper {
          .collapsibleSectionBody {
            max-height: 40vh;
          }
        }
      }
    }
  }
  .recordCardHeader {
    padding-bottom: 0.25em;
    margin-bottom: 1em;
    &.isDraggable {
      cursor: move;
      &:hover {
        .draggableIcon {
          path {
            fill: $darkBlue;
          }
        }
      }
    }
    .recordCardHeaderRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .recordIconWrapper {
        width: 1.75em;
        height: 1.75em;
        border-radius: $borderRadius--full;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background: $grey--background;
        margin: 0;
        padding: 0;
        margin-right: 0.5em;
        .inlineSVG {
          width: 1em;
          height: 1em;
          margin: 0;
          padding: 0;
          path {
            fill: $grey;
          }
        }
        .recordTypeHeaderIcon {
          width: 1em;
          height: 1em;
          margin: 0;
          padding: 0;
        }
        &.critical, &.Critical {
          background: $critical--10;
          .inlineSVG {
            path {
              fill: $critical;
            }
          }
        }
        &.high, &.High, &.Important, &.important {
          background: $high--10;
          .inlineSVG {
            path {
              fill: $high;
            }
          }
        }
        &.moderate, &.Moderate, &.medium, &.Medium {
          background: $moderate--10;
          .inlineSVG {
            path {
              fill: $moderate;
            }
          }
        }
        &.low, &.Low {
          background: $low--10;
          .inlineSVG {
            path {
              fill: $low;
            }
          }
        }
        &.minimal, &.Minimal {
          background: $minimal--10;
          .inlineSVG {
            path {
              fill: $minimal;
            }
          }
        }
        &.primary, &.negligible {
          background: $primary--10;
          .inlineSVG {
            path {
              fill: $primary;
            }
          }
        }
        &.unknown {
          background: $grey--background;
          .inlineSVG {
            path {
              fill: $grey;
            }
          }
        }
        &.red {
          background: $status--red--10;
        }
      }
      h2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .inlineSVG {
          width: 1em;
          height: 1em;
          margin-right: 0.5em;
          path {
            fill: $grey--icon;
          }
        }
        .recordTypeHeaderIcon {
          width: 1.125em;
          height: 1.125em;
          margin-right: 0.5em;
          .svgNodeIconOutline {
            fill: $grey--icon;
          }
        }
        .statWarning {
          position: absolute;
          background-color: $status--red;
          border-radius: $borderRadius--full;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          width: 0.875em;
          height: 0.875em;
          top: -0.25em;
          left: 0.625em;
          .inlineSVG {
            width: 0.75em;
            height: 0.75em;
            flex-shrink: 0;
            margin: 0;
            path {
              fill: #FFF;
            }
          }
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .recordCardHeaderRow__dividerBar {
      width: 100%;
      height: 0.25em;
      border-radius: 0.125em;
      background: $grey;
      margin: 0.5em 0;
      &.critical, &.Critical {
        background: $critical;
      }
      &.high, &.High, &.Important, &.important {
        background: $high;
      }
      &.moderate, &.Moderate, &.medium, &.Medium {
        background: $moderate;
      }
      &.low, &.Low {
        background: $low;
      }
      &.minimal, &.Minimal {
        background: $minimal;
      }
      &.primary, &.negligible {
        background: $primary;
      }
      &.unknown {
        background: $grey;
      }
      &.red {
        background: $status--red;
      }
    }
    &.edge {
      .recordCardHeaderRow {
        h2 {
          font-weight: 400;
          strong {
            font-weight: 600;
            margin-right: 0.25em;
          }
          span {
            margin-right: 0.25em;
          }
        }
      }
    }
  }
  .recordCardOptions {
    .recordCardOptionsHeader {
      margin: 1em 0 0 0;
      padding: 0.75em 1em;
      color: $darkBlue;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-weight: 600;
      border: 1px solid $grey--icon;
      box-shadow: $boxShadow;
      border-radius: $borderRadius $borderRadius 0 0;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.5em;
        path {
          fill: $grey--icon;
        }
      }
      h3 {
        columns: #fff;
        font-size: 1em;
        padding: 0;
        margin: 0;
      }
    }
    ul {
      border-radius: 0 0 $borderRadius $borderRadius;
      background: $grey--background;
      border: 1px solid $grey--icon;
      border-top: none;
      padding: 0.5em;
      box-shadow: $boxShadow;
      color: $darkBlue;
      li {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border-radius: $borderRadius;
        padding: 0.5em 1em;
        margin-bottom: 0.25em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          margin-right: 0.5em;
          path {
            fill: $grey--icon;
          }
        }
        &:hover {
          background: rgba( $grey, 0.25 );
        }
        &.delete {
          color: $risk--red;
          .inlineSVG {
            path {
              fill: $high--10;
            }
          }
          &:hover {
            background: $high--10;
          }
        }
      }
    }
  }
  &.collapsed {
    top: 6.5em !important;
    right: 1em !important;
    left: unset !important;
    bottom: unset !important;
    border: 1px solid rgba( $grey--icon, 0.5 );
    transform: none !important;
    width: 480px !important;
    z-index: 999;
    padding: 0.5em 0.5em 0.5em 0.75em;
    .recordCardHeaderRow__dividerBar,
    .goToRecordButton,
    .recordCardCloseButton,
    .draggableIcon,
    .left {
      display: none;
    }
    .recordCardHeader {
      border-bottom: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .recordCardHeaderRow {
        order: 1;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        padding-right: 0.5em;
        h2 {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          font-size: 1em;
          span.label {
            width: 19em;
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 0.5em;
          }

        }
        &:nth-of-type(3) {
          padding: 0;
          margin: 0;
          justify-content: flex-end;
          flex-grow: 0;
        }
        &:nth-of-type(4) {
          display: none;
        }
      }
      &.isDraggable {
        cursor: default;
      }
      .iconDescription {
        display: none;
      }
    }
    .contentGridWrapper.twoColumn {
      grid-template-columns: 1fr;
      grid-gap: unset;
    }
    .recordCardContent, .recordCardOptions, .additionalContent {
      display: none;
    }
  }
  .additionalContentContainer {
    padding: 1em 1em 1em;
    margin: -1.5em -1.5em -1.5em 1em;
    background: #fff;
    border-radius: $borderRadius;
    box-shadow: $boxShadow--medium;
    max-height: calc( 100vh - 2em );
    overflow: auto;
    .additionalContentHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 1em;
        color: $darkBlue;
      }
    }
    .collapsibleSectionWrapper {
      .collapsibleSectionHeader {
        position: relative;
        .headerLeft {
          .recordCardHeader {
            padding-right: 0em;
            .recordCardHeaderRow {
              h2 {
                .recordIconWrapper {
                  .inlineSVG {
                    opacity: 1;
                  }
                }
              }
              .right {
                .ratingBadge  {
                  margin-right: 3em;
                }
                .roundGlyphButton {
                  .inlineSVG {
                    width: 1.25em;
                    height: 1.25em;
                    opacity: 1;
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
        .headerRight {
          .carretWrapper {
            position: absolute;
            right: 0em;
            top: 1.375em;
          }
        }
      }
    }
  }
  .fullDetailsLink {
    @include dsButton( 'minimal-primary');
    text-align: center;
    justify-content: space-around;
  }
}