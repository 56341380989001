/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.dataTable {
  font-family: $sans;
  color: $darkBlue;
  display: grid;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0.5em;
  transition: all 0.2s ease-in-out;
  .tableRow {
    width: 100%;
    display: grid;
    background: #fff;
    transition: all 0.2s ease-in-out;
    border-top: 1px solid #fff;
    border-bottom: 1px solid $grey--divider;
    border-left: 1px solid $grey--divider;
    border-right: 1px solid $grey--divider;
    line-height: 1.5em;
    grid-template-columns:
      minmax(14em, 1.5fr)
      minmax(14em, 2fr)
      minmax(14em, 1fr)
      minmax(8em, 1fr)
      minmax(6em, 1fr)
      minmax(6em, 1fr);
    &:nth-child(even) {
      background: $tableBlue;
      border-top: 1px solid #fff $tableBlue;
      .tableCell {
        .expandingCellWrapper.needsExpanding {
          .expandingCellIndicator {
            background: $tableBlue;
          }
        }
      }
    }
    &.tableHeader {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 3;
      width: 100%;
      margin: 0;
      background: #fff;
      line-height: 1em;
      border-right: none;
    }
    &.isSelected {
      background: $primary--10;
      .tableCell {
        &:first-of-type {
          position: relative;
          .tableSelectButton {
            opacity: 1;
            .inlineSVG {
              opacity: 1;
              path {
                fill: $primaryBlue;
              }
            }
          }
        }
      }
    }
    &.hasRowAction {
      cursor: pointer;
    }
    &:hover {
      background: rgba( $primary--10, 0.5 );
      .tableCell {
        &.tableCell_actions {
          .roundGlyphButton {
            background: rgba( $darkBlue, 0.25 );
            .inlineSVG {
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
    .tableCell {
      padding: 0.375em 1em;
      transition: all 0.2s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      &.headerCell {
        background: #fff;
        white-space: nowrap;
        color: $darkBlue;
        font-weight: 500;
        padding: 1em;
        font-family: $sans;
        border-right: 1px solid $grey--divider;
        text-align: left;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        font-size: .75em;
        transition: all 0.3s ease-in-out;
        span {
          width: 100%;
          &.helpTrigger {
            margin-top: -0.5em;
            margin-left: 0.5em;
            .inlineSVG {
              position: unset !important;
              display: block !important;
            }
          }
        }
        &.isSortable {
          padding-right: 2.5em;
          cursor: pointer;
          .inlineSVG {
            display: none;
            position: absolute;
            height: 1.5em;
            right: 0.25em;
            top: 0.75em;
            transition: all 0.3s ease-in-out;
          }
          .inlineSVG.both {
            display: block;
            opacity: 0.2;
          }
          &:hover {
            background: rgba( $darkBlue, 0.04 );
            .inlineSVG.both {
              opacity: 1;
              path {
                fill: $darkBlue;
              }
            }
          }
        }
        &.sortingBy {
          color: $darkBlue;
          font-weight: 600;
          background: $tableBlue;
          &:hover {
            background: #fff;
          }
          &.ASC {
            .inlineSVG {
              display: none;
              &.ascending {
                display: block;
                opacity: 1;
                path.sortingByDirection {
                  fill: $darkBlue;
                }
              }
            }
          }
          &.DESC {
            .inlineSVG {
              display: none;
              &.descending {
                display: block;
                opacity: 1;
                path.sortingByDirection {
                  fill: $darkBlue;
                }
              }
            }
          }
        }
        &.selectCell {
          border-right: 1px solid #fff;
          padding: 0 0 0 0.5em;
        }
        &.headerCell_actions {
          border-right: 1px solid #fff;
        }
      }
      ul {
        padding: 0;
        padding-right: 1em;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          margin-bottom: 0;
          margin-right: 0.125em;
        }
      }
      .dataTableInputWrapper {
        width: 100%;
        transition: all 0.3s ease-in-out;
        position: relative;
        input {
          width: 100%;
          padding: 0.25em 0.5em;
          background: $grey--background--light;
          border: 1px solid $grey--icon;
          border-radius: $borderRadius;
          color: $darkBlue;
          transition: all 0.3s ease-in-out;
          &:focus {
            outline: none;
            border: 1px solid $grey;
          }
        }
        &.error {
          padding-left: 1.25em;
          &:before {
            content: '';
            position: absolute;
            height: 0.75em;
            width: 0.75em;
            border-radius: $borderRadius--full;
            background: $status--red;
            top: 50%;
            margin-top: -0.375em;
            left: 0em;
          }
        }
        &.updated {
          padding-left: 1.25em;
          &:before {
            content: '';
            position: absolute;
            height: 0.75em;
            width: 0.75em;
            border-radius: $borderRadius--full;
            background: $status--yellow;
            top: 50%;
            margin-top: -0.375em;
            left: 0em;
          }
          &.error {
            padding-left: 2.5em;
            &:after {
              content: '';
              position: absolute;
              height: 0.75em;
              width: 0.75em;
              border-radius: $borderRadius--full;
              background: $status--red;
              top: 50%;
              margin-top: -0.375em;
              left: 0em;
            }
            &:before {
              left: 1.25em;
            }
          }
        }
      }
      .tableReportLink {
        transition: all 0.3s ease-in-out;
        color: $primaryBlue;
        text-decoration: none;
        font-weight: 600;
        .inlineSVG {
          width: 1em;
          height: 1em;
          margin-left: 0.5em;
          path {
            transition: all 0.3s ease-in-out;
            fill: $primaryBlue;
            opacity: 0.3;
          }
        }
        &:hover {
          text-decoration: underline;
          .inlineSVG {
            path {
              opacity: 1;
            }
          }
        }
      }
      &.sortingBy {
        background: rgba( $darkBlue, 0.04 );
        border-top: rgba( $darkBlue, 0.04 );
        margin-top: -1px;
      }
      &.selectCell {
        width: 4em;
        padding: 0.5em;
        font-size: 1em;
        .tableSelectButton {
          background: none;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          opacity: 0.6;
          border-radius: $borderRadius--full;
          width: 2em;
          height: 2em;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .inlineSVG {
            width: 1.25em;
            height: 1.25em;
            flex-shrink: 0;
          }
          &:hover {
            opacity: 1;
            background: $grey--divider;
          }
        }
        .quickViewToggleButton {
          background: none;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          border-radius: $borderRadius--full;
          width: 2em;
          height: 2em;
          display: flex;
          justify-content: space-around;
          align-items: center;
          opacity: 0.4;
          flex-shrink: 0;
          .inlineSVG {
            width: 1.125em;
            height: 1.125em;
            flex-shrink: 0;
            path {
              fill: $darkBlue;
            }
          }
          &:hover {
            opacity: 1;
            background: $grey--divider;
            .inlineSVG {
              path {
                fill: $primaryBlue;
              }
            }
          }
        }
      }
      &.tableCell_name, &.tableCell_host_name {
        .nameLink {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            text-decoration: underline;
            color: $primaryBlue;
          }
          .iconWrapper {
            background: $grey--icon;
            width: 2em;
            height: 2em;
            border-radius: $borderRadius--full;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            margin-right: 0.5em;
            .inlineSVG {
              width: 1.25em;
              height: 1.25em;
            }
            &.notScanned {
              background: $status--red--10;
              .inlineSVG {
                path {
                  fill: $status--red;
                }
              }
            }
          }
        }
        .notLink {
          color: $grey;
          font-weight: 400;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          cursor: default;
          .iconWrapper {
            background: $grey--icon;
            width: 2em;
            height: 2em;
            border-radius: $borderRadius--full;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            margin-right: 0.5em;
            .inlineSVG {
              width: 1.25em;
              height: 1.25em;
            }
            &.notScanned {
              background: $status--red--10;
              .inlineSVG {
                path {
                  fill: $status--red;
                }
              }
            }
          }
        }
      }
      &.tableCell_actions {
        padding: 0.5em 1em 0.5em 0em;
        .roundGlyphButton {
          &:hover {
            background: $darkBlue;
          }
        }
      }
    }
  }
  &.minimal {
    .tableRow {
      background: none;
      &:nth-child( odd ) {
        background: none;
      }
      &:nth-child( even ) {
        background: none;
      }
      &.tableHeader {
        border: none !important;
        .tableCell {
          &.headerCell {
            border: none;
            font-size: unset;
            span {
              text-align: center;
              font-weight: 600;
            }
          }
        }
      }
      &.isSelected {
        background: $grey--background--light;
        opacity: 1;
      }
    }
    &.withSelect {
      .tableRow:not(.tableHeader) {
        opacity: 0.6;
        &.isSelected {
          background: $grey--background--light;
          opacity: 1;
        }
      }
    }
  }
}