/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.hosts_top_details,
.dashboardWidgetWrapper.patches_top_details,
.dashboardWidgetWrapper.vulnerabilities_top_details {
  padding: 0;
  .widgetHeaderContainer {
    margin-top: 1em;
    margin-left: 1em;
    margin-bottom: 0;
  }
  .topRecordDetailsWrapper {
    display: grid;
    grid-gap: 1em;
    padding: 1em;
    font-size: 1.125em;
    .recordCardContainer {
      position: relative;
      border: 1px solid $grey--divider;
      box-shadow: $boxShadow--light;
      z-index: unset;
      max-height: unset;
      .contentGridWrapper {
        max-height: unset;
        .mainContent {
          .vulnerabilityDescription, .patchDescription {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
            // max-height: 4.75em;
          }
          .analyistNotes {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
            // max-height: 4.75em;
          }
        }
      }
    }
    &.vertical {
      grid-template-rows: 1fr 1fr 1fr;
      &.itemCount--5 {
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      }
    }
    &.horizontal {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      &.itemCount--5 {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }
}