/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.riskScoreWidgetWrapper {
  background: $primary--15;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 3em;
  height: 100%;
  .outerRiskCircle {
    border: 2em solid rgba( $primaryBlue, 0.3);
    border-radius: $borderRadius--full;
    width: 16em;
    height: 16em;
    .innerRiskCircle {
      border: 2px solid $primaryBlue;
      background: #fff;
      border-radius: $borderRadius--full;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .scoreAndLabelWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        color: $primaryBlue;
        strong {
          font-size: 2em;
          font-weight: 600;
        }
        span {
          color: $darkBlue;
          font-size: 1.25em;
          margin-top: 0.5em;
          font-weight: 400;
        }
      }
    }
  }
  &.risk--critical {
    background: $critical--10;
    .outerRiskCircle {
      border-color: rgba( $risk--darkRed, 0.3);
      .innerRiskCircle {
        border-color: $risk--darkRed;
        .scoreAndLabelWrapper {
          color: $risk--darkRed;
        }
      }
    }
  }
  &.risk--high {
    background: $high--10;
    .outerRiskCircle {
      border-color: rgba( $risk--red, 0.3);
      .innerRiskCircle {
        border-color: $risk--red;
        .scoreAndLabelWrapper {
          color: $risk--red;
        }
      }
    }
  }
  &.risk--moderate {
    background: $moderate--10;
    .outerRiskCircle {
      border-color: rgba( $risk--orange, 0.3);
      .innerRiskCircle {
        border-color: $risk--orange;
        .scoreAndLabelWrapper {
          color: $risk--orange;
        }
      }
    }
  }
  &.risk--low {
    background: $low--10;
    .outerRiskCircle {
      border-color: rgba( $risk--yellow, 0.3);
      .innerRiskCircle {
        border-color: $risk--yellow;
        .scoreAndLabelWrapper {
          color: $risk--yellow;
        }
      }
    }
  }
  &.risk--minimal {
    background: $minimal--10;
    .outerRiskCircle {
      border-color: rgba( $risk--green, 0.3);
      .innerRiskCircle {
        border-color: $risk--green;
        .scoreAndLabelWrapper {
          color: $risk--green;
        }
      }
    }
  }
  &.risk--default {
    background: rgba( $darkBlue, 0.1 );
    .outerRiskCircle {
      border-color: rgba( $darkBlue, 0.3);
      .innerRiskCircle {
        border-color: $darkBlue;
        .scoreAndLabelWrapper {
          color: $darkBlue;
        }
      }
    }
  }
}

.dashboardWidgetWrapper.risk_score,
.dashboardWidgetWrapper.risk_target {
  padding: 0;
  overflow: hidden;
  border: none;
  &.widgetHeight-4 {
    .widgetContent {
      height: 17em;
      .riskScoreWidgetWrapper {
        .outerRiskCircle {
          width: 12em;
          height: 12em;
        }
        .innerRiskCircle {
          .scoreAndLabelWrapper {
            strong {
              font-size: 1.5em;
            }
            span {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
  &.widgetHeight-5 {
    .widgetContent {
      height: 21em;
    }
  }
  &.widgetHeight-6 {
    .widgetContent {
      height: 25em;
    }
  }
}
