/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.riskPercentageBarWrapper {
  width: 100%;
  height: 1.25em;
  background: $grey--background;
  border-radius: $borderRadius--full;
  position: relative;
  .riskPercentageBar {
    position: absolute;
    left: 0;
    top: 0;
    background: $risk--darkRed;
    border-radius: $borderRadius--full;
    height: 1.25em;
    min-width: 1.25em;
    &.high {
      background: $risk--red;
    }
    &.moderate {
      background: $risk--orange;
    }
    &.low {
      background: $risk--yellow;
    }
    &.minimal {
      background: $risk--green;
    }
    &.unknown {
      background: $grey--icon;
    }
  }
  &.minimal {
    height: 0.875em;
    .riskPercentageBar {
      height: 0.875em;
      min-width: 0.875em;
      // left: unset;
      // right: 0;
    }
  }
}