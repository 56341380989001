/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.hosts_global,
.dashboardWidgetWrapper.patches_global,
.dashboardWidgetWrapper.vulnerabilities_global,
.dashboardWidgetWrapper.risk_global {
  .widgetContent {
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: 1fr 10em;
    .svgDonutWrapper {
      padding-top: 0.5em;
      min-height: 100%;
      max-height: 100%;
    }
  }
}