/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.pathSVGContainer {
  width: 100%;
  position: relative;
  svg {
    width: 100%;
    .scopeGroup {
      .riskRatingCircle {
        fill: $grey;
        opacity: 0.3;
      }
      rect {
        -webkit-filter: drop-shadow( 1px 1px 3px rgba(0, 0, 0, .1));
        filter: drop-shadow( 1px 1px 3px rgba(0, 0, 0, .1));
      }
      &.isParentScope {
        rect {
          -webkit-filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, .1));
          filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, .1));
        }
      }
      &.selected {
        rect {
          stroke: $critical--75;
          fill: $critical--5;
          -webkit-filter: drop-shadow( 0 0 2px $critical--50 );
          filter: drop-shadow( 0 0 2px $critical--50 );
        }
        &.high {
          rect {
            stroke: $high--75;
            fill: $high--5;
            -webkit-filter: drop-shadow( 0 0 2px $high--50 );
            filter: drop-shadow( 0 0 2px $high--50 );
          }
        }
        &.moderate {
          rect {
            stroke: $moderate--75;
            fill: $moderate--5;
            -webkit-filter: drop-shadow( 0 0 2px $moderate--50 );
            filter: drop-shadow( 0 0 2px $moderate--50 );
          }
        }
        &.low {
          rect {
            stroke: $low--75;
            fill: $low--5;
            -webkit-filter: drop-shadow( 0 0 2px $low--50 );
            filter: drop-shadow( 0 0 2px $low--50 );
          }
        }
        &.minimal {
          rect {
            stroke: $minimal--75;
            fill: $minimal--5;
            -webkit-filter: drop-shadow( 0 0 2px $minimal--50 );
            filter: drop-shadow( 0 0 2px $minimal--50 );
          }
        }
        &.default {
          rect {
            stroke: $high;
            fill: $grey--background;
            -webkit-filter: drop-shadow( 0 0 2px $darkBlue );
            filter: drop-shadow( 0 0 2px $darkBlue );
          }
        }

        // the color can be overriden by hovering over a list item due to how risk is calculated
        // &.hoverRatingOverride-critical {
        //   rect {
        //     stroke: $risk--darkRed;
        //     fill: $critical--10;
        //   }
        //   .riskRatingCircle {
        //     fill: $risk--darkRed;
        //   }
        // }
        // &.hoverRatingOverride-high {
        //   rect {
        //     stroke: $risk--red;
        //     fill: $high--10;
        //   }
        //   .riskRatingCircle {
        //     fill: $risk--red;
        //   }
        // }
        // &.hoverRatingOverride-moderate {
        //   rect {
        //     stroke: $risk--orange;
        //     fill: $moderate--10;
        //   }
        //   .riskRatingCircle {
        //     fill: $risk--orange;
        //   }
        // }
        // &.hoverRatingOverride-low {
        //   rect {
        //     stroke: $risk--yellow;
        //     fill: $low--10;
        //   }
        //   .riskRatingCircle {
        //     fill: $risk--yellow;
        //   }
        // }
        // &.hoverRatingOverride-minimal {
        //   rect {
        //     stroke: $risk--green;
        //     fill: $minimal--10;
        //   }
        //   .riskRatingCircle {
        //     fill: $risk--green;
        //   }
        // }
        // &.hoverRatingOverride-default {
        //   rect {
        //     stroke: $darkBlue;
        //     fill: $grey--background;
        //   }
        //   .riskRatingCircle {
        //     fill: $darkBlue;
        //   }
        // }
      }
    }
    .edgeGroup {
      .edgePath, .edgeCount, .edgeArrow, .edgeCountCircle, .edgeHoverPath {
        cursor: pointer;
      }
      .edgePath {
        transition: unset;
      }
      .edgeArrowDirection {
        cursor: pointer;
        transform-origin: center;
        &.n {
          transform: rotate(-90deg);
        }
        &.ne {
          transform: rotate(-45deg);
        }
        &.e {
          transform: rotate(0deg);
        }
        &.se {
          transform: rotate(45deg);
        }
        &.s {
          transform: rotate(90deg);
        }
        &.sw {
          transform: rotate(135deg);
        }
        &.w {
          transform: rotate(180deg);
        }
        &.nw {
          transform: rotate(225deg);
        }
      }
      &.hovered {
        .edgeHoverPath {
          opacity: .15;
        }
      }
    }
    .nodeGroup {
      .nodeHoverCircle {
        cursor: pointer;
      }
      &.default {
        .nodeIconCircle {
          fill: $darkBlue--50;
        }
        .nodeHoverCircle {
          fill: $darkBlue--50;
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: $darkBlue--50;
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: $darkBlue--75;
          }
          .nodeHoverCircle {
            fill: $darkBlue--75;
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: $darkBlue--75;
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.high {
        .nodeIconCircle {
          fill: $high--50;
        }
        .nodeHoverCircle {
          fill: $high--50;
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: $high--50;
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: $high--75;
          }
          .nodeHoverCircle {
            fill: $high--75;
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: $high--75;
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.moderate {
        .nodeIconCircle {
          fill: $moderate--50;
        }
        .nodeHoverCircle {
          fill: $moderate--50;
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: $moderate--50;
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: $moderate--75;
          }
          .nodeHoverCircle {
            fill: $moderate--75;
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: $moderate--75;
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.low {
        .nodeIconCircle {
          fill: $low--50;
        }
        .nodeHoverCircle {
          fill: $low--50;
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: $low--50;
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: $low--75;
          }
          .nodeHoverCircle {
            fill: $low--75;
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: $low--75;
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.minimal {
        .nodeIconCircle {
          fill: $minimal--50;
        }
        .nodeHoverCircle {
          fill: $minimal--50;
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: $minimal--50;
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: $minimal--75;
          }
          .nodeHoverCircle {
            fill: $minimal--75;
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: $minimal--75;
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.primary {
        .nodeIconCircle {
          fill: $primary--50;
        }
        .nodeHoverCircle {
          fill: $primary--50;
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: $primary--50;
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: $primary--75;
          }
          .nodeHoverCircle {
            fill: $primary--75;
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: $primary--75;
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.sensitive {
        .nodeIndicatorDot {
          fill: $status--red;
        }
        .nodeIconCircle {
          fill: $primary--75;
        }
        .nodeHoverCircle {
          fill: $primary--75;
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: $primary--75;
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: $primary;
          }
          .nodeHoverCircle {
            fill: $primary;
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: $primary;
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.isAdversary {
        .nodeIconCircle {
          fill: $high--50;
        }
        .nodeHoverCircle {
          fill: $high--50;
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: $high--50;
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: $high--75;
          }
          .nodeHoverCircle {
            fill: $high--75;
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: $high--75;
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      // .nodeHoverCircle {
      //   cursor: pointer;
      // }
      // .svgNodeIconOutline {
      //   transition: all 0.2s ease-in-out;
      // }
      // &.selected {
      //   .svgNodeIconOutline {
      //     fill: $darkBlue;
      //   }
      // }
      // &.hovered {
      //   .svgNodeBackgroundCircle {
      //     fill: $grey--divider;
      //   }
      //   .svgNodeIconOutline {
      //     fill: $darkBlue;
      //   }
      // }
      // &.onDetailPage {
      //   .nodeHoverCircle {
      //     fill: $critical--10;
      //   }
      //   .svgNodeIconOutline {
      //     fill: $critical;
      //   }
      //   &.high {
      //     .nodeHoverCircle {
      //       fill: $high--10;
      //     }
      //     .svgNodeIconOutline {
      //       fill: $high;
      //     }
      //   }
      //   &.moderate {
      //     .nodeHoverCircle {
      //       fill: $moderate;
      //     }
      //     .svgNodeIconOutline {
      //       fill: $moderate;
      //     }
      //   }
      //   &.low {
      //     .nodeHoverCircle {
      //       fill: $low--10;
      //     }
      //     .svgNodeIconOutline {
      //       fill: $low;
      //     }
      //   }
      //   &.minimal {
      //     .nodeHoverCircle {
      //       fill: $minimal--10;
      //     }
      //     .svgNodeIconOutline {
      //       fill: $minimal;
      //     }
      //   }
      // }
    }
  }
  #panZoomWrapper {
    transition: all 0.3s ease-in-out;
  }
  .graphActions {
    position: absolute;
    bottom: 1em;
    right: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    button {
      @include dsButton( 'secondary' );
      &.revertZoomButton {
        margin-right: 1em;
      }
      &.fullscreenButton {
        padding: 0.375em;
        .inlineSVG {
          margin: 0;
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

.pathsGraphFullscreenModal {
  padding-bottom: 0;
  .modalBody {
    padding: 2em;
    margin: 2em;
    border-radius: $borderRadius;
    background-color: $grey--background;
    width: calc( 100vw - 4em );
    max-height: unset;
    position: relative;
    .pathSVGContainer {
      position: static;
      .graphActions {
        bottom: 1em;
        right: 1em;
      }
    }
  }
}