/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

#pageContent.explore_pathsPageContainer.exploreContainer {
  flex-direction: column;
  .advancedFilterFormWrapper {
    .alwaysVisible {
      .filterWrapper.item_count {
        right: 1em;
      }
    }
  }
  .riskInsightDetailBody.path {
    columns: $darkBlue;
    .detailBodyRow {
      border-bottom: none;
    }
    .detailColumnHeader {
      .stepWrapper {
        .inlineSVG {
          width: 1.5em;
          height: 1.5em;
          margin-right: 0.5em;
          path {
            fill: $primaryBlue;
          }
        }
        h2 {
          font-size: 1.125em;
        }
      }
      .pathRiskBadges {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        .riskReduction {
          margin-right: 0.5em;
        }
      }
    }
    .detailBodyColumn.remediation {
      margin: 0;
      padding-right: 0;
    }
  }
}