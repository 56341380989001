/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.dropdownButtonShade {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 1004;
  &.opaque {
    background: rgba(#000, 0.2);
  }
  &.visible {
    visibility: visible;
  }
}

.dropdownButtonWrapper {
  position: relative;
  font-family: $sans;
  .dropdownButtonTrigger {
    @include dsButton('minimal-primary');
    border-radius: $borderRadius;
    .inlineSVG {
      path {
        fill: $primaryBlue;
      }
    }
    // width: auto;
    // span {
    //   font-weight: 600;
    // }
    // .inlineSVG {
    //   margin-left: 0.5em;
    //   margin-right: 0;
    // }
  }
}

.dropdownButtonMenu {
  visibility: hidden;
  background: #fff;
  position: absolute;
  top: 3.5em;
  right: 0;
  border-radius: $borderRadius--input;
  box-shadow: $boxShadow--heavy;
  color: $darkBlue;
  width: 100%;
  font-family: $sans;
  z-index: 1005;
  width: 100%;
  border: 1px solid $grey--icon;
  ul {
    padding: 0.5em;
    li {
      width: 100%;
      margin-bottom: 0;
      a {
        width: 100%;
        padding: 0.5em 1em;
        border-radius: none;
        background: #fff;
        text-decoration: none;
        color: $darkBlue;
        text-align: left;
        padding: 0.5em 1em;
        border-radius: $borderRadius;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        &:hover {
          background: $grey--divider;
        }
      }
    }
  }
  &.visible {
    visibility: visible;
  }
}
