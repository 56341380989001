/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.hosts_total,
.dashboardWidgetWrapper.patches_total,
.dashboardWidgetWrapper.vulnerabilities_total {
  .widgetContent {
    color: $darkBlue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .bigNumberWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      h3 {
        font-weight: 400;
        color: $grey;
      }
      h2 {
        font-size: 3.5em;
        margin-bottom: 0.25em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .inlineSVG {
          margin-right: 0.25em;
          height: 0.75em;
          width: 0.75em;
          path {
            fill: $grey--icon;
          }
        }
      }
      span {
        font-size: 1.125em;
        text-align: center;
      }
    }
  }
}