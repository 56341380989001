/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.reportCreatorBanner {
  width: calc( 100% + 4em );
  margin: -2em -2em 2em -2em;
  padding: 1em 2em;
  background: $grey--divider;
  border-bottom: 1px solid $grey--icon;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    margin-left: 1em;
    background: none;
    color: $darkBlue;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1em;
    padding: 0;
    border: none;
    cursor: pointer;
    .inlineSVG {
      margin-left: 0.5em;
      height: 1.5em;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.creatorStep {
  background: $darkBlue;
  color: #fff;
  font-size: 1.5;
  font-weight: 600;
  border-radius: $borderRadius--full;
  padding: 0.5em;
  display: block;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 1em;
  margin-right: 1em;
}
.reporCreatorShade {
  position: fixed;
  z-index: 1004;
  left: 32.25em;
  top: 6.5em;
  width: calc(100vw - 32.25em);
  height: calc(100vh - 6.5em - 4em);
  background: $darkBlue;
  opacity: 0.4;
}
.reportCreatorForm {
  // position: fixed;
  // left: 32.25em;
  // top: 6.5em;
  box-sizing: border-box;
  position: relative;
  margin: -2em 0 0 0;
  padding: 6em 0em 1em 2em;
  border-left: 1px solid $grey--divider;
  .formWrapper {
    width: 100%;
  }
  .linkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2em;
    .linkToReports {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.25em 0.5em;
      border: 1px solid $primaryBlue;
      border-radius: $borderRadius;
      .inlineSVG {
        margin-left: 0.5em;
        height: 1.5em;
      }
      &:hover {
        background: $primaryBlue--background;
        text-decoration: none;
      }
    }
  }

  .reportCreatorActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    button {
      @include dsButton('secondary');
      margin: 0.5em 1em;
      white-space: nowrap;
      &.submit {
        @include dsButton('primary');
        margin: 0.5em 1em;
        white-space: nowrap;
      }
    }
  }
  .formWrapper {
    .fieldGroupWrapper {
      .fieldWrapper.selectList {
        .selectListContainer.asVertical {
          label {
            width: 100%;
            .multiSelectFieldWrapper {
              margin-right: 0;
              width: 100%;
              max-width: 100%;
              select {
                margin-top: 0;
                width: 100%;
                max-height: 8em;
                height: auto;
                min-height: unset;
              }
            }
          }
        }
      }
    }
  }
}
.reportCreatorShade {
  width: 100vw;
  height: 100vh;
  background: rgba($darkBlue, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1004;
}
.reportCreatorModal,
.inlineReportCreatorContainer {
  visibility: visible;
  height: auto;
  position: fixed;
  width: 80vw;
  background: #fff;
  top: 6.5em;
  left: 10vw;
  padding: 1em 2em;
  z-index: 1005;
  border-radius: $borderRadius;
  box-shadow: $boxShadow--heavy;
  font-family: $sans;
  color: $darkBlue;
  h2 {
    width: 100% !important;
    font-size: 1em;
    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
  }
  .linkToReports {
    margin-left: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.3s ease-in-out;
    color: $grey--icon;
    text-decoration: none;
    font-weight: 400;
    .inlineSVG {
      margin-left: 0.5em;
      width: 1em;
      height: 1em;
      path {
        transition: all 0.3s ease-in-out;
        fill: $grey--icon;
      }
    }
    &:hover {
      text-decoration: underline;
      color: $primaryBlue;
      .inlineSVG {
        path {
          fill: $primaryBlue;
        }
      }
    }
  }
  .flashMessage {
    width: 50% !important;
    margin-left: 50% !important;
  }
  .formWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    .fieldGroupWrapper {
      width: 50%;
      padding: 0 1em;
      border-right: 1px solid $grey--divider;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
        border-right: none;
      }
      .fieldWrapper.recipients {
        label {
          .summaryWrapper {
            background: #fff;
            .loadingEmptyWrapper {
              background: #fff;
              .emptyState {
                background: #fff;
              }
            }
          }
        }
      }
    }
  }
  .reportCreatorActions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    margin-top: 1em;
    button {
      @include dsButton( 'minimal' );
      margin: 0;
      &:last-of-type {
        @include dsButton( 'minimal-primary' );
        margin-left: 1em;
      }
    }
  }
}

.inlineReportCreatorContainer {
  position: relative;
  width: unset;
  top: unset;
  left: unset;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  border-top: 1px solid $grey--divider;
  color: $darkBlue;
  padding-top: 1em;
  margin-top: 1em;
  .formWrapper {
    .fieldGroupWrapper {
      &.one, &.two {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        .fieldWrapper {
          width: 50%;
          padding-right: 1em;
          &.text {
            .textFieldWrapper {
              input {
                padding: 0.5em 0.5em 0.5em 0.75em;
                line-height: 1.25em;
              }
            }
          }
          &.radioGroup {
            label {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              flex-wrap: wrap;
              .labelWrapper {
                width: 100%;
              }
              .radioOptionWrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: nowrap;
                width: 50%;
                .labelWrapper {
                  color: $darkBlue;
                  width: unset;
                }
              }
            }
          }
          &.checkbox {
            padding-top: 3em;
            label {
              .labelWrapper {
                color: $darkBlue;
              }
            }
          }
          &.schedule {
            width: 100%;
            padding-right: 0;
          }
          &.date {
            label {
              input {
                margin-top: 0;
              }
            }
          }
        }
      }
      &.two {
        .fieldWrapper {
          &.checkbox {
            padding-top: 1em;
          }
        }
      }
    }
  }
}