/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
button.copyToClipBoardButton {
  padding: 0;
  border: none;
  background: none;
  margin-left: 0.5em;
  opacity: 0.5;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 1;
  }
  .inlineSVG {
    height: 1.25em;
  }
}
