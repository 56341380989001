/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.modalContainer.reactSetupModal {
  .modalBody {
    .formWrapper {
      .fieldGroupWrapper {
        margin-left: -3em;
        margin-right: -3em;
        padding: 2em 3em 0em 3em;
        &.selector {
          border-top: none;
          margin-top: 1em;
          margin-left: 0;
          margin-right: 0;
          padding: 0;
        }
        &.isEmpty {
          border-top: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}