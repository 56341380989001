/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.areaChart, .areaPlusBarWrapper {
  .areaChartPoint {
    cursor: pointer;
  }
  .hoverIndicatorWrapper {
    rect, circle, line {
      cursor: pointer;
      opacity: 0;
    }
    &.highlight {
      rect, circle, line {
        opacity: 1;
      }
    }
  }
}