/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

#reportingContainer {
  width: 100%;
  height: calc(100vh - 6.5em);
  background: $grey--background;
}
