/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.configAlertDescriptionModal {
  padding: 0em !important;
  .modalBody {
    padding: 1em 2em 4em 2em !important;
  }
  .alertDescriptionWrapper {
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-weight: 600;
      padding-bottom: 1em;
      .iconWrapper {
        background: $high--10;
        width: 2em;
        height: 2em;
        border-radius: $borderRadius--full;
        align-items: center;
        justify-content: space-around;
        margin-right: 0.75em;
      }
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: $high;
        }
      }
      &.medium {
        .iconWrapper {
          background: $moderate--10;
        }
        .inlineSVG {
          path {
            fill: $moderate;
          }
        }
      }
      &.low {
        .iconWrapper {
          background: $low--10;
        }
        .inlineSVG {
          path {
            fill: $low;
          }
        }
      }
    }
    .upper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: flex-start;
      border-bottom: 1px solid $grey--divider;
      padding: 1em 0;
      margin-bottom: 1em;
      span {
        width: 50%;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 1em;
      width: 100%;
      label {
        font-weight: 600;
        margin-bottom: 0.5em;
      }
      .infoSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0.5em;
        label {
          margin-right: 0.25em;
          margin-bottom: 0;
        }
      }
      ul {
        margin-left: 1.5em;
        li {
          list-style: disc;
        }
      }
      .detailsWrapper {
        width: 100%;
      }
      details {
        summary {
          padding: 0.5em 1em;
          color: $primaryBlue;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          border-radius: $borderRadius;
          display: inline-block;
          margin-top: 0.5em;
          &:hover {
            background: $primaryBlue--background;

          }
        }
      }
    }
    pre {
      margin-top: 0;
      width: 100%;
    }
  }
  .alertActionsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    a {
      @include dsButton( 'minimal-primary' );
      margin-left: 1em;
      &.secondary {
        @include dsButton( 'minimal-dark' );
      }
    }
  }
}

#pageHeaderContainer.configurationAlertsHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  .filterForm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    .filterWrapper {
      width: unset;
      margin: 0;
      padding: 0;
      border-bottom: none;
      flex-grow: 0;
      margin-right: 1em;
      label {
        width: unset;
        flex-direction: column;
        select, input {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
      }
      &.ignore {
        label {
          flex-direction: row;
          margin-top: 2.5em;
          .labelWrapper {
            font-weight: 600;
          }
        }
      }
    }
  }
  .paginationContainer.indeterminantPagination {
    border-left: 1px solid $grey--divider;
    padding-right: 0;
    flex-shrink: 0;
    .nextPreviousButtonsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-left: 1em;
    }
  }
  .exportMenuTrigger {
    margin: 1em;
    margin-top: 1.25em;
    margin-right: 0;
    margin-left: 1em;
  }
}