/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper {
  padding: 1em;
  font-family: $sans;
  overflow: hidden;
  margin: 0.5em;
  width: calc( 100% - 1em );
  height: calc( 100% - 1em);
  background: #fff;
  border-radius: $borderRadius--input;
  &:hover {
    .editModeShade {
      opacity: 0.99;
    }
  }
  .emptyState {
    grid-column: 1 / 3;
  }
  .widgetHeader {
    padding: 0;
    box-sizing: border-box;
    border-radius: $borderRadius $borderRadius 0 0;
    background: #fff;
    padding-bottom: 0.5em;
    h2 {
      color: $darkBlue;
      margin: 0;
      padding: 0;
      font-size: 1.125em;
      line-height: 1.125em;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.3s ease-in-out;
      .inlineSVG {
        height: 1.125em;
        width: 1.125em;
        margin-right: 0.5em;
      }
    }
    select {
      font-size: 0.625em;
      padding: 0.25em  2.5em .25em 1em;
      border: 1px solid $grey;
      border-radius: $borderRadius;
      background: #fff;
      background-repeat: no-repeat;
      background-size: auto;
      background-image: url('./../../../../../../images/icons/input/select-arrow.svg');
      background-position: calc(100% - 0.75em) 50%;
      &:focus {
        outline: none;
      }
    }
    &.withBetaFlag {
      position: relative;
      overflow: hidden;
      font-family: $sans;
      font-weight: 600;
      text-indent: 6em;

      &:after {
        content: "Beta";
        padding: 0.375em 1.25em;
        width: 5em;
        text-align: center;
        color: #fff;
        background: $primaryBlue;
        display: block;
        box-shadow: $boxShadow;
        position: absolute;
        border-radius: $borderRadius;
        text-indent: 0;
      }
    }
  }
  .widgetContent {
    padding-bottom: 0;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    position: relative;
    height: 100%;
    .chartLegend {
      align-self: center;
      font-size: 0.875em;
    }
    &:hover {
      .fullScreenVisualToggleButton {
        display: flex;
      }
    }
  }
  .fullScreenVisualToggleButton {
    position: absolute;
    bottom: 0em;
    right: 0em;
    z-index: 50;
    flex-shrink: 0;
    display: none;
  }
  .editModeFooter {
    z-index: 2;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    background: $grey--background;
    border-top: 1px solid $grey--icon;
    box-shadow: $boxShadow;
    border-radius: 0 0 $borderRadius $borderRadius;
    .editModeButton {
      transition: all 0.3s ease-in-out;
      border: none;
      cursor: pointer;
      width: 2.5em;
      height: 2.5em;
      border-radius: $borderRadius--full;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      padding: 0;
      margin: 0;
      background: none;
      .inlineSVG {
        flex-shrink: 0;
        width: 1.5em;
        height: 1.5em;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        background: $status--red--light;
        .inlineSVG {
          opacity: 1;
        }
      }
      &.draggable {
        cursor: move;
        &:hover {
          background: $primaryBlue--background;
          .inlineSVG {
            path {
              fill: $primaryBlue;
            }
          }
        }
      }
      &.edit {
        &:hover {
          background: $grey--divider;
        }
      }
    }
  }
  &.risk_score,
  &.risk_target {
    .fullScreenVisualToggleButton {
      right: 0.5em;
      bottom: 0.5em;
    }
  }
  // &.widgetHeight-1 {
  //   .widgetContent {
  //     height: 2em;
  //   }
  // }
  // &.widgetHeight-2 {
  //   .widgetContent {
  //     height: 6em;
  //   }
  // }
  // &.widgetHeight-3 {
  //   .widgetContent {
  //     height: 10em;
  //   }
  // }
  // &.widgetHeight-4 {
  //   .widgetContent {
  //     height: 15em;
  //   }
  // }
  // &.widgetHeight-5 {
  //   .widgetContent {
  //     height: 18em;
  //   }
  // }
  // &.widgetHeight-6 {
  //   .widgetContent {
  //     height: 23.5em;
  //   }
  // }
  // &.widgetHeight-7 {
  //   .widgetContent {
  //     height: 26em;
  //   }
  // }
  // &.widgetHeight-8 {
  //   .widgetContent {
  //     height: 31em;
  //   }
  // }
  // &.widgetHeight-9 {
  //   .widgetContent {
  //     height: 34em;
  //   }
  // }
  // &.widgetHeight-10 {
  //   .widgetContent {
  //     height: 38em;
  //   }
  // }
  // &.widgetHeight-11 {
  //   .widgetContent {
  //     height: 42em;
  //   }
  // }
  // &.widgetHeight-12 {
  //   .widgetContent {
  //     height: 46em;
  //   }
  // }
  // &.widgetHeight-13 {
  //   .widgetContent {
  //     height: 50em;
  //   }
  // }
  // &.widgetHeight-14 {
  //   .widgetContent {
  //     height: 54em;
  //   }
  // }
  // &.widgetHeight-15 {
  //   .widgetContent {
  //     height: 58em;
  //   }
  // }
  // &.widgetHeight-16 {
  //   .widgetContent {
  //     height: 62em;
  //   }
  // }
  // &.widgetHeight-17 {
  //   .widgetContent {
  //     height: 66em;
  //   }
  // }
  // &.widgetHeight-18 {
  //   .widgetContent {
  //     height: 70em;
  //   }
  // }
  // &.widgetHeight-19 {
  //   .widgetContent {
  //     height: 74em;
  //   }
  // }
  // &.widgetHeight-20 {
  //   .widgetContent {
  //     height: 78em;
  //   }
  // }
  // &.widgetHeight-21 {
  //   .widgetContent {
  //     height: 82em;
  //   }
  // }
  // &.widgetHeight-22 {
  //   .widgetContent {
  //     height: 86em;
  //   }
  // }
  // &.widgetHeight-23 {
  //   .widgetContent {
  //     height: 90em;
  //   }
  // }
  // &.widgetHeight-24 {
  //   .widgetContent {
  //     height: 94em;
  //   }
  // }
  // &.widgetHeight-25 {
  //   .widgetContent {
  //     height: 98em;
  //   }
  // }
  // &.widgetHeight-26 {
  //   .widgetContent {
  //     height: 102em;
  //   }
  // }
  // &.widgetHeight-27 {
  //   .widgetContent {
  //     height: 106em;
  //   }
  // }
  // &.widgetHeight-28 {
  //   .widgetContent {
  //     height: 110em;
  //   }
  // }
  // &.widgetHeight-29 {
  //   .widgetContent {
  //     height: 114em;
  //   }
  // }
  // &.widgetHeight-30 {
  //   .widgetContent {
  //     height: 118em;
  //   }
  // }
}