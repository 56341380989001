/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.notificationSubscriptionsWrapper {
  padding: 1em;
  font-family: $sans;
  .dropdownWrapper.selectFieldMenuWrapper.user_selector {
    .dropdownTrigger {
      justify-content: space-between !important;
      flex-direction: row !important;
      align-items: center !important;
      .selectedUserDisplayName {
        flex-grow: 1 !important;
        display: block !important;
        width: unset !important;
      }
      .ratingBadge {
        flex-grow: 0 !important;
        width: 16em !important;
        justify-content: space-around !important;
      }
    }
  }
  .subscriptionHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $grey;
    text-transform: uppercase;
    padding: 0.5em 0 1em 0;
    font-weight: 600;
    font-size: 0.875em;
    span {
      span {
        margin-left: 2em;
      }
    }
  }
  .subscriptionRow {
    .fieldGroupWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 1em;
      height: 4em;
      .fieldWrapper {
        margin-bottom: 0;
        &.checkbox {
          flex-grow: 1;
          label {
            .labelWrapper {
              color: $darkBlue;
              font-weight: 600;
              font-size: 1.125em;
            }
            .toggleWrapper {
              top: -0.125em;
            }
          }
          &.diff_digest_checkbox {
            flex-grow: 0;
            margin-left: 5.5em;
            margin-right: 1em;
          }
        }
        &.select {
          label {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .labelWrapper {
              font-size: 1em;
              color: $darkBlue;
              text-transform: none;
              margin: 0;
              padding: 0 0.5em;
            }
            .selectFieldWrapper {
              select {
                border-radius: $borderRadius;
                padding-right: 2.5em;
              }
            }
          }
        }
        &.contentBlock {
          color: $grey;
          font-weight: 600;
        }
      }
    }
  }
}

.dropdownMenu.user_selector {
  .selectedUserDisplayName {
    flex-grow: 1 !important;
    display: block !important;
    width: unset !important;
  }
  .ratingBadge {
    flex-grow: 0 !important;
    width: 16em !important;
    justify-content: space-around !important;
  }
}