/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.recordListItem {
  padding-bottom: 0;
  margin-bottom: 0.125em;
  position: relative;
  a, .notLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $darkBlue;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin: 0;
    padding: 0.375em 0.75em;
    border-radius: 0.25em;
    cursor: pointer;
    .itemContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: calc( 100% - 2em );
      strong {
        margin-right: 0.5em;
      }
    }
    &.withPercentageBar {
      justify-content: flex-start;
      .itemContent {
        width: 100%;
        display: grid;
        grid-template-columns: 14em 1fr 4em;
        grid-gap: 0.5em;
        padding-right: 1em;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 0.5em;
          flex-shrink: 0;
          font-weight: 600;
          white-space: nowrap;
          &.vulnerability {
            width: 9em;
          }
        }
        .itemPercentageBarWrapper {
          flex-shrink: 1;
          margin-right: 0.5em;
        }
        strong {
          width: 100%;
          display: block;
          text-align: right;
          &.risk-critical {
            color: $critical;
          }
          &.risk-high, &.risk-weaponized {
            color: $high;
          }
          &.risk-moderate, &.published_details, &.details_published {
            color: $moderate;
          }
          &.risk-low, &.poc {
            color: $low;
          }
          &.risk-minimal {
            color: $minimal;
          }
          &.risk-primary {
            color: $primary;
          }
          &.risk-unknown, &.risk-default, &.risk-private {
            color: $grey;
          }
          &.risk-darkBlue {
            color: $darkBlue;
          }
        }
      }
    }
    .inlineSVG {
      transition: all 0.3s ease-in-out;
      width: 1em;
      height: 1em;
      opacity: 0.3;
      margin-right: 0.5em;
    }
    &:visited {
      color: $darkBlue;
    }
    &:hover, &.isSelected {
      color: $primaryBlue;
      .inlineSVG {
        opacity: 1;
        path {
          fill: $primaryBlue;
        }
      }
      &.fullHover {
        text-decoration: none;
        color: $darkBlue;
        background: $primary--10;
        &.risk-critical {
          background: $critical--10;
          .inlineSVG {
            path {
              fill: $critical;
            }
          }
        }
        &.risk-high, &.risk-weaponized {
          background: $high--10;
          .inlineSVG {
            path {
              fill: $high;
            }
          }
        }
        &.risk-moderate, &.published_details, &.details_published {
          background: $moderate--10;
          .inlineSVG {
            path {
              fill: $moderate;
            }
          }
        }
        &.risk-low, &.poc {
          background: $low--10;
          .inlineSVG {
            path {
              fill: $low;
            }
          }
        }
        &.risk-minimal {
          background: $minimal--10;
          .inlineSVG {
            path {
              fill: $minimal;
            }
          }
        }
        &.risk-primary {
          background: $primary--10;
          .inlineSVG {
            path {
              fill: $primary;
            }
          }
        }
        &.risk-unknown, &.risk-default, &.risk-private {
          background: $grey--divider;
          .inlineSVG {
            path {
              fill: $grey;
            }
          }
        }
        &.risk-darkBlue {
          background: $grey--divider;
          .inlineSVG {
            path {
              fill: $darkBlue;
            }
          }
        }
      }
    }
    &.isSelected {
      &.fullHover {
        padding-left: 2em;
        &:before {
          content: "";
          position: absolute;
          height: 0.75em;
          width: 0.75em;
          border-radius: $borderRadius--full;
          background: $primaryBlue;
          top: 50%;
          margin-top: -0.375em;
          left: 0.75em;
        }
        &.risk-critical {
          &:before {
            background: $critical;
          }
        }
        &.risk-high, &.risk-weaponized {
          &:before {
            background: $high;
          }
        }
        &.risk-moderate, &.published_details, &.details_published {
          &:before {
            background: $moderate;
          }
        }
        &.risk-low, &.poc {
          &:before {
            background: $low;
          }
        }
        &.risk-minimal {
          &:before {
            background: $minimal;
          }
        }
        &.risk-primary {
          &:before {
            background: $primary;
          }
        }
        &.risk-unknown, &.risk-default, &.risk-private {
          &:before {
            background: $grey;
          }
        }
        &.risk-darkBlue {
          &:before {
            background: $darkBlue;
          }
        }
      }
    }
  }
  .relatedSignatureButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    padding: 0.375em 0.75em;
    border-radius: $borderRadius;
    margin: 0;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    color: $darkBlue;
    width: 100%;
    .labelWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      img {
        display: block;
        height: 1.5em;
        margin-right: 0.5em;
      }
      strong {
        font-weight: 600;
        margin-right: 0.25em;
      }
      span {
        font-weight: 400;
      }
    }
    .timestamp {
      font-weight: 400;
      color: $grey;
    }
    &:hover {
      background: $grey--divider;
    }
  }
  .additionalActionLink {
    background: $high--10;
    padding: 0.5em 0.5em 0.5em 1em;
    color: $risk--darkRed;
    .inlineSVG {
      path {
        fill: $risk--darkRed;
      }
    }
    &:hover {
      background: $high--10;
    }
  }
  .disableClicks {
    cursor: default;
    pointer-events: none;
  }
}