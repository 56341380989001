/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.recordCardContainer.recordCardType_scope,
.recordCardContainer.recordCardType_host {
  .contentGridWrapper {
    .mainContent {
      .recordCardContent {
        .cvssCategoriesBreakdownWrapper, .instancesBreakdownWrapper {
          border-bottom: 1px solid $grey--divider;
        }
        .recordDetails__InformationPanel__Body_Section.statistics {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding-top: 2em;
        }
        section {
          margin-bottom: 1em;
          strong {
            margin-right: 0.25em;
          }
        }
      }
    }
  }
}