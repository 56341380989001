/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;
.riskGradeWidgetWrapper {
  background: #fff;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 3em;
  height: 100%;
  .riskGradeContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .scoreCircle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 10em;
      height: 10em;
      border-radius: $borderRadius--full;
      margin-bottom: 1.5em;
      .gradeWrapper {
        font-size: 4.5em;
      }
    }
    .chartLabel {
      color: $darkBlue;
      font-size: 1.25em;
    }
  }
  &.risk--critical {
    .scoreCircle {
      background: $critical--10;
      .gradeWrapper {
        color: $critical;
      }
    }
  }
  &.risk--high {
    .scoreCircle {
      background: $high--10;
      .gradeWrapper {
        color: $high;
      }
    }
  }
  &.risk--moderate {
    .scoreCircle {
      background: $moderate--10;
      .gradeWrapper {
        color: $moderate;
      }
    }
  }
  &.risk--low {
    .scoreCircle {
      background: $low--10;
      .gradeWrapper {
        color: $low;
      }
    }
  }
  &.risk--minimal {
    .scoreCircle {
      background: $minimal--10;
      .gradeWrapper {
        color: $minimal;
      }
    }
  }
  &.risk--primary, &.risk--primaryBlue {
    .scoreCircle {
      background: $primary--10;
      .gradeWrapper {
        color: $primary;
      }
    }
  }
  &.risk--default {
    .scoreCircle {
      background: rgba( $darkBlue, 0.1 );
      .gradeWrapper {
        color: $darkBlue;
      }
    }
  }
}

.dashboardWidgetWrapper.risk_grade {
  padding: 0;
  overflow: hidden;
  border: none;
  &.widgetHeight-4 {
    .widgetContent {
      height: 17em;
    }
  }
  &.widgetHeight-5 {
    .widgetContent {
      height: 21em;
    }
  }
  &.widgetHeight-6 {
    .widgetContent {
      height: 25em;
    }
  }
}
