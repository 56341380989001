/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.filterWrapper.textSearch {
  .searchInputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    border-radius: $borderRadius--input;
    background: $grey--background--light;
    border: 1px solid $grey--icon;
    padding: 0.25em 0.25em 0.25em 0.75em;
    margin-top: 0.5em;
    input {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: none;
      padding: 0;
      border: none;
      margin-top: 0 !important;
      &:focus {
        outline: none;
      }
    }
    button {
      @include dsButton('primary');
      flex-shrink: 0;
      padding: 0.25em 0.25em 0.25em 0.5em;
    }
  }
}
