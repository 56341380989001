/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.recordDetailsMainPanel {
  width: 100%;
  .recordDetails__MainPanel__Body {
    padding: 2em;
    background: #fff;
    border-radius: 0 $borderRadius $borderRadius $borderRadius;
    min-height: 50vh;
    position: relative;
    .recordDetails__MainPanel_BodyRow {
      padding: 2em 0;
      border-bottom: 1px solid $grey--divider;
      &.riskAssessment {
        padding-top: 0;
        .recordDetails__InformationPanel__Body_Section.statistics {
          &.patch, &.user {
            padding: 0 4em;
          }
        }
        &.host {
          padding-top: 0.5em;
          .recordDetails__InformationPanel__Body_Section.statistics {
            justify-content: flex-end;
            flex-wrap: wrap;
            .recordStatisticWrapper {
              margin-left: 1.5em;
              margin-bottom: 1.5em;
              margin-top: 1.5em;
            }
          }
        }
      }
      &.pathsAndAssets {
        .criticalPathsWrapper {
          padding: 1em;
          border-radius: $borderRadius;
          background: $grey--background;
          position: relative;
          .emptyState {
            padding: 1em;
            font-size: 1.25em;
            background: $grey--background;
            border-radius: $borderRadius;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
          }
          svg {
            max-height: 29em;
          }
          &.pathCount_5 {
            svg {
              max-height: 38em;
            }
          }
          .fullScreenVisualToggleButton {
            position: absolute;
            bottom: 0.5em;
            right: 0.5em;
          }
        }
        &.host {
          display: grid;
          grid-template-columns: 28em 1fr;
          grid-column-gap: 1em;
        }
      }
      &.recordLists {
        .collapsibleSectionsWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1em;
        }
        padding-bottom: 0;
        border-bottom: none;
      }
      .mainPanel__SectionDescription {
        margin-top: -1em;
        grid-column: 1 / 3;
      }
      h2, h3 {
        grid-column: 1 / 3;
        font-size: 1em;
        font-weight: 600;
        margin: 0;
        padding: 0;
        color: $darkBlue;
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
        .remediationInstructionsButton {
          @include dsButton( 'minimal-primary' );
          margin-left: 1em;
        }
        .addToPlanMenuWrapper {
          position: relative;
          z-index: unset;
          top: unset;
          left: unset;
          right: unset;
          .addToPlanMenuTriggerWrapper {
            .addToPlanMenuTrigger {
              @include dsButton( 'minimal-darkRed');
              .inlineSVG {
                opacity: 1;
                path {
                  fill: $risk--darkRed;
                }
              }
              &.high {
                @include dsButton( 'minimal-red');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: $risk--red;
                  }
                }
              }
              &.moderate {
                @include dsButton( 'minimal-orange');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: $risk--orange;
                  }
                }
              }
              &.low {
                @include dsButton( 'minimal-yellow');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: $risk--yellow;
                  }
                }
              }
              &.minimal {
                @include dsButton( 'minimal-green');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: $risk--green;
                  }
                }
              }
              &.unknown {
                @include dsButton( 'minimal-dark' );
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: $darkBlue;
                  }
                }
              }
            }
          }
        }
      }
      .bodySectionItem {
        margin-bottom: 1em;
        label {
          margin-bottom: 0.5em;
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .bodySectionDescription {
          padding: 0.5em 1em;
          background: $grey--background;
          border-radius: $borderRadius;
          margin-bottom: 1em;
        }
        ul {
          li {
            &.uniqueScanner {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 0.375em 0.5em 0.375em 0.75em;
              border-radius: $borderRadius--full;
              margin-bottom: 0.625em;
              border: 1px solid rgba( $grey--icon, 0.5 );
              .logoAndLabel {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding-right: 1em;
              }
              img {
                width: 1.5em;
                height: 1.5em;
                margin-right: 0.5em;
              }
            }
          }
        }
      }
    }
    .recordDetails__InformationPanel__Body_Section {
      &.statistics {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .recordStatisticWrapper {
          margin-bottom: 0;
          .statisticLabel {
            font-size: 1em;
          }
        }
      }
    }
  }
}