/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.graphModelScopeGroup {
  cursor: pointer;
  .draggableIcon {
    path {
      fill: $grey--icon;
    }
  }
  .graphModelScopeRectangle {
    -webkit-filter: drop-shadow( 2px 2px 6px rgba(0, 0, 0, .1));
    filter: drop-shadow( 2px 2px 6px rgba(0, 0, 0, .1));
    &.isParentScope {
      -webkit-filter: drop-shadow( 2px 2px 12px rgba(0, 0, 0, .1));
      filter: drop-shadow( 2px 2px 12px rgba(0, 0, 0, .1));
    }
  }
  &:hover {
    .draggableIcon {
      path {
        fill: $primaryBlue;
      }
    }
  }
  &.selected {
    .graphModelScopeRectangle {
      stroke: $darkBlue;
      fill: $grey--background--light;
    }
    .scopeIcon {
      path {
        fill: $grey;
      }
    }
  }
  &.externallyHovered {
    .graphModelScopeRectangle {
      stroke: $darkBlue;
      fill: $grey--background;
    }
    &.risk-critical {
      .graphModelScopeRectangle {
        stroke: $risk--darkRed;
        fill: $critical--10;
      }
      .scopeIcon {
        path {
          fill: $risk--darkRed;
        }
      }
    }
    &.risk-high {
      .graphModelScopeRectangle {
        stroke: $risk--red;
        fill: $high--10;
      }
      .scopeIcon {
        path {
          fill: $risk--red;
        }
      }
    }
    &.risk-moderate {
      .graphModelScopeRectangle {
        stroke: $risk--orange;
        fill: $moderate--10;
      }
      .scopeIcon {
        path {
          fill: $risk--orange;
        }
      }
    }
    &.risk-low {
      .graphModelScopeRectangle {
        stroke: $risk--yellow;
        fill: $low--10;
      }
      .scopeIcon {
        path {
          fill: $risk--yellow;
        }
      }
    }
    &.risk-minimal {
      .graphModelScopeRectangle {
        stroke: $risk--green;
        fill: $minimal--10;
      }
      .scopeIcon {
        path {
          fill: $risk--green;
        }
      }
    }
  }
}
