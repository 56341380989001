/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.exploreGraphContextMenuShade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.exploreGraphContextMenu {
  border-radius: $borderRadius--input;
  box-shadow: $boxShadow--heavy;
  position: absolute;
  background: #fff;
  .contextMenuHeader {
    padding: 0.75em 1em;
    color: $darkBlue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    border-radius: $borderRadius $borderRadius 0 0;
    border-bottom: 1px solid $grey--divider;
    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 1em;
      font-weight: 400;
      strong, span {
        margin-right: 0.25em;
      }
      .recordTypeHeaderIcon {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
      }
      .inlineSVG {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        path {
          fill: $grey--icon;
        }
      }
    }
  }
  ul {
    border-radius: 0 0 $borderRadius $borderRadius;
    background: $grey--background--light;
    border-top: none;
    padding: 0.5em;
    color: $darkBlue;
    li {
      width: 100%;
      padding: 0.5em 0.875em;
      border-radius: none;
      background: $grey--background--light;
      text-decoration: none;
      color: $darkBlue;
      text-align: left;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0.25em;
      cursor: pointer;
      &:hover {
        background: $grey--divider;
      }
    }
  }
}