/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.recordDetails__MainPanel__Body_DeepSurfaceScanning {
  .recordDetails__MainPanel_BodyRow {
    &.scanningStatusSummary {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2em;
    }
    &.latestScanLog {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}