/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

ul.setupRecordsList {
  li.setupRecordItem {
    background: none;
    border-bottom: 1px solid $grey--icon;
    padding: 1em;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background: #fff;
    &.isDraggable {
      cursor: move;
      &:hover, &.isDragging {
        border-radius: $borderRadius;
        background: #fff;
        box-shadow: $boxShadow--medium;
        border: 1px solid $grey--divider;
        border-bottom: 1px solid $grey--divider !important;
        .itemActions {
          .recordItemAction.draggable {
            opacity: 1;
          }
        }
      }
      &.isDragging {
        border: 1px solid $grey--icon;
        border-bottom: 1px solid $grey--icon !important;
        box-shadow: $boxShadow--heavy;
        .itemActions {
          .recordItemAction.draggable {
            opacity: 1;
            display: none;
            &.current {
              display: flex;
            }
          }
        }
      }
    }
    &.observed {
      .itemAttribute:first-of-type {
        position: relative;
        &:before {
          content: "observed";
          text-transform: uppercase;
          font-size: 0.75em;
          text-align: center;
          border-radius: $borderRadius;
          border: 2px solid $status--green;
          font-weight: 600;
          padding: 0.25em 0.5em;
          color: $status--green;
          width: 7em;
          position: absolute;
          top: 0.75em;
          right: 1.5em;
        }
      }

    }
    .itemAttribute {
      width: 20%;
      flex-grow: 0;
      border-left: 1px solid $grey--icon;
      padding-left: 2em;
      height: 100%;
      &:first-of-type {
        border-left: none;
        padding-left: 0;
      }
      strong {
        margin-bottom: 0.5em;
        text-transform: uppercase;
        color: $grey;
        font-weight: 600;
        font-size: .875em;
      }
      span {
        color: $darkBlue;
      }
      ul.scanScheduleList {
        margin: 0;
        padding: 0;
        font-size: 0.875em;
        padding-left: 1.25em;
        list-style: disc;
        li {
          list-style: disc;
          &.remainder {
            list-style: none;
            color: $grey;
          }
        }
      }
      &.shouldGrow {
        flex-grow: 1;
      }
      &.scannerRegistration {
        button {
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          color: $primaryBlue;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .itemActions {
      flex-direction: row;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      align-self: center;
      // .recordItemAction {
      //   margin: 0;
      //   padding: 0.5em;
      //   opacity: 0.6;
      //   border: none;
      //   background: none;
      //   cursor: pointer;
      //   display: block;
      //   transition: all 0.3s ease-in-out;
      //   margin-left: 0.5em;
      //   border-radius: $borderRadius--full;
      //   width: 2.5em;
      //   height: 2.5em;
      //   &.draggable {
      //     opacity: 0.2;
      //     .inlineSVG {
      //       height: 1.5em;
      //       width: 1.5em;
      //     }
      //     &.current {
      //       display: none;
      //     }
      //   }
      //   &:focus {
      //     outline: none;
      //   }
      //   &:hover {
      //     opacity: 1;
      //     background: $grey--divider;
      //   }
      //   .inlineSVG {
      //     width: 1.5em;
      //     height: 1.5em;
      //   }
      // }
    }
    &.selected {
      .itemAttribute:nth-of-type(1) {
        flex-shrink: 0;
      }
      .itemAttribute:nth-of-type(2) {
        position: relative;
        width: 80%;
        &:before {
          content: "Auto Provisioning";
          text-transform: uppercase;
          font-size: 0.75em;
          text-align: center;
          border-radius: $borderRadius;
          border: 2px solid $status--green;
          font-weight: 600;
          padding: 0.25em 0.5em;
          color: $status--green;
          width: 12em;
          position: absolute;
          top: 1em;
          right: 1.5em;
        }
      }
    }
    // alternate styling for a card based layout, used for more densely packed items ie scan groups
    &.asCard {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 40% 30% 30%;
      grid-template-areas: "A B B" "D G G";
      background: #fff;
      border-radius: $borderRadius;
      border: 1px solid #fff;
      box-shadow: $boxShadow;
      margin-bottom: 1em;
      .itemAttribute {
        width: 100%;
        border: none;
        ul {
          li {
            margin-top: 0.5em;
            a {
              display: inline-block;
              padding: 0.25em 2.125em 0.25em 0.5em;
              border: 1px solid $primaryBlue;
              border-radius: 0.25em;
              background-image: url('./../../../../images/icons/primary/link.svg');
              background-position: calc( 100% - 0.5em ) center;
              background-size: 1em;
              background-repeat: no-repeat;
              &:hover {
                text-decoration: none;
                background: $primaryBlue--background;
              }
            }
          }
        }
      }
      .itemAttribute:nth-of-type(1) {
        grid-area: A;
        padding-bottom: 1em;
        strong {
          display: none;
        }
        span {
          font-size: 1.5em;
        }
      }
      .itemAttribute:nth-of-type(2) {
        grid-area: B;
        padding-right: 1em;
        padding-bottom: 1em;
        border-left: 1px solid $grey--divider;
        span {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .itemAttribute:nth-of-type(3) {
        grid-area: C;
        padding-bottom: 1em;
      }
      .itemAttribute:nth-of-type(4) {
        grid-area: D;
        border-left: none;
        padding: 0;
        ul {
          display: flex;
          flex-direction: row;
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            margin-right: 0.25em;
            &:after {
              content: '/  '
            }
            &:nth-child(3) {
              &:after {
                display: none;
              }
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .itemAttribute:nth-of-type(5) {
        padding-top: 1em;
        grid-area: E
      }
      .itemAttribute:nth-of-type(6) {
        padding-top: 1em;
        grid-area: F
      }
      .itemActions {
        padding-top: 1em;
        grid-area: G;
        padding-right: 1em;
      }
    }
    // another alternate layout, more separation of items, better spacing, etc. could potentially become the new display
    // method going forward.
    &.alternateLayout {
      background: #fff;
      border-radius: $borderRadius;
      padding: 1em;
      border: 1px solid $grey--divider;
      color: $darkBlue;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      margin-bottom: 1em;
      section {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1em;
        border-left: 1px solid $grey--divider;
        strong.sectionLabel, span.sectionDetails {
          font-size: 0.875em;
          margin-bottom: 0.5em;
        }
        span.sectionDetails {
          margin-top: 0.5em;
          max-height: 20vh;
          overflow: hidden;
        }
        &.mainDetailsSection {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding-left: 0;
          border-left: none;
          .inlineSVG, img {
            width: 2em;
            height: 2em;
            margin-right: 1em;
          }
          .orderContainer {
            flex-shrink: 0;
            padding-right: 0.5em;
            margin-right: 0.75em;
            border-right: 1px solid $grey--divider;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            font-size: 1.5em;
            strong {
              font-size: 0.5em;
              margin-bottom: 0.25em;
              color: $grey;
            }
            span {
              color: $darkBlue;
            }
          }
          h2 {
            margin-bottom: 0.25em;
          }
          span {
            font-size: 0.875em;
            color: $status--green;
            font-weight: 600;
            margin-top: 0.5em;
            strong {
              color: $darkBlue;
            }
          }
        }
      }
    }
  }
}