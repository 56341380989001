/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.risk_over_time {
  color: $darkBlue;
  .riskOverTimeV3Wrapper {
    display: grid;
    grid-template-rows: 2fr 1fr 1fr 2.5em;
    height: 100%;
    // area plus both bars
    &.areaHeight--50 {
      grid-template-rows: 2fr 1fr 1fr 2.5em;
    }
    // area plus one bar
    &.areaHeight--66 {
      grid-template-rows: 2fr 1fr 2.5em;
    }
    // area by itself
    &.areaHeight--100 {
      grid-template-rows: 1fr 2.5em;
    }
  }
  .riskOverTimeAreaWrapper {
    padding-top: 0.5em;
    display: grid;
    grid-template-columns: 9em 1fr;
    height: 100%;
    position: relative;
    .axisLinesContainer {
      &.yAxis {
        position: absolute;
        z-index: 1;
        padding-top: 0.5em;
        padding-left: 9.5em;
        padding-right: 3.5em;
      }
    }
    .axisContainer {
      &.yAxis {
        padding-bottom: 0;
        height: 100%;
      }
    }
    // the svg
    .areaSVG {
      // padding-bottom: 0.5em;
      padding-left: 0.5em;
      margin-left: -0.5em;
      height: 100%;
      width: calc( 100% - 3em );
      z-index: 2;
      border-bottom: 2px dashed $grey--icon;
      &.noDash {
        border-bottom: none;
      }
    }

    .chartLegend {
      padding-left: 2em;
    }
    &.withLegend {
      grid-template-columns: 9em 1fr 14em;
      .areaSVG {
        width: 100%;
      }
      .axisLinesContainer {
        &.yAxis, &.xAxis {
          padding-right: 14.5em;
        }
      }
    }
  }
  .centerBarWrapper {
    margin-left: 8.375em;
    padding-bottom: 0.5em;
    height: 100%;
    z-index: 3;
    border-left: 2px solid $grey--icon;
    padding-right: 3.5em;
    position: relative;
    padding-left: 0.5em;
    svg {
      z-index: 2;
      width: 100%;
      height: 100%;
    }
    &:after {
      z-index: 1;
      content: '';
      position: absolute;
      top: calc( 50% - 3px );
      left: 0.5em;
      height: 2px;
      width: calc( 100% - 3em - 1em );
      border-bottom: 2px dashed $grey--divider;
    }
    &.instances {
      padding-top: 0.5em;
    }
    &.withLegend {
      padding-right: 14.5em;
      &:after {
        width: calc( 100% - 14.5em - 1em );
      }
    }

  }

  .axisLinesContainer {
    &.xAxis {
      position: absolute;
      z-index: 1;
      width: calc( 100% - 14.5em - 9em );
      padding-top: 1em;
      padding-bottom: 2.5em;
      margin-left: 9em;
      &.fullWidth {
        width: calc( 100% - 3em - 9em );
      }
    }
  }
  .axisContainer {
    &.xAxis {
      width: calc( 100% - 14.5em - 8.5em + 2px );
      padding-top: 1em;
      margin-left: calc(8.5em - 2px );
      border-top: 2px solid $grey--icon;
      &.fullWidth {
        width: calc( 100% - 3em - 8.5em + 2px );
      }
    }
  }
  .patchTuesdayAndHoverIndicatorsWrapper {
    position: absolute;
    width: calc(100% - 10em);
    padding-top: 1em;
    padding-bottom: 2.25em;
    margin-left: 9em;
    height: 100%;
    z-index: 4;
    padding-right: 3em;
    padding-left: 0.5em;
    svg {
      height: calc( 100% + 0.5em );
      margin-top: -0.5em;
    }
    &.withLegend {
      padding-right: 14em;
    }
  }
  .emptyState {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}