/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.remediationExportMenuWrapper {
  .dropdownMenu.visible {
    width: 24em;
    ul {
      margin: 0;
      li {
        list-style: none;
        cursor: default;
        div {
          cursor: pointer;
          &.dividerHeader {
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: 0em;
            font-size: 0.75em;
            line-height: 1em;
            color: $grey--icon;
            cursor: default;
            pointer-events: none;
          }
          &.comingSoonOption {
            cursor: default;
            opacity: 0.6;
            &:hover {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}