/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.remediation_plans {
  .remediationChartWrapper {
    padding-top: 1em;
    .remediationPlanWrapper {
      padding-bottom: 1em;
      margin-bottom: 1em;
      border-bottom: 1px solid $grey--divider;
      color: $darkBlue;
      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
      }
      label {
        font-weight: 600;
        margin-bottom: 0.5em;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .ratingBadge {
          margin-left: 0.5em;
        }
      }
      .labelAndBarWrapper {
        display: grid;
        grid-template-columns: 3.5em 1fr 5em;
        grid-gap: 0.5em;
        margin-bottom: 0.5em;
      }
      span {
        text-align: right;
        line-height: 1.5em;
        &.categoryLabelWrapper {
          text-align: left;
        }
      }
      .planCompletionWrapper {
        width: 100%;
        height: 1.5em;
        background: $grey--background;
        .planCompletionBar {
          height: 1.5em;
          min-width: 0.25em;
        }
      }
    }
  }
  .chartLegend.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    ul {
      justify-self: center;
      width: unset;
      li {
        margin-bottom: 0;
        &:first-child {
          margin-right: 2em;
        }
      }
    }
  }
}