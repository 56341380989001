/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.vulnerability_instances_category {
  .widgetContent {
    color: $darkBlue;
    .svgDonutWrapper {
      padding-top: 0.5em;
      min-height: calc( 100% - 4em );
      max-height: calc( 100% - 4em );
    }
    .categoryDescription {
      margin: 0;
      margin-top: 1em;
      text-align: center;
    }
  }
}