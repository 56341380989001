/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.remediateOptionsModal {
  padding-top: 0 !important;
  h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    margin-bottom: 1em;
    .recordIconWrapper {
      width: 1.75em;
      height: 1.75em;
      border-radius: $borderRadius--full;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: $grey--background;
      margin: 0;
      padding: 0;
      margin-right: 0.5em;
      .inlineSVG {
        width: 1.125em;
        height: 1.125em;
        margin: 0;
        padding: 0;
        path {
          fill: $grey;
        }
      }
      &.critical {
        background: $critical--10;
        .inlineSVG {
          path {
            fill: $critical;
          }
        }
      }
      &.high {
        background: $high--10;
        .inlineSVG {
          path {
            fill: $high;
          }
        }
      }
      &.moderate {
        background: $moderate--10;
        .inlineSVG {
          path {
            fill: $moderate;
          }
        }
      }
      &.low {
        background: $low--10;
        .inlineSVG {
          path {
            fill: $low;
          }
        }
      }
      &.minimal {
        background: $minimal--10;
        .inlineSVG {
          path {
            fill: $minimal;
          }
        }
      }
      &.primary {
        background: $primary--10;
        .inlineSVG {
          path {
            fill: $primary;
          }
        }
      }
      &.unknown {
        background: $grey--background;
        .inlineSVG {
          path {
            fill: $grey;
          }
        }
      }
    }
    span {
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .ratingBadge {
      margin-left: 0.5em;
    }
  }
  .newTabLink {
    margin-bottom: 0.5em;
    &.mitreLink {
      background: $moderate--10;
      color: $darkBlue;
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      .cveLogo {
        height: 1em;
        width: 3em;
        margin-right: 0.5em;
      }
      .inlineSVG {
        path {
          fill: $risk--orange;
        }
      }
      &:hover {
        background: $moderate--10;
      }
    }
  }
  .printInstructionsButton {
    @include dsButton( 'minimal-primary' );
    font-size: 0.875em;
    margin-left: 1em;
  }
  .remediationInstructionsDescription {
    margin: 0;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 0.75em 1em;
    background: $grey--background;
    border-radius: $borderRadius;
    font-size: 1em;
  }
  h3 {
    margin: 1em 0;
    padding: 0;
  }
  .itemsList {
    padding-bottom: 0;
    margin-bottom: 0.125em;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 1fr 1fr;
    li {
      margin-bottom: 0;
    }
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $darkBlue;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      margin: 0;
      padding: 0.375em 0.75em;
      border-radius: 0.25em;
      .itemContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: calc( 100% - 2em );
        strong {
          margin-right: 0.5em;
        }
      }
      .inlineSVG {
        transition: all 0.3s ease-in-out;
        width: 1em;
        height: 1em;
        opacity: 0.3;
        margin-right: 0.5em;
      }
      &.withPercentageBar {
        justify-content: flex-start;
        .itemContent {
          width: 100%;
          .name {
            width: 12em;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 0.5em;
            flex-shrink: 0;
            font-weight: 600;
            white-space: nowrap;
            &.vulnerability {
              width: 9em;
            }
          }
          .riskPercentageBarWrapper {
            flex-shrink: 1;
            margin-right: 0.5em;
          }
          strong {
            width: 4em;
            text-align: right;
            flex-shrink: 0;
            margin-right: 1em;
          }
        }
      }
      &:visited {
        color: $darkBlue;
      }
      &:hover {
        color: $primaryBlue;
        .inlineSVG {
          opacity: 1;
          path {
            fill: $primaryBlue;
          }
        }
        &.fullHover {
          text-decoration: none;
          color: $darkBlue;
          background: $primaryBlue--background;
        }
      }
    }
  }
}

// for printing
@media print {
  #risk_insightContainer {
    display: none;
  }
  #reactModalPortal {
    display: block !important;
    .remediateOptionsModal--shade {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
      &.visible {
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }
    .modalContainer {
      display: none !important;
      &.remediateOptionsModal {
        border-radius: 0;
        box-shadow: none;
        display: block !important;
        background: #fff;
        position: static;
        width: 100%;
        min-height: 100%;
        max-height: unset;
        height: 100%;
        padding: 0;
        .printInstructionsButton, .closeButton {
          display: none;
        }
        .modalBody {
          min-height: 100%;
          max-height: unset;
          height: unset;
          padding: 0;
          .expandingItemWrapper {
            .itemHeader {
              .carretWrapper {
                display: none;
              }
            }
            .itemContent {
              overflow: unset !important;
              max-height: unset !important;
              position: relative;
              height: unset !important;
            }
          }
        }
        .modalActions {
          display: none;
        }
      }
    }
  }
}