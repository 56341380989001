/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.filterWrapper.checkboxGroup {
  .labelWrapper.mainLabel {
    font-size: .875em;
    font-weight: 600;
    text-transform: uppercase;
  }
  label {
    margin-top: 0.5em;
    .labelWrapper {
      flex-grow: 1;
      margin: 0;
      margin-top: 0.25em;
      margin-left: 0.5em;
      text-transform: none;
      font-weight: 400;
      font-size: 1em;
      width: calc( 100% - 3em );
      overflow: hidden;
    }
  }
  .emptyState {
    font-size: 1em;
    padding: 1em;
  }
}