/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.hosts_specific,
.dashboardWidgetWrapper.patches_specific,
.dashboardWidgetWrapper.vulnerabilities_specific {
  background: none;
  padding: 0;
  .widgetContent {
    background: none;
  }
  .recordCardContainer {
    z-index: unset;
    width: calc( 100% - 1em );
    height: calc( 100% - 1em );
    overflow: hidden;
  }
}