/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

#riskExplanationPortal {
  z-index: 9999998;
  .riskExplanationShade {
    width: 100vw;
    height: 100vh;
    z-index: 9999998;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba( $darkBlue, 0.1 );
  }
  .riskExplanationContainer {
    position: fixed;
    z-index: 9999999;
    background: #fff;
    border-radius: $borderRadius;
    box-shadow: $boxShadow--medium;
    padding: 1em;
    font-family: $sans;
    color: $darkBlue;
    .riskExplanationHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.75em;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .recordIconWrapper {
          width: 1.75em;
          height: 1.75em;
          border-radius: $borderRadius--full;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          background: $grey--background;
          margin: 0;
          padding: 0;
          margin-right: 0.5em;
          flex-shrink: 0;
          .inlineSVG {
            width: 1em;
            height: 1em;
            margin: 0;
            padding: 0;
            path {
              fill: $grey;
            }
          }
          &.critical {
            background: $critical--10;
            .inlineSVG {
              path {
                fill: $critical;
              }
            }
          }
          &.high {
            background: $high--10;
            .inlineSVG {
              path {
                fill: $high;
              }
            }
          }
          &.moderate {
            background: $moderate--10;
            .inlineSVG {
              path {
                fill: $moderate;
              }
            }
          }
          &.low {
            background: $low--10;
            .inlineSVG {
              path {
                fill: $low;
              }
            }
          }
          &.minimal {
            background: $minimal--10;
            .inlineSVG {
              path {
                fill: $minimal;
              }
            }
          }
          &.primary {
            background: $primary--10;
            .inlineSVG {
              path {
                fill: $primary;
              }
            }
          }
          &.unknown {
            background: $grey--background;
            .inlineSVG {
              path {
                fill: $grey;
              }
            }
          }
        }
        h2 {
          font-size: 1.125em;
          line-height: 1.125em;
          font-weight: 600;
          margin: 0;
          padding: 0;
        }
      }
    }
    .recordStatisticWrapper {
      .statisticLabel {
        font-size: 1em;
      }
    }
    p {
      margin-top: 1em;
      margin-bottom: 0;
    }
  }
}

