/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.svgCenterBarWrapper {
  rect {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}