/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;
.multiSelectOptionsWrapper {
  position: fixed;
  z-index: 99999999;
  .multiselectShade {
    width: 100vw;
    height: 100vh;
    z-index: 58;
  }
  .multiSelectOptions {
    border: 1px solid $grey--icon;
    border-radius: $borderRadius--input;
    background: #fff;
    z-index: 59;
    width: 100%;
    box-shadow: $boxShadow;
    max-height: 24em;
    overflow: auto;
    .option {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5em;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      text-transform: none;
      color: $darkBlue;
      .assetTagListItem {
        padding: 0.5em 1em;
        border-radius: $borderRadius--full;
        font-weight: 600;
        margin: 0;
        list-style: none;
        font-size: 0.75em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
      &:hover {
        background: $grey--divider;
      }
      &:first-of-type {
        border-radius: $borderRadius $borderRadius 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 $borderRadius $borderRadius;
      }
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.5em;
        &.unchecked {
          opacity: 0.6;
        }
      }
    }
    &.twoColumn {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1em;
    }
  }
}

// the browser default select field can be swapped out with a more stylyzed dropdown component
.fieldWrapper.select {
  .dropdownWrapper.asSelect {
    width: 100%;
    .dropdownTrigger {
      appearance: none;
      background-color: #fff;
      background-repeat: no-repeat;
      background-size: auto;
      background-image: url('./../../../../../../images/icons/input/select-arrow.svg');
      background-position: calc(100% - 0.75em) 50%;
      padding: 0.5em 2.5em 0.5em 1.125em;
      border: 1px solid rgba( $grey--icon, 0.75 );
      color: rgba( $darkBlue, 0.85 );
      box-shadow: $boxShadow--input;
      border-radius: $borderRadius--input;
      margin: 0;
      width: 100%;
      font-size: 1.125em;
      span {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        .inlineSVG {
          margin-right: 0.5em;
        }
      }
      img {
        display: block;
        width: 1.375em;
      }
      .logoWrapper {
        width: 2.125em;
        height: 2.125em;
        border-radius: $borderRadius--full;
        margin-right: 0.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background: $grey--background;
      }
      .inlineSVG {
        width: 1.375em;
        height: 1.375em;
      }
    }
  }
  .multiselectShade {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 58;
  }
  .multiSelectWrapper {
    display: relative;
    .emptyState {
      background: $grey--background;
      padding: 1em;
      font-size: 1.25em;
      border-radius: $borderRadius;
      a {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .inlineSVG {
          width: 1em;
          height: 1em;
          margin-left: 0.5em;
        }
      }
    }
    .selectFieldWrapper.noInput {
      padding: 0.5em 1em 0.5em 0.5em;
      background: #fff;
      border: 1px solid rgba( $grey--icon, 0.75 );
      border-radius: $borderRadius--input;
      color: rgba( $darkBlue, 0.85 );
      box-shadow: $boxShadow--input;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: 20em;
      z-index: 59;
      min-height: 3.125em;
      line-height: 1em;
      .carretIcon {
        width: 1.25em;
        height: 1.25em;
        transition: all 0.3s ease-in-out;
        flex-shrink: 0;
      }
      &.open {
        .carretIcon {
          transform: rotate( 180deg );
        }
      }
      .selectedOptions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-right: 0.5em;
        margin-bottom: -0.5em;
        width: calc( 100% - 1.75em );
        flex-grow: 1;
        flex-shrink: 1;
        .placeholder {
          text-transform: none;
          margin-left: 0.5em;
        }
        .selected {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          text-transform: none;
          color: $darkBlue;
          font-size: 0.875em;
          border-radius: 0.25em;
          background: $grey--divider;
          padding: 0.25em 0.5em;
          margin-right: 0.5em;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          flex-shrink: 0;
          flex-grow: 1;
          margin-bottom: 0.5em;
          .assetTagListItem {
            padding: 0.5em 1em;
            border-radius: $borderRadius--full;
            width: calc( 100% - 1.5em );
            font-weight: 600;
            margin: 0;
            list-style: none;
          }
          strong {
            width: calc( 100% - 1.5em );
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .inlineSVG, .removeButton {
            width: 1.25em;
            height: 1.25em;
          }
          .removeButton {
            margin-left: 0.5em;
            opacity: 0.6;
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            background: rgba( $grey--icon, 0.6 );
            .removeButton {
              opacity: 1;
            }
          }
        }
      }
    }
    .multiSelectOptions {
      position: absolute;
      border: 1px solid $grey--icon;
      border-radius: $borderRadius--input;
      background: #fff;
      top: 5em;
      z-index: 60;
      width: 100%;
      box-shadow: $boxShadow;
      .option {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5em;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        text-transform: none;
        color: $darkBlue;
        .assetTagListItem {
          padding: 0.5em 1em;
          border-radius: $borderRadius--full;
          font-weight: 600;
          margin: 0;
          list-style: none;
          font-size: 0.75em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
        }
        &:hover {
          background: $grey--divider;
        }
        &:first-of-type {
          border-radius: $borderRadius $borderRadius 0 0;
        }
        &:last-of-type {
          border-radius: 0 0 $borderRadius $borderRadius;
        }
        .inlineSVG {
          width: 1.5em;
          height: 1.5em;
          margin-right: 0.5em;
          &.unchecked {
            opacity: 0.6;
          }
        }
      }
      &.twoColumn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1em;
      }
    }
    &.disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}

.dropdownMenu.selectFieldMenuWrapper {
  top: 4em;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  max-height: 0;
  background: #fff;
  border: 1px solid $grey--divider;
  border-radius: $borderRadius--input;
  box-shadow: $boxShadow;
  ul {
    cursor: default;
    padding: 0.5em;
    li {
      cursor: default;
      width: 100%;
      margin-bottom: 0;
      .selectIemWrapper {
        width: 100%;
        border-radius: none;
        background: #fff;
        text-decoration: none;
        color: $darkBlue;
        text-align: left;
        padding: 0.5em 1em 0.5em 2em;
        border-radius: $borderRadius;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        cursor: pointer;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
        img, .inlineSVG {
          display: block;
          width: 1.375em;
          height: 1.375em;
        }
        .logoWrapper {
          width: 2.125em;
          height: 2.125em;
          border-radius: $borderRadius--full;
          margin-right: 0.5em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          background: $grey--background;
          img, .inlineSVG {
            margin-right: 0;
          }
        }
        &:hover {
          background: $grey--divider;
        }
        &.asDivider {
          margin-bottom: 0;
          padding: 0;
          cursor: default;
          padding-left: 1em;
          padding-top: 0.5em;
          margin-top: 0.5em;
          border-top: 1px solid $grey--divider;
          border-radius: 0;
          .dividerLabelWrapper {
            text-transform: uppercase;
            // font-weight: 600;
            color: $grey;
            font-size: 0.875em;
          }
          &:hover {
            background: #fff;
          }
        }
        .ratingBadge.beta {
          width: 4.5em;
          justify-content: space-around;
          &:hover {
            color: $primaryBlue;
            background: $primaryBlue--background;
          }
        }
        .ratingBadge.new {
          width: 4.5em;
          justify-content: space-around;
          &:hover {
            color: $status--green;
            background: $status--green--background;
          }
        }
      }
      &:first-child {
        .selectIemWrapper {
          &.asDivider {
            border-top: none;
            margin-top: 0;
          }
        }
      }
      &:hover {
        color: unset;
      }
    }
  }
  &.visible {
    max-height: 50vh;
  }
}

// default browser input style overrides
.selectFieldWrapper {
  position: relative;
  max-width: 100%;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: auto;
    background-image: url('./../../../../../../images/icons/input/select-arrow.svg');
    background-position: calc(100% - 0.75em) 50%;
    padding: 0.5em 0.75em;
    padding-right: 2.5em;
    border: 1px solid rgba( $grey--icon, 0.75 );
    border-radius: $borderRadius--input;
    color: rgba( $darkBlue, 0.85 );
    box-shadow: $boxShadow--input;
    margin: 0;
    &:focus {
      outline: none;
    }
    optgroup {
      color: $grey;
      option {
        color: $darkBlue;
      }
    }
    option.original {
      background: $grey--divider;
      font-weight: 600;
    }
  }
  &.isMulptiple {
    width: 100%;
    select {
      width: 100%;
      padding-right: 1em;
      -webkit-appearance: none;
      appearance: none;
      border: none;
      background: none;
      padding: 0.5em;
      background: #fff;
      border: 1px solid rgba( $grey--icon, 0.75 );
      border-radius: $borderRadius--input;
      color: rgba( $darkBlue, 0.85 );
      box-shadow: $boxShadow--input;
      &:focus {
        outline: none;
      }
      option {
        padding: 0.5em 1em;
        cursor: pointer;
        &:focus,
        &:active,
        &:checked {
          background-image: $grey--background;
        }
        &:first-child {
          border-radius: $borderRadius $borderRadius 0 0;
        }
        &:last-child {
          border-radius: 0 0 $borderRadius $borderRadius;
        }
      }
    }
    .deselectButton {
      @include dsButton('status-red');
      margin-top: 1em;
    }
  }
  &.asButton {
    select {
      appearance: none;
      background-color: $darkBlue;
      border: 1px solid $darkBlue;
      color: #fff;
      background-image: url('./../../../../../../images/icons/input/select-arrow-light.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 0.5em) 50%;
      border-radius: $borderRadius--input;
      option {
        background-color: #fff;
        color: #000;
      }
      &:focus {
        color: #fff;
      }
    }
  }
  &.asset_tag_ids {
    .multiSelectWrapper {
      .selectFieldWrapper.noInput {
        .selectedOptions {
          .selected {
            background: none;
          }
        }
      }
    }
  }
}
