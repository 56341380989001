/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

#scanningContainer {
  width: 100%;
  background: $grey--background--light;
  padding-bottom: 4.5em;
  .pageHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey--icon;
    padding: 1em 1.5em;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    box-shadow: $boxShadow;
    h2 {
      font-family: $sans;
      color: $darkBlue;
    }
  }
  .setupFormContainer {
    position: relative;
    width: 100%;
    height: 100%;
    .scanGroupDefaultHeader {
      position: absolute;
      left: 0;
      top: 5em;
      width: 50%;
      display: flex;
      padding: 1em 0 0 4em ;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      font-size: 1.25em;
      font-weight: 600;
      .helpTrigger {
        width: 0.75em;
        height: 0.75em;
      }
    }
    .validFormWrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      .formGroupSection.windowsDomainsGroup, .formGroupSection.scanDefaults, .formGroupSection.settings {
        width: 50%;
      }
      .formGroupSection.windowsDomainsGroup {
        padding: 2em;
        border-radius: $borderRadius;
        border: 1px solid $grey--icon;
        background: $grey--background--light;
        margin-right: 2em;
        order: 1;
      }
      .formGroupSection.scanDefaults {
        padding: 2em;
        padding-top: 6em;
        background: rgba($darkBlue, 0.02);
        border: 1px solid $grey--icon;
        border-radius: $borderRadius;
        margin-right: 2em;
      }
      .formGroupSection.settings {
        order: 2;
      }
    }
  }
}
