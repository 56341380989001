/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.exploitStatusBreakdownWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  label {
    width: 100%;
    font-size: 0.875em;
    font-weight: 600;
    margin-bottom: 1em;
  }
  .svgDonutWrapper {
    height: 12em;
    width: 12em;
  }
}