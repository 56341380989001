/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.selectV2Wrapper {
  .labelWrapper {
    width: 100%;
    margin-bottom: .5em;
    // font-size: .875em;
    font-weight: 500;
    // text-transform: uppercase;
    color: $darkBlue;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    line-height: 1.5em;
    label {
      &:before {
        left: -1.5em;
      }
    }
  }
  .selectV2Trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0.5em 0.5em 0.75em;
    border: 1px solid rgba( $grey--icon, 0.75 );
    border-radius: $borderRadius--input;
    color: rgba( $darkBlue, 0.85 );
    box-shadow: $boxShadow--input;
    background: #fff;
    font-size: 1em;
    min-width: 20em;
    .selectCarretIcon {
      margin-left: 0.5em;
      width: 1.125em;
      height: 1.125em;
      transition: all 200ms ease-in-out;
      path: {
        fill: $darkBlue;
      }
    }
    .selectionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .selectionWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        &.withRemoveButton {
          padding: 0.25em 0.5em;
          border-radius: $borderRadius;
          background: $grey--background;
          margin-right: 0.5em;
          button {
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            margin-left: 0.25em;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;
            .inlineSVG {
              width: 1.125em;
              height: 1.125em;
              path {
                fill: $darkBlue;
              }
            }
          }
        }
        li {
          margin: 0;
          padding: 0;
          list-style: none;
        }
      }
    }
    &.fetchNeeded {
      input[ type="text" ] {
        color: $darkBlue;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: #fff;
        line-height: 1em;
        font-size: 1em;
        &:focus {
          outline: none;
          background: none;
          border-radius: 0;
        }
      }
    }
    &.showOptions {
      .selectCarretIcon {
        transform: rotate( -180deg);
      }
    }
  }
}

#selectV2OptionsPortal {
  .selectV2OptionsShade {
    z-index: 99999998;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: none;
  }
  .selectV2Options {
    color: $darkBlue;
    font-family: $sans;
    z-index: 99999999;
    padding: 0.5em;
    border: 1px solid rgba( $grey--icon, 0.75 );
    border-radius: $borderRadius--input;
    color: $darkBlue;
    box-shadow: $boxShadow;
    background: #fff;
    max-height: 50vh;
    overflow: auto;
    .selectV2Option {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0.375em 0.75em;
      cursor: pointer;
      transition: all 200ms ease-in-out;
      margin-bottom: 0.375em;
      border-radius: $borderRadius;
      border: 1px solid transparent;
      label {
        margin-right: 0.5em;
      }
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        background: $grey--background;
      }
      &.isSelected {
        background: $grey--divider;
        border: 1px solid $grey--divider;
      }
      &.isHovered {
        background: $grey--background;
        border: 1px dashed $grey--icon
      }
    }
  }
}