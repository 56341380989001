/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

#pageContent.smtpPageContainer {
  .setupFormContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }
  .testFormWrapper {
    padding: 1em 2em;
    border-radius: $borderRadius;
    background: #fff;
    .formWrapper {
      .fieldGroupWrapper {
        padding: 0
      }
    }
    button {
      @include dsButton( 'minimal-primary' );
      justify-content: space-around;
      text-align: center;
    }
  }
}