/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss"as *;

#pageContent.agentless_settingsPageContainer {
  .formWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }
}
