/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../../styles/globals.scss" as *;

#pageContent.agentsPageContainer, #pageContent.user_managedPageContainer {
  .EmptyState {
    width: 100%;
  }
  .sectionHeader {
    padding: 1em 0;
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 1em;
  }
  .accordionWrapper, .accordionWrapper.collapsed {
    border: 1px solid $grey--icon;
    border-radius:  0.125em;
    margin-top: 1em;
    .accordionBody {
      background: none;
    }
  }
  .accordionWrapper.collapsed {
    .accordionHeader {
      border-bottom: none;
    }
  }
  .setupFormContainer.agentsConfigurationsPage {
    padding: 2em;
    border-radius: $borderRadius;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  section.domainsSection, section.scheduleSection {
    width: 50%;
    border-top: none;
    border-bottom: none;
    box-shadow: none;
  }

  section.domainsSection {
    width: calc( 100% - 20em );
    padding-left: 2em;
    order: 3;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .toggleWrapper {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      .toggle {
        border-radius: $borderRadius--input;
        background: #fff;
        color: $darkBlue;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.5em 1em;
        border: 1px solid $darkBlue;
        transition: color 0.3s ease-in-out;
        cursor: pointer;
        font-size: 1em;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          margin-right: 0.5em;
          transition: all 0.3s ease-in-out;
          path {
            fill: $darkBlue;
            transition: all 0.3s ease-in-out;
          }
        }
        &:first-of-type {
          border-radius: $borderRadius--input 0 0 $borderRadius--input;
        }
        &:last-of-type {
          border-radius: 0 $borderRadius--input $borderRadius--input 0;
        }
        &.selected, &:hover {
          background: $darkBlue;
          color: #fff;
          .inlineSVG {
            path {
              fill: #fff;
            }
          }
        };
      }
    }
    .setupTabs {
      z-index: 1;
    }
    .downloadButton {
      @include dsButton('minimal-green');
      margin-bottom: 1em;
      width: 9em;
    }
  }

  section.scheduleSection {
    width: 20em;
    padding-right: 2em;
    order: 2;
    margin-top: 2.5em;
    ul.scheduleWrapper {
      li {
        margin-bottom: 0.5em;
        strong {
          display: inline-block;
          margin-right: 0.5em;
          margin-bottom: 0.5em;
        }
        ul {
          li {
            list-style: disc;
            display: list-item;
            margin-left: 1.5em;

            strong {
              text-transform: none;
              color: $darkBlue;
              margin: 0;

            }
          }
        }
      }
    }
  }

  .documentationWrapper {
    border: 1px solid $grey--icon;
    border-radius: 0 0 0.125em 0.125em;
    padding: 2em;
    margin: 0;
    width: 100%;
    .documentationStep {
      pre {
        ul {
          li {
            display: flex;
          }
        }
      }
    }
    .inlineLink.newTabLink {
      display: inline-flex;
      width: unset;
    }
  }
}