/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.noThirdPartyEmptyState {
  width: 100%;
  padding: 2em;
  background: $status--red--10;
  border-radius: $borderRadius;
  align-items: center;
  margin: 2em 0;
  grid-column: 1 / 3;
  .inlineSVG {
    width: 2em;
    height: 2em;
    margin-bottom: 2em;
    path {
      fill: $status--red;
    }
  }
  p {
    font-size: 1.25em;
    line-height: 1.75em;
    color: $darkBlue;
    a {
      font-weight: 600;
      color: $status--red;
    }
  }
  &.hasHosts {
    background: $grey--divider;
    .inlineSVG {
      path {
        fill: $darkBlue;
      }
    }
  }
}