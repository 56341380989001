/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.colorSelect {
  .dropdownWrapper.asSelect {
    width: 100%;
    .dropdownTrigger {
      appearance: none;
      background-color: $grey--background--light;
      background-repeat: no-repeat;
      background-size: auto;
      background-image: url('./../../../../../../images/icons/input/select-arrow.svg');
      background-position: calc(100% - 0.75em) 50%;
      padding: 0.25em 2.5em 0.25em 0.25em;
      border: 1px solid $grey--icon;
      border-radius: $borderRadius--input;
      margin: 0;
      width: 100%;
      font-size: 1em;
      span.triggerColorBlock {
        border-radius: $borderRadius--full;
        height: 2em;
        width: 100%;
        text-align: center;
        font-weight: 600;
        line-height: 2em;
        display: block;
      }
    }
  }
}

.dropdownMenu.colorSelectMenu {
  width: 20em !important;
  max-height: 50vh;
  overflow: auto;
  max-height: 0;
  background: #fff;
  border: 1px solid $grey--icon;
  border-radius: $borderRadius--input;
  box-shadow: $boxShadow--heavy;
  padding: 0;;
  ul {
    cursor: default;
    padding: 0.5em;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    grid-auto-flow: column;
    li {
      cursor: default;
      background: #fff;
      text-decoration: none;
      color: $darkBlue;
      text-align: left;
      margin-bottom: 0em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      strong.colorBlock {
        height: 2em;
        width: 100%;
        text-align: center;
        border-radius: $borderRadius--full;
      }
      &:hover {
        color: unset;
      }
    }
  }
  &.visible {
    max-height: 50vh;
  }
}