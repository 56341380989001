/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.persistentAlertWrapper {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 999999;
  width: calc( 30vw );
  cursor: move;
}

#navigationMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 5.25em;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 1003;
  padding: 1em 0em 1em 1em;
  &:hover {
    .leftNavLower {
      .expandButton {
        display: flex;
      }
    }
  }
  &:hover, &.expanded {
    width: 26em;
    #navigationMenu {
      width: 24em;
      .logoContainer {
        width: 22em;
        a {
          padding: 0 0.125em;
          .inlineSVG {
            &.logoText {
              display: block;
            }
          }
        }
      }
    }

    #lowerItems {
      width: 22em;
      padding-left: 1em;
      padding-right: 1em;
      .lowerItemWrapper {
        &.addToPlan, &.helpCenter {
          display: flex;
        }
      }
    }
  }
}

// left nav
#navigationMenu {
  width: 4.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  box-shadow: $boxShadow;
  background: $darkBlue;
  background: -moz-linear-gradient(0deg, rgba(66,109,159,1) 0%, rgba(51,77,110,1) 33.333%);
  background: -webkit-linear-gradient(0deg, rgba(66,109,159,1) 0%, rgba(51,77,110,1) 33.333%);
  background: linear-gradient(0deg, rgba(66,109,159,1) 0%, rgba(51,77,110,1) 33.333%);
  border-radius: $borderRadius--input;
  transition: all 0.1s ease-in-out;
  .leftNavUpper, .leftNavLower {
    width: 100%;
    position: relative;
  }
  .leftNavLower {
    .expandButton {
      padding: 0;
      border: none;
      background: rgba( #fff, 0.2 );
      border-radius: $borderRadius--full;
      width: 2.25em;
      height: 2.25em;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: all 0.1s ease-in-out;
      display: none;
      cursor: pointer;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: $grey--divider;
        }
      }
      &:hover {
        background: rgba( #fff, 0.4 );
        .inlineSVG {
          path {
            fill: #fff;
          }
        }
      }
    }
    .logoutButton {
      padding: 0;
      border: none;
      background: rgba( #fff, 0.2 );
      border-radius: $borderRadius--full;
      width: 2.25em;
      height: 2.25em;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: all 0.1s ease-in-out;
      cursor: pointer;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: $grey--divider;
        }
      }
      &:hover {
        background: rgba( #fff, 0.4 );
        .inlineSVG {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .logoContainer {
    border-bottom: 1px solid rgba( #fff, 0.25 );
    width: 2.25em;
    padding-bottom: 0.5em;
    a {
      padding: 0 0.125em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      .inlineSVG {
        width: 2em;
        &.logoText {
          width: calc( 2em * 6.333333 );
          height: 2em;
          display: none;
          margin-left: 1em;
          &.demoVersion {
            height: 2.75em;
            margin-top: 0.5em;
            margin-left: 0.875em;
          }
        }
      }
    }
  }
  #lowerItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 1em auto;
    padding-top: 2em;
    border-top: 1px solid rgba( #fff, 0.25 );
    .lowerItemWrapper {
      width: 2.5em;
      height: 2.5em;
      border-radius: $borderRadius--full;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: rgba( #fff, 0.1 );
      transition: all 0.25s ease-in-out;
      cursor: pointer;
      position: relative;
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
        opacity: 1;
        path {
          opacity: 1;
          fill: $grey--icon;
        }
      }
      .avatarInitials {
        width: 2.25em;
        height: 2.25em;
        line-height: 2.25em;
        cursor: pointer;
      }
      .onboardingWarning {
        border: 2px solid #fff;
        width: 1.25em;
        height: 1.25em;
        border-radius: $borderRadius--full;
        background: $critical;
        position: absolute;
        right: -0.25em;
        top: -0.25em;
        &.high {
          background: $high;
        }
        &.moderate {
          background: $moderate;
        }
        &.low {
          background: $low;
        }
        &.minimal {
          background: $minimal;
        }
      }
      .addToPlanMenuWrapper {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        .addToPlanMenuTriggerWrapper {
          .notificationCount {
            background: $primaryBlue;
          }
        }
      }
      .userMenuShade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
      }
      .userMenuWrapper {
        background: #fff;
        box-shadow: $boxShadow;
        border-radius: $borderRadius--input;
        padding: 1em;
        position: fixed;
        left: 26em;
        bottom: 1em;
        width: 16.5em;
        li {
          margin: 0;
          padding: 0;
          a, .notLink {
            color: $darkBlue;
            font-weight: 600;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 0.5em 0.75em;
            border-radius: $borderRadius;
            height: unset;
            .ratingBadge {
              margin-left: 0.75em;
              font-weight: 400;
            }
            &:hover {
              background: $grey--divider;
            }
          }
        }
      }
      &.addToPlan, &.helpCenter {
        display: none;
      }
      &:hover {
        background: rgba( #fff, 0.2 );
        .inlineSVG {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

#topBar {
  position: sticky;
  top: 0em;
  padding-top: 1em;
  min-height: unset;
  background: $grey--background;
  margin-bottom: 1em;
  margin-left: 1em;
  z-index: 1002;
  width: calc( 100% - 1em );
  transition: padding 0.1s ease-in-out;
  &.leftNavExpanded {
    padding-left: 19.75em;
  }
  .innerTopBarWrapper {
    padding: 1em;
    padding-left: 1.5em;
    box-sizing: border-box;
    background: #fff;
    background: linear-gradient(180deg, $grey--background 0%, #fff 4.5em);
    border: 1px solid rgba( $grey--icon, 0.5 );
    box-shadow: $boxShadow--light;
    border-radius: $borderRadius--input;
  }

  #globalHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .titleAndSelectorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  #pageCreateButtonContainer.left {
    .showAllUsersButton {
      @include dsButton( 'minimal-dark' );
      margin-right: 1em;
      margin-left: 0;
      .inlineSVG {
        path {
          fill: $darkBlue;
        }
      }
    }
    .riskInsightBackButton {
      @include dsButton( 'minimal-darkRed' );
      margin-left: 0;
      margin-right: 1em;
      .inlineSVG {
        path {
          fill: $risk--darkRed;
        }
      }
    }
    &.high, &.High {
      .riskInsightBackButton {
        @include dsButton( 'minimal-red' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: $risk--red;
          }
        }
      }
    }
    &.moderate, &.Moderate {
      .riskInsightBackButton {
        @include dsButton( 'minimal-orange' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: $risk--orange;
          }
        }
      }
    }
    &.low, &.Low {
      .riskInsightBackButton {
        @include dsButton( 'minimal-yellow' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: $risk--yellow;
          }
        }
      }
    }
    &.minimal, &.Minimal {
      .riskInsightBackButton {
        @include dsButton( 'minimal-green' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: $risk--green;
          }
        }
      }
    }
    &.unknown, &.Unknown {
      .riskInsightBackButton {
        @include dsButton( 'minimal-dark' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: $darkBlue;
          }
        }
      }
    }
  }
  h1 {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    font-family: $sans;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    color: $darkBlue;
    margin-right: 0.5em;
    white-space: nowrap;
    // padding-right: 1em;
    // border-right: 1px solid $grey--icon;
    margin-right: 1em;
    .inlineSVG {
      margin-right: 0.75em;
      path {
        fill: $grey--icon;
        &.gearIcon {
          fill: $primaryBlue;
        }
      }
    }
    .ratingBadge.beta {
      font-size: 0.625em;
    }
  }
  .rightHeaderContent {
    min-width: 20em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  #pageCreateButtonContainer {
    button, .dropdownButtonTrigger, .dropdownTrigger {
      @include dsButton( 'minimal-primary' );
      padding: 0.625em 1.25em;
      margin-left: 1em;
      font-weight: 600;
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
        path {
          fill: $primaryBlue;
        }
      }
    }
    .dropdownMenu.visible {
      top: 3.75em;
      button {
        @include dsButton( 'minimal-primary' );
        padding: 0.375em 1em;
        margin: 1em;
      }
    }
    .pageSettingsButton {
      margin: 0;
      padding: 0.5em;
      opacity: 0.6;
      border: none;
      background: none;
      cursor: pointer;
      display: block;
      transition: all 0.3s ease-in-out;
      margin-left: 0.5em;
      border-radius: $borderRadius--full;
      width: 2.5em;
      height: 2.5em;
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
      }
      &:hover {
        opacity: 1;
        background: $grey--divider;
      }
    }
  }
  #pageAlertsContainer {
    button {
      &.showNotificationsButton {
        width: 2.5em;
        height: 2.5em;
        border-radius: $borderRadius--full;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        transition: all 0.3s ease-in-out;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        background: $grey--background;
        opacity: 1;
        margin: 0 1em;
        position: relative;
        .inlineSVG {
          flex-shrink: 0;
          path {
            fill: $darkBlue;
            transition: all 0.3s ease-in-out;
          }
        }
        .notificationsCount {
          background: $status--red;
          top: -0.75em;
          right: -0.75em;
          width: 1.75em;
          height: 1.75em;
          line-height: 1.75em;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: $borderRadius--full;
          font-size: 0.875em;
          font-weight: 600;
          white-space: nowrap;
          margin: 0;
          margin-left: 0;
          color: #fff;
          .inlineSVG {
            path {
              fill: #fff;
            }
          }
        }
        &:hover {
          background: $grey--divider;
          .inlineSVG {
            path {
              fill: $darkBlue;
            }
          }
        }
      }
    }
    .HeaderConfigureButton {
      @include dsButton( 'minimal-dark' );
      padding: 0.625em 1.25em;
      margin-left: 1em;
    }
  }
  #pageHeaderContainer {
    border-top: 1px solid $grey--divider;
    padding-top: 1em;
    margin-top: 1em;
  }
}