/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "globals" as *;

svg.graph-node,svg.graph-node-selected {
  alignment-baseline: hanging;
  overflow: visible;
  cursor: pointer;
}

.graph-node-select-box {
  stroke: $darkBlue;
  fill: none;
}

.graph-edge, .graph-edge-selected {
  fill: none;
  stroke-opacity: 0.5;
  stroke-width: 10px;
}

.graph-edge-selected {
  stroke-opacity: 1.0;
  stroke-width: 14px;
}

.graph-arrow
{
    cursor: pointer;
}

.graph-arrow polygon
{
    fill: white;
}
/* Had to emove this convenient method of changing size because Firefox doesn't support setting rx/ry via CSS, AFAICT
.graph-arrow ellipse
{
    rx: 25px;
    ry: 21px;
}
*/
.graph-arrow:hover polygon,.graph-arrow-selected
{
    fill: white;
}
/*
.graph-arrow-selected ellipse
{
    rx: 28px;
    ry: 24px;
}
*/

.scopeDragIcon {
  opacity: 0.3;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}



.graph-scope-g {
  -webkit-filter: drop-shadow( 0px 2px 20px rgba(0,0,0, .08) );
  filter: drop-shadow( 0px 2px 20px rgba(0,0,0, .08));
}
.graph-scope-box {
  fill: #fff;
  opacity: 0.8;
  stroke: $grey--icon;
}

.graph-scope-label {
  fill: none;
}

svg.graph-scope-label {
  overflow: visible;
  cursor: pointer;
}

text.graph-label,text.graph-image-label {
  fill: $darkBlue;
  font-weight: 600;
  font-family: $sans;
  alignment-baseline: text-before-edge;
  /*
     Firefox needs dominant-baseline because it doesn't properly support alignment-baseline
     See: https://bugzilla.mozilla.org/show_bug.cgi?id=1381788
   */
  dominant-baseline: text-before-edge;
}

rect.graph-label-background {
  /*stroke-width: 5px;*/
  /*stroke: $primaryBlue;*/
  fill: #fff;
}

text.graph-image-label
{
    fill: $darkBlue;
    font-weight: bold;
    // font-size: 15pt;
}

// svg.graph-node:hover rect.graph-label-background
// {
//     /*stroke-width: 5px;*/
//     /*stroke: $primaryBlue;*/
//     fill: $primaryBlue--dark;
// }

svg.graph-node-selected {
  text.graph-label {
    fill: #fff;
  }
  rect.graph-label-background {
    /*stroke-width: 5px;*/
    /*stroke: $primaryBlue;*/
    fill: $darkBlue;
  }
}

rect.graph-label-border
{
    stroke-width: 4px;
    stroke: $darkBlue;
    fill: none;
}

.hidden {
  visibility: hidden;
  pointer-events: none;
  cursor: default;
}

.graph-label {
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
