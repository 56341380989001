/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.scanning_age_breakdown {
  .widgetContent {
    display: grid;
    grid-template-columns: 1fr 16em;
    grid-column-gap: 1em;
    height: unset;
    p {
      grid-column: 1 / 3;
      width: 100%;
      padding: 0.5em 1em;
      border-radius: $borderRadius;
      background: $grey--background;
    }
    .svgDonutWrapper, .chartWrapper {
      padding-top: 0.5em;
      min-height: 14em;
      max-height: 14em;
    }
  }
  &.widgetHeight-4 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 9em;
        max-height: 9em;
      }
    }
  }
  &.widgetHeight-5 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 13.5em;
        max-height: 13.5em;
      }
    }
  }
  &.widgetHeight-6 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 18em;
        max-height: 18em;
      }
    }
  }
  &.widgetHeight-7 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 22.5em;
        max-height: 22.5em;
      }
    }
  }
  &.widgetHeight-8 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 26.5em;
        max-height: 26.5em;
      }
    }
  }
}