/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.patchRollupModal--shade {
  background: rgba($darkBlue, 0.2);
  transition: all 0.3s ease-in-out;
}

.patchRollupModal {
  width: 34em;
  padding-top: 0em !important;
  .modalBody {
    padding: 1em;
    .patchRollupHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      strong {
        font-weight: 600;
        margin-right: 0.25em;
      }
      .inlineSVG {
        margin-right: 0.5em;
        path {
          fill: $risk--darkRed;
        }
      }
      &.risk-high {
        .inlineSVG {
          path {
            fill: $risk--red;
          }
        }
      }
      &.risk-moderate {
        .inlineSVG {
          path {
            fill: $risk--orange;
          }
        }
      }
      &.risk-low {
        .inlineSVG {
          path {
            fill: $risk--yellow;
          }
        }
      }
      &.risk-minimal {
        .inlineSVG {
          path {
            fill: $risk--green;
          }
        }
      }
    }
    .detailSectionDescription {
      width: 100%;
      background: $grey--background;
      font-size: 0.875em;
      padding: 0.5em 1em;
      border-radius: $borderRadius;
      display: block;
      margin-bottom: 1em;
      margin-top: 1em;
      strong, span {
        display: inline;
        margin-right: 0.25em;
      }
      a {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        color: $primaryBlue;
        font-weight: 600;
        .inlineSVG {
          width: 0.75em;
          height: 0.75em;
          margin-left: 0.5em;
          path {
            fill: $primaryBlue;
          }
        }
      }
    }
    .emptyState {
      font-size: 1.25em;
    }
  }
}

.patchItemWithRollupWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.25em;
  padding-right: 0.25em;
  a {
    flex-grow: 1;
    padding-right: 0.25em;
  }
}

.showRollupButton {
  width: 2em;
  height: 2em;
  background: $critical--10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: $borderRadius--full;
  border: none;
  margin-right: 0.25em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
  .inlineSVG {
    flex-shrink: 0;
    width: 1.25em;
    height: 1.25em;
    path {
      fill: $risk--darkRed;
    }
  }
  &:hover {
    opacity: 1;
  }
  &.risk-high {
    background: $high--10;
    .inlineSVG {
      path {
        fill: $risk--red;
      }
    }
  }
  &.risk-moderate {
    background: $moderate--10;
    .inlineSVG {
      path {
        fill: $risk--orange;
      }
    }
  }
  &.risk-low {
    background: $low--10;
    .inlineSVG {
      path {
        fill: $risk--yellow;
      }
    }
  }
  &.risk-minimal {
    background: $minimal--10;
    .inlineSVG {
      path {
        fill: $risk--green;
      }
    }
  }
}