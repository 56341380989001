/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.widgetDescriptionContainer {
  padding: 0.375em 0.75em;
  background: $grey--background;
  border-radius: $borderRadius;
  margin-bottom: 0.5em;
  grid-column: 1 / 3;
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    display: inline;
    padding: 0;
    background: none;
    .inlineSVG {
      width: 0.875em !important;
      height: 0.875em !important;
      margin-left: 0.25em !important;
    }
    &:hover {
      background: none;
    }
  }
}