/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.riskInsightVisualWrapper.instance {
  height: unset;
}
.vulnerabilityInstancesVisualWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: $darkBlue;
  h3 {
    align-self: flex-start;
    margin-bottom: 0.5em;
    font-size: 1.25em;
    font-weight: 400;
    strong {
      font-weight: 600;
    }
  }
  .excludeNotScannedToggle {
    position: absolute;
    right: 3.5em;
    top: 1.5em;
    width: 24em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    span {
      font-weight: 600;
      margin-left: 0.5em;
    }
  }
  &.dashboardVersion {
    .excludeNotScannedToggle {
      top: 0em;
      right: 0.5em;
    }
  }
  svg.instancesBreakdown {
    width: 100%;
    height: 10em;
    margin-bottom: 0.5em;
    .legendLine {
      transition: all 0.3s ease-in-out;
    }
    .instanceCategoryRect {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover, &.isHovered {
        fill-opacity: 0.75;
      }
    }
  }

  .instanceCategoryLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25em;
    background: none;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 0.5em;
    border-radius: $borderRadius;
    cursor: pointer;
    .instanceCategoryColor {
      width: 1em;
      height: 1em;
      border-radius: $borderRadius--full;
      flex-shrink: 0;
      margin-right: 0.5em;
      &.parent {
        display: none;
      }
    }
    .instanceCategoryLabelWrapper {
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: flex-start;
      span {
        margin-right: 0.25em;
        &.instanceCategoryColor {
          margin-right: 0.5em;
        }
      }
    }
    .helpTrigger {
      width: 1em;
      height: 1em;
      .inlineSVG {
        width: 1em;
        height: 1em;
        margin: 0;
      }
    }
    .removeCategoryFilterButton {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      margin-left: 0.5em;
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: space-around;
      .inlineSVG {
        width: 1em;
        height: 1em;
        transition: all 0.3s ease-in-out;
        path {
          transition: all 0.3s ease-in-out;
          fill: $darkBlue !important;
        }
        &:hover {
          path {
            fill: $darkBlue;
          }
        }
      }
    }
    &:hover, &.isHovered {
      background: $grey--divider;
      .removeCategoryFilterButton {
        .inlineSVG {
          path {
            fill: $darkBlue;
          }
        }
      }
    }
    &.parent {
      margin-bottom: 0.125em;
      .instanceCategoryLabelWrapper {
        font-size: 1.125em;
      }
    }
    &.indented {
      padding-left: 1em;
    }
    &.empty {
      cursor: default;
      opacity: 0.3;
      &:hover {
        background: none;
      }
    }
    &.isFilteringBy {
      background: $grey--divider;
      &:hover {
        background: none;
      }
    }
  }

  .legendsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .categoryLegendWrapper {
    .legendItemsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2em;
      // border-radius: $borderRadius--input;
      // background: rgba( $status--green--10, 0.7 );
      // border: 1px solid $status--green--25;
      padding-top: 1em;
      margin-top: 0.5em;
      border-top: 1px solid $grey--divider;
      label {
        text-transform: uppercase;
        font-size: 0.75em;
        margin-bottom: 0.5em;
        font-weight: 600;
        color: $grey--icon;
      }
    }
    &.deprioritized {
      width: 24em;
      .legendItemsWrapper {
        grid-template-columns: 1fr;
      }
      .instanceCategoryLabel {
        justify-content: flex-end;
        &.isHovered, &.isFilteringBy {
          background: $status--green--25;
          .removeCategoryFilterButton {
            path {
              fill: $status--green--75;
            }
            &:hover {
              path {
                fill: $status--green;
              }
            }
          }
        }
      }
    }
    &.for_review {
      width: calc( 100% - 48em - 4em );
      margin-left: 2em;
      margin-right: 2em;
      .instanceCategoryLabel {
        justify-content: space-between !important;
        &.isHovered, &.isFilteringBy {
          background: $status--red--20;
          .removeCategoryFilterButton {
            path {
              fill: $status--red--60 !important;
            }
            &:hover {
              path {
                fill: $status--red !important;
              }
            }
          }
        }
        &.for_review {
          justify-content: space-around !important;
        }
        &.missing_host {
          justify-content: space-between;
          &.isHovered, &.isFilteringBy {
            background: rgba( $grey--icon, 0.5);
            .removeCategoryFilterButton {
              path {
                fill: $grey !important;
              }
              &:hover {
                path {
                  fill: $darkBlue !important;
                }
              }
            }
          }
        }
      }
      .legendItemsWrapper {
        .instanceCategoryLabel {
          justify-content: flex-start;
        }
      }
    }
    &.prioritized {
      width: 24em;
      .legendItemsWrapper {
        grid-template-columns: 1fr;
      }
      .instanceCategoryLabel {
        &.isHovered, &.isFilteringBy {
          background: $status--red--40;
          .removeCategoryFilterButton {
            path {
              fill: $status--red--60;
            }
            &:hover {
              path {
                fill: $status--red;
              }
            }
          }
        }
      }
    }
  }

  // floating hover label for particular category
  .categoryLabelContainer {
    padding: 1em 1.5em;
    border-radius: $borderRadius--input;
    box-shadow: $boxShadow--light;
    position: fixed;
    background: #fff;
    width: 30em;
    z-index: 999999999;
    justify-content: flex-start;
    align-items: flex-start;
    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-weight: 400;
      font-size: 1.125em;
      margin-bottom: 1em;
      .labelColor {
        width: 1em;
        height: 1em;
        border-radius: $borderRadius--full;
        margin-right: 0.5em;
      }
      strong {
        font-weight: 600;
        margin-left: 0.25em;
      }
    }
    &.visible {
      opacity: 1;
    }
  }
}

.dashboardWidgetWrapper.vulnerability_instances_global {
  .widgetContent {
    .vulnerabilityInstancesVisualWrapper {
      height: 100%;
      svg.instancesBreakdown {
        height: calc( 100% - 10em );
      }
    }
  }
  &.withDescription {
    .widgetContent {
      padding-top: 2em;
      .vulnerabilityInstancesVisualWrapper {
        svg.instancesBreakdown {
          height: calc( 100% - 20em );
        }
      }
    }
    &.widgetHeight-4 {
      .vulnerabilityInstancesVisualWrapper {
        svg.instancesBreakdown {
          height: calc( 100% - 11em );
        }
        .legendItemsWrapper {
          display: none;
        }
      }
    }
  }
  &.truncated {
    .widgetContent {
      .vulnerabilityInstancesVisualWrapper {
        height: 100%;
        svg.instancesBreakdown {
          height: calc( 100% - 4em );
        }
      }
    }
    &.withDescription {
      .widgetContent {
        .vulnerabilityInstancesVisualWrapper {
          height: 100%;
          svg.instancesBreakdown {
            height: calc( 100% - 11.5em );
          }
        }
      }
    }
  }
}