/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.cvssCategoriesBreakdownWrapper {
  color: $darkBlue;
  h3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    margin: 0;
    padding: 1em 0;
    strong {
      span {
        font-weight: 400;
      }
    }
  }
}
