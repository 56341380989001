/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.recordCardContainer.recordCardType_vulnerability {
  .contentGridWrapper {
    .mainContent {
      .recordCardHeader.vulnerability {
        .recordCardHeaderRow {
          .cvssRatingBadge {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
}