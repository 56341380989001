/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.loadingContainer {
  background: rgba(#fff, 0.85);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  .loadingWrapper {
    align-items: center;
    flex-direction: row;
    height: 6em;
  }
  .loadingBlocksContainer {
    position: relative;
    width: 2em;
    height: 6em;
    .loadingBlock {
      width: 2em;
      height: 2em;
      border: .125em solid $primaryBlue;
      background: none;
      position: absolute;
      top: 1.75em;
      left: 0;
      animation: rotate 5s infinite ease-in-out;
      &.two {
        top: 2em;
      }
      &.three {
        top: 2.25em;
      }
      &.four {
        top: 2.5em;
      }
    }
  }
  .messageContainer {
    width: auto;
    margin-left: .75em;
    height: 6em;
    line-height: 6em;
    font-size: 2em;
    color: $darkBlue;
    font-family: $sans;
  }
}

.minimalLoadingAnimationWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 1em;
  .loadingText{
    width: 100%;
    display: block;
    position: absolute;
    top: 40%;
    left: 0;
    text-align: center;
    font-size: 1.25em;
    font-weight: 400;
    color: $grey;
  }
  svg {
    height: 100%;
    width: 100%;
  }
  .loadingRect {
    transition: all .5s ease-in-out;
  }
}


@keyframes rotate {
  0%   {
    transform: rotate(45deg);
  }

  50%   {
    transform: rotate(315deg);
  }
  100%   {
    transform: rotate(-45deg);
  }
}
