/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.graphModelNodeGroup {

  .svgNodeIconOutline {
    fill: #fff;
  }
  .draggingMask, .pathDraggingMask {
    cursor: pointer;
  }
  text {
    opacity: 0.8;
    &::selection {
      background: none;
    }
  }
  &.default {
    .nodeIconCircle {
      fill: $darkBlue--50;
    }
    .nodeHoverCircle {
      fill: $darkBlue--50;
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: $darkBlue--50;
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: $darkBlue--75;
      }
      .nodeHoverCircle {
        fill: $darkBlue--75;
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: $darkBlue--75;
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.high {
    .nodeIconCircle {
      fill: $high--50;
    }
    .nodeHoverCircle {
      fill: $high--50;
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: $high--50;
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: $high--75;
      }
      .nodeHoverCircle {
        fill: $high--75;
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: $high--75;
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.moderate {
    .nodeIconCircle {
      fill: $moderate--50;
    }
    .nodeHoverCircle {
      fill: $moderate--50;
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: $moderate--50;
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: $moderate--75;
      }
      .nodeHoverCircle {
        fill: $moderate--75;
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: $moderate--75;
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.low {
    .nodeIconCircle {
      fill: $low--50;
    }
    .nodeHoverCircle {
      fill: $low--50;
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: $low--50;
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: $low--75;
      }
      .nodeHoverCircle {
        fill: $low--75;
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: $low--75;
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.minimal {
    .nodeIconCircle {
      fill: $minimal--50;
    }
    .nodeHoverCircle {
      fill: $minimal--50;
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: $minimal--50;
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: $minimal--75;
      }
      .nodeHoverCircle {
        fill: $minimal--75;
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: $minimal--75;
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.primary {
    .nodeIconCircle {
      fill: $primary--50;
    }
    .nodeHoverCircle {
      fill: $primary--50;
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: $primary--50;
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: $primary--75;
      }
      .nodeHoverCircle {
        fill: $primary--75;
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: $primary--75;
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.sensitive {
    .nodeIndicatorDot {
      fill: $status--red;
    }
    .nodeIconCircle {
      fill: $primary--75;
    }
    .nodeHoverCircle {
      fill: $primary--75;
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: $primary--75;
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: $primary;
      }
      .nodeHoverCircle {
        fill: $primary;
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: $primary;
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.isAdversary {
    .nodeIconCircle {
      fill: $high--50;
    }
    .nodeHoverCircle {
      fill: $high--50;
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: $high--50;
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: $high--75;
      }
      .nodeHoverCircle {
        fill: $high--75;
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: $high--75;
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
}