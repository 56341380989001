/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.alert, .alert-item {
  margin-bottom: 1em;
  padding: 0.5em 1em;
  border-radius: $borderRadius;
  color: #fff;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  background: $risk--red;
  .inlineSVG {
    margin-right: 0.5em;
    height: 1.25em;
  }
  p {
    line-height: 1.5em;
    margin: 0;
    padding: 0;
  }
  a {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  &.warning {
    background: $primaryOrange;
  }
}

.notification {
  border-radius: $borderRadius;
  border: 1px solid $status--red;
  background: $status--red--light;
  color: $status--red;
  font-weight: bold;
  font-family: $sans;
  position: relative;
  margin: 1em 0;
  .notificationHeader {
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: $borderRadius $borderRadius 0 0;
    box-sizing: border-box;
    padding: 0.5em;
    width: calc(100% + 2px);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    color: #fff;
    text-transform: uppercase;
    background: $status--red;
    font-size: 0.875em;
    .inlineSVG {
      height: 1.25em;
      width: 1.25em;
      margin-right: .5em;
    }
    button {
      border: none;
      background: none;
      cursor: pointer;
      position: absolute;
      top: 0.375em;
      right: 1.25em;
      padding: 0;
      margin: 0;
      width: 1em;
      height: 1em;
      .inlineSVG {
        height: 1.25em;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .notificationBody {
    padding: 3em 1em 1em 1em;
    display: block;
    p {
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0em;
      }
    }
    a {
      color: $darkBlue;
      display: inline;
      opacity: 1;
    }
    ul {
      margin-top: 0.5em;
      background: none;
      margin-left: 1em;
      max-height: 12em;
      overflow: auto;
      li {
        background: none;
        padding: 0;
        list-style: disc;
        margin-bottom: 0.5em;
        a {
          background: none !important;
          padding: 0 !important;
          color: $status--red !important;
          opacity: 1;
        }
      }
    }
    .additionalContent {
      margin-top: 1em;
      a {
        margin-left: 0.25em;
      }
    }
  }
  &.success {
    background: $status--green--light;
    border: 1px solid $status--green;
    .notificationHeader {
      background: $status--green;
    }
    .notificationBody {
      color: $status--green;
      ul {
        li {
          a {
            color: $status--green !important;
          }
        }
      }
    }
  }
  &.warning {
    background: $status--yellow--light;
    border: 1px solid $status--yellow;
    .notificationHeader {
      background: $status--yellow;
    }
    .notificationBody {
      color: $status--yellow;
      ul {
        li {
          a {
            color: $status--yellow !important;
          }
        }
      }
    }
  }
  &.info {
    background: $status--blue--light;
    border: 1px solid $status--darkBlue;
    .notificationHeader {
      background: $status--darkBlue;
    }
    .notificationBody {
      color: $status--darkBlue;
      ul {
        li {
          a {
            color: $status--darkBlue !important;
          }
        }
      }
    }
  }


  &.flash {
    margin: 0;
    opacity: 0;
    box-shadow: $boxShadow--heavy;
    z-index: 9999999;
    position: fixed;
    bottom: 6em;
    right: -28em;
    transition: all 0.15s ease-in-out;
    max-width: 50vw;
    &.visible {
      right: 1em;
      opacity: 1;
    }
    &.top {
      bottom: unset;
      top: 7em;
    }
  }
}
