/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.onboardingWizardContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: $grey--background;
  // background: -moz-linear-gradient(0deg, rgba(66,109,159,0.98) 0%, rgba(51,77,110,0.98) 50%);
  // background: -webkit-linear-gradient(0deg, rgba(66,109,159,0.98) 0%, rgba(51,77,110,0.98) 50%);
  // background: linear-gradient(0deg, rgba(66,109,159,0.98) 0%, rgba(51,77,110,0.98) 50%);

  z-index: 99999;
  font-family: $sans;
  color: $darkBlue;
  margin-top: 200vh;
  transition: all 0.15s ease-in-out;
  &.visible {
    margin-top: 0;
    .onboardingModalFooter, .exitOnboardingButton {
      display: flex;
    }
    .onboardingShade {
      display: block;
    }
  }

  .onboardingShade {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
  }

  .exitOnboardingButton {
    position: fixed;
    top: 1em;
    right: 1em;
    cursor: pointer;
    color: $primaryBlue;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
    text-transform: uppercase;
    padding: 1em 2em;
    border-radius: $borderRadius;
    font-weight: 600;
    display: none;
    z-index: 1;
    .inlineSVG {
      height: 2em;
      width: 2em;
      path {
        fill: $darkBlue;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  .welcomeMessage {
    margin: 4em auto 0em auto;
    h2 {
      color: $darkBlue;
      font-size: 2em;
      text-align: center;
      font-weight: 400;
    }
  }
  .toggleWrapper {
    margin: 2em auto;
    z-index: 1;
    .toggleButton {
      .ratingBadge {
        margin-right: 1em;
        background: $status--green;
        color: #fff;
      }
    }
  }
  .welcomeWrapper {
    p {
      text-align: center;
      font-size: 1.25em;
      padding: 0 2em;
    }
    p:first-of-type {
      margin-top: 2em;
    }
    .welcomeChoiceContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      .imgColumn {
        width: 50%;
        padding: 2em;
        button {
          @include dsButton('primary');
          margin-top: 2em;
        }
      }
    }
  }
  .collapsingStepsWrapper {
    background: #fff;
    border-radius: 0.5em;
    border: 1px solid $grey--divider;
    box-shadow: $boxShadow--heavy;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: 95vh;
    overflow: auto;
    padding-bottom: 5em;
    z-index: 1;
    margin-top: 4em;
    .scanningModeHeader {
      margin-top: 2em;
      font-size: 1.5em;
    }
    .scanningModeDescription {
      text-align: center;
      border-bottom: 1px solid $grey--divider;
      color: $grey;
      width: 100%;
      font-size: 1.25em;
      padding: 1em 2em 2em 2em;
      margin-bottom: 0;
    }
    .collapsingStep {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5em 2em;
      border-bottom: 1px solid $grey--divider;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      z-index: 1;
      span.stepBadge {
        border-radius: $borderRadius--full;
        background: $grey--background;
        width: 2.25em;
        height: 2.25em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 2em;
        flex-shrink: 0;
        border: 1px solid $grey--icon;
        span {
          font-size: 1.125em;
          font-weight: 600;
        }
        .inlineSVG {
          display: none;
        }
        &.configured {
          background: $status--green;
          border: 1px solid $status--green;
          span {
            display: none;
          }
          .inlineSVG.check {
            display: block;
            width: 1.25em;
          }
        }
        &.incomplete {
          border: 1px solid $status--red;
          background: $status--red;
          span {
            display: none;
          }
          .inlineSVG.alertImg {
            display: block;
            height: 1.25em;
          }
        }
      }
      .stepDescription {
        width: calc( 100% - 15em );
        transition: all 0.3s ease-in-out;
        height: 4.4em;
        overflow: hidden;
        .notification {
          display: none;
        }
        strong {
          margin-bottom: 0.5em;
        }
        a {
          display: none;
          .inlineSVG {
            height: 1.25em;
          }
        }
      }
      .linkContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 16em;
      }
      .stepStatusLink {
        transition: all 0.15s ease-in-out;
        border-radius: 1.25em;
        width: 2.5em;
        height: 2.5em;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
        text-decoration: none;
        padding: 0 1em;
        justify-self: right;
        span {
          display: none;
          margin: 0 0.5em 0 0;
          font-weight: 600;
        }
        .inlineSVG {
          height: 1.25em;
          display: none;
          flex-shrink: 0;
          margin-left: 0;
          margin-right: 0;
          &.go {
            display: block;
          }
        }
        &:hover {
          padding: 0 1.5em;
          background: $darkBlue;
          color: #fff;
          width: auto;
          span {
            display: block;
            color: #fff;
            &.revisit, &.fix {
              display: none;
            }
          }
          .inlineSVG.go {
            display: none;
          }
          .inlineSVG.goLight {
            display: block;
          }
        }
        &.configured {
          &:hover {
            span {
              &.revisit {
                display: block;
              }
              &.configure, &.fix {
                display: none;
              }
            }
          }
        }
        &.incomplete {
          &:hover {
            span {
              &.fix {
                display: block;
              }
              &.configure, &.revisit {
                display: none;
              }
            }
          }
        }
      }
      &.expanded {
        background: rgba( $primary, 0.05 );
        .stepDescription {
          height: auto;
          .notification {
            display: block;
          }
          a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
          }
        }
        .linkContainer {
          .stepStatusLink {
            padding: 0 1.5em;
            background: $grey--divider;
            width: auto;
            span {
              display: block;
              color: $darkBlue;
              &.revisit, &.fix {
                display: none;
              }
            }
            &.configured {
              span {
                &.revisit {
                  display: block;
                }
                &.configure, &.fix {
                  display: none;
                }
              }
            }
            &.incomplete {
              span {
                &.fix {
                  display: block;
                }
                &.configure, &.revisit {
                  display: none;
                }
              }
            }
            &:hover {
              background: $darkBlue;
              span {
                color: #fff;
              }
            }
          }
        }
        &:hover {
          background: rgba( $primary, 0.05 );
        }
      }
      &:first-of-type {
        border-top: 1px solid $grey--divider;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &:hover {
        background: rgba( $primary, 0.05 );
      }
    }
  }
  .onboardingModalFooter {
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    background: #fff;
    border-top: 1px solid $grey--icon;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 10vw;
    box-shadow: $boxShadow--heavy;
    z-index: 999999;
    font-weight: 600;
    display: none;
    button {
      @include dsButton('minimal-primary');
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .inlineSVG {
        margin-left: 0.5em;
        margin-right: 0;
        path {
          fill: $primaryBlue;
        }
      }
      &:nth-of-type(1) {
        .inlineSVG {
          margin-left: 0;
          margin-right: 0.5em;
        }
      }
    }
  }
}