/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.globalRiskTriggerWrapper {
  font-family: $sans;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: $borderRadius--input;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0 1em;
  .riskButtonSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    color: $darkBlue;
    font-size: 1em;
    background: $grey--background;
    padding: 0.625em 1em;
    border-radius: $borderRadius--input;
    .iconWrapper {
      width: 1.5em;
      height: 1.5em;
      background: rgba( #fff, 0.85 );
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-right: 0.5em;
      border-radius: $borderRadius--full;
      .inlineSVG {
        flex-shrink: 0;
        width: 1em;
        height: 1em;
      }
    }
  }
  &.risk--critical {
    .riskButtonSection {
      color: #fff;
      background: $critical;
      .iconWrapper {
        .inlineSVG {
          path {
            fill: $critical;
          }
        }
      }
    }
  }
  &.risk--high {
    .riskButtonSection {
      color: #fff;
      background: $high;
      .iconWrapper {
        .inlineSVG {
          path {
            fill: $high;
          }
        }
      }
    }
  }
  &.risk--moderate {
    .riskButtonSection {
      color: #fff;
      background: $moderate;
      .iconWrapper {
        .inlineSVG {
          path {
            fill: $moderate;
          }
        }
      }
    }
  }
  &.risk--low {
    .riskButtonSection {
      color: #fff;
      background: $low;
      .iconWrapper {
        .inlineSVG {
          path {
            fill: $low;
          }
        }
      }
    }
  }
  &.risk--minimal {
    .riskButtonSection {
      color: #fff;
      background: $minimal;
      .iconWrapper {
        .inlineSVG {
          path {
            fill: $minimal;
          }
        }
      }
    }
  }
  &.risk--primary {
    .riskButtonSection {
      color: #fff;
      background: $primary;
      .iconWrapper {
        .inlineSVG {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
  &.displayModesLength--2 {
    .riskButtonSection {
      border-radius: $borderRadius--input 0 0 $borderRadius--input;
      &:nth-of-type( 2 ) {
        border-radius: 0 $borderRadius--input $borderRadius--input 0;
      }
    }
    &.risk--critical {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $critical;
          background: $critical--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $critical;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: $critical;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $critical;
              }
            }
          }
        }
      }
    }
    &.risk--high {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $high;
          background: $high--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $high;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: $high;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $high;
              }
            }
          }
        }
      }
    }
    &.risk--moderate {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $moderate;
          background: $moderate--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $moderate;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: $moderate;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $moderate;
              }
            }
          }
        }
      }
    }
    &.risk--low {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $low;
          background: $low--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $low;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: $low;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $low;
              }
            }
          }
        }
      }
    }
    &.risk--minimal {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $minimal;
          background: $minimal--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $minimal;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: $minimal;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $minimal;
              }
            }
          }
        }
      }
    }
    &.risk--primary {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $primary;
          background: $primary--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $primary;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: $primary;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }
  &.displayModesLength--3 {
    .riskButtonSection {
      border-radius: $borderRadius--input 0 0 $borderRadius--input;
      &:nth-of-type( 2 ) {
        border-radius: 0;
      }
      &:nth-of-type( 3 ) {
        border-radius: 0 $borderRadius--input $borderRadius--input 0;
      }
    }
    &.risk--critical {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $critical;
          background: $critical--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $critical;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: $critical;
          background: $critical--30;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $critical;
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: $critical;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $critical;
              }
            }
          }
        }
      }
    }
    &.risk--high {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $high;
          background: $high--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $high;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: $high;
          background: $high--30;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $high;
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: $high;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $high;
              }
            }
          }
        }
      }
    }
    &.risk--moderate {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $moderate;
          background: $moderate--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $moderate;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: $moderate;
          background: $moderate--30;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $moderate;
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: $moderate;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $moderate;
              }
            }
          }
        }
      }
    }
    &.risk--low {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $low;
          background: $low--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $low;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: $low;
          background: $low--30;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $low;
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: $low;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $low;
              }
            }
          }
        }
      }
    }
    &.risk--minimal {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $minimal;
          background: $minimal--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $minimal;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: $minimal;
          background: $minimal--30;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $minimal;
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: $minimal;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $minimal;
              }
            }
          }
        }
      }
    }
    &.risk--primary {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: $primary;
          background: $primary--10;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $primary;
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: $primary;
          background: $primary--30;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $primary;
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: $primary;
          .iconWrapper {
            .inlineSVG {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.globalRiskDisplay {
  background: $risk--darkRed;
  opacity: 0.8;
  padding: 1em 1.5em;
  border-radius: 0.5em;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1em auto;
  label {
    color: #fff;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.25em;
    .inlineSVG {
      margin-right: 0.5em;
      width: 1.5em;
      height: 1.5em;
      path {
        fill: #fff;
      }
    }
  }
  strong {
    font-size: 4em;
    font-weight: 400;
    width: 100%;
    text-align: center;
    color: #fff;
  }
  &.high {
    background: $risk--red;
  }
  &.moderate {
    background: $risk--orange;
  }
  &.low {
    background: $risk--yellow;
  }
  &.minimal {
    background: $risk--green;
  }
}

.topBarRiskScoreModal {
  width: 72em !important;
  left: calc( 50vw - 36em ) !important;
  .modalBody {
    .riskDisplayModesWrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 1em;
      margin: 2em 0;
      &.displayModesLength--2 {
        grid-template-columns: 1fr 1fr;
      }
      &.displayModesLength--3 {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    .optionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 36em;
      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 2em;
        .labelWrapper {
          width: unset;
          margin: 0;
          margin-left: 0.5em;
        }
      }
    }
    .labelWrapper {
      width: 100%;
      margin-bottom: .5em;
      // font-size: .875em;
      font-weight: 500;
      // text-transform: uppercase;
      color: $darkBlue;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      line-height: 1.5em;
    }
    .formWrapper {
      .selectFieldWrapper.percentCurrentRisk {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .labelWrapper {
          width: unset;
          margin-right: 0.5em;
        }
        select {
          width: 6em;
          margin-right: 0.5em;
        }
      }
    }
  }
}