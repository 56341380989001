/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.checkbox {
  label {
    flex-direction: row;
    flex-wrap: wrap;
    transition: 0.3s ease-in-out;
    align-items: center;
    .labelWrapper {
      flex-grow: 1;
      margin-left: 0.5em;
      text-transform: none;
      color: $grey;
      font-size: 1em;
      width: auto;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      input[type="text"], input[type="number"] {
        background: $grey--divider;
        border: 1px solid $grey--icon;
        border-radius: 0.25em;
        color: $darkBlue;
        width: 4em;
        margin-left: 1em;
        margin-top: 0;
        padding: 0.25em 0.5em;
      }
    }
    input[type='checkbox'] {
      margin: 0;
      width: auto;
      transform: scale(1);
    }
    &.asToggleField{
      background: none;
      .checkboxFieldWrapper {
        background: none;
      }
      .labelWrapper {
        padding-left: 1.5em;
      }
      .toggleWrapper {
        border-radius: $borderRadius--full;
        background: $grey--divider;
        width: 3em;
        height: 1.25em;
        background-image: none;
        position: relative;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
        flex-shrink: 0;
        position: absolute;
        top: 0.25em;
        .toggleSwitch {
          position: absolute;
          top: 2px;
          left: 0;
          margin-left: 2px;
          transition: all 0.15s ease-in-out;
          height: calc(1.25em - 4px);
          width: 1.5em;
          background: #fff;
          border-radius: $borderRadius--full;
        }
        &:hover {
          background: $grey--icon;
          .toggleSwitch {
            box-shadow: $boxShadow;
          }
        }
        &.checked {
          background: $primaryBlue;
          .toggleSwitch {
            color: $primaryBlue;
            margin-left: calc( 1.5em - 2px);
          }
          &:hover {
            background: $primaryBlue;
            .toggleSwitch {
              color: $primaryBlue;
            }
          }
        }
      }
    }
  }
  &.updated, &.invalid {
    label {
      &:before{
        top: 0.5em;
      }
      padding-left: 1.25em;
      .labelWrapper {
        padding-left: 0;
      }
      &.asToggleField {
        .labelWrapper {
          padding-left: 1.5em;
        }
      }
    }
  }
}

.checkboxFieldWrapper {
  position: relative;
  width: 1.75em;
  height: 1.75em;
  background-image: url('./../../../../../../images/icons/input/checkbox.svg');
  background-repeat: no-repeat;
  background-size: contain;
  &.checked {
    background-image: url('./../../../../../../images/icons/input/checkbox-checked.svg');
  }
  &.disabled {
    pointer-events: none;
    background-image: url('./../../../../../../images/icons/input/checkbox-disabled.svg');
    &.checked {
      background-image: url('./../../../../../../images/icons/input/checkbox-checked-disabled.svg');
    }
  }
  input {
    width: 0;
    height: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    visibility: hidden;
  }
}
