@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 400;
	src:
		local('Roboto Slab Regular'),
		local('RobotoSlab-Regular'),
		/* from https://fonts.gstatic.com/s/robotoslab/v7/y7lebkjgREBJK96VQi37ZmfQcKutQXcIrRfyR5jdjY8.eot */
		url('Roboto_Slab_400.eot?#iefix') format('embedded-opentype'),
		/* from https://fonts.gstatic.com/s/robotoslab/v7/y7lebkjgREBJK96VQi37ZobN6UDyHWBl620a-IRfuBk.woff */
		url('Roboto_Slab_400.woff') format('woff'),
		/* from https://fonts.gstatic.com/s/robotoslab/v7/y7lebkjgREBJK96VQi37Zo4P5ICox8Kq3LLUNMylGO4.woff2 */
		url('Roboto_Slab_400.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/l/font?kit=y7lebkjgREBJK96VQi37ZnNsigHlcrQGNV8nkUSFQfc&skey=a9ad6a4717e923f0&v=v7#RobotoSlab */
		url('Roboto_Slab_400.svg#RobotoSlab') format('svg');
}
