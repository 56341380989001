/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "globals" as *;

.button-bar, #eula-buttons {
  padding: 0;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 0;
  background: #fff;
  button, span {
    @include dsButton('primary');
    width: 120px;
    flex-grow: 0;
    font-family: $sans;
    font-weight: bold;
    height: 2em;
    line-height: 2em;
    &:nth-of-type(1) {
      @include dsButton('destructive');
      height: 2em;
      line-height: 2em;
    }
    &:last-of-type {
      @include dsButton('primary');
    }
  }
}

#modalCloseButton, .popup-close {
  position: absolute;
  top: 1em;
  right: 1em;
  background: none;
  cursor: pointer;
  opacity: 0.6;
  width: 1.25em;
  height: 1.25em;
  border: none;
  padding: 0;
  margin: 0;
  img {
    width: 1.25em;
  }
  &:hover {
    opacity: 1;
  }
}

// stupid safari, stupid overrides
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {

    .modalContainer.tag {
      overflow: visible;
    }
    #navigationMenu.collapsed ul#menuItems {
      width: 4.25em;
    }
    button {
      // display: block !important;
      // img {
      //   margin-bottom: -1px !important;
      // }
      &.taskRunnerButton {
        display: flex !important;
      }
    }
    #report-options-row,
    #page-content,
    #dataContainer,
    #chartLegend,
    #react-setup-container,
    #react-about-container,
    #reactAboutPage,
    .tabContent,
    .formActions,
    .modalActions,
    .button-bar {
      min-height: unset;
    }
    .linkContainer,
    #globalRiskWrapper,
    .tableActions,
    .setupFormContainer,
    .filterForm,
    .validFormWrapper,
    .formGroupSection {
      min-height: min-content;
    }
    #globalRiskWrapper {
      display: flex !important;
    }
    .insightListHeader,
    .setupPageContainer .pageHeader,
    .filterTestButton,
    .savedFiltersWrapper .saveButtonWrapper {
      min-height: 3em;
    }
    .panelTabs {
      min-height: 2em;
    }
    .config-help {
      height: calc( 100vh - 26em);
    }
    .explore-item {
      flex-shrink: 0;
    }
    .filterWrapper label input[type="date"] {
      width: 90%;
    }
    #report-listing-row {
      #report-listing {
        .report-item, .report-item-selected {
          flex-shrink: 0;
        }
      }
    }
    .report-item-column {
      div {
        flex-shrink: 0;
        &.report-item-list {
          flex-shrink: 1;
        }
      }
    }
}}

div {
  min-height: unset;
}


// for printing
@media print {
  body {
    width: 100%;
    padding: 0;
    margin: 0;
    background: none;
  }
  #reactDialogPortal,
  #reactModalPortal,
  #reactHelpPortal,
  #reactOnboardingPortal,
  #addToMenuPortal,
  #reactToolTipPortal,
  #userMenuTriggerWrapper,
  #topBar,
  #navigationMenu,
  #reactFlashMessageQueuePortal,
  #icicleChartCategoryLabelPortal {
    display: none;
    &.printingFullScreenVisual {
      display: block !important;
      .fullScreenVisualModal {
        display: block !important;
        visibility: visible !important;
        opacity: 1 !important;
        padding: 0 !important;
        .modalBody {
          padding: 0 !important;
        }
        .closeButton,
        .fullScreenVisualToggleButton,
        .fullScreenVisualPrintButton {
          display: none;
        }
        .vulnerabilityInstancesVisualWrapper {
          .instancesBreakdown {
            height: 10em;
          }
          .legendsContainer {
            font-size: 0.625em;
          }
        }
        .dashboardWidget.vulnerability_instances_cvss_breakdown.barchart {
          height: 5.5in;
          .chartWrapper {
            max-height: unset;
            .cvssBarChartWrapper {
              min-height: unset;
              max-height: unset;
            }
          }
        }
      }
    }
  }
  #page-content {
    width: 100%;
    height: unset;
    overflow: visible;
    padding: 0.125in;
    margin: 0;
    background: none;
  }
}
