/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.pathsFromHereDraggableCard {
  position: absolute;
  right: unset;
  right: 1em;
  top: unset;
  bottom: 1em;
  background: #fff;
  padding: 1em;
  border-radius: $borderRadius--input;
  box-shadow: $boxShadow--medium;
  border: 1px solid rgba( $grey--icon, 0.5 );
  overflow: hidden;
  width: 32em;
  .pathsFromHereCardHeader {
    font-size: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: move;
    h2 {
      padding: 0;
      margin: 0;
      font-size: 1em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      .inlineSVG {
        margin-right: 0.5em;
        width: 1.25em;
        height: 1.25em;
        path {
          fill: $grey--icon;
        }
      }
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .headerActions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
    &:hover {
      .draggableIcon {
        path {
          fill: $darkBlue;
        }
      }
    }
  }
  ul {
    li {
      span.name {
        width: 24em !important;
      }
      &.isSelectedPath, &:hover {
        background: $darkBlue--75 !important;
        color: #fff !important;
      }
    }
  }
  &.collapsed {
    bottom: 0.5em;
    border: 1px solid rgba( $grey--icon, 0.5 );
    transform: none !important;
    padding: 0.5em 0.5em 0.5em 0.75em;
    .pathsFromHereCardHeader {
      cursor: default;
      margin: 0;
      h2 {
        font-size: 1em;
        button {

          margin: 0;
          margin-left: 0.5em;
        }
      }
      .selectedCount {
        color: $grey;
        font-weight: 400;
        margin-left: 0.375em;
      }
    }
    ul {
      display: none;
    }
  }
}