/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.vulnerability_instances_tag_breakdown {
  .widgetContent {
    display: grid;
    grid-template-columns: 1fr 20em;
    grid-column-gap: 1em;
    height: unset;
    .svgDonutWrapper, .chartWrapper {
      padding-top: 0.5em;
      min-height: 14em;
      max-height: 14em;
    }
  }
  &.widgetHeight-4 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 12.5em;
        max-height: 12.5em;
      }
    }
    &.withDescription {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 10em;
          max-height: 10em;
        }
      }
    }
  }
  &.widgetHeight-5 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 17em;
        max-height: 17em;
      }
    }
    &.withDescription {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 14.5em;
          max-height: 14.5em;
        }
      }
    }
  }
  &.widgetHeight-6 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 21.5em;
        max-height: 21.5em;
      }
    }
    &.withDescription {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 18.5em;
          max-height: 18.5em;
        }
      }
    }
  }
  &.widgetHeight-7 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 26em;
        max-height: 26em;
      }
    }
    &.withDescription {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 23em;
          max-height: 23em;
        }
      }
    }
  }
  &.widgetHeight-8 {
    .widgetContent {
      .svgDonutWrapper {
        min-height: 30em;
        max-height: 30em;
      }
    }
    &.withDescription {
      .widgetContent {
        .svgDonutWrapper {
          min-height: 27em;
          max-height: 27em;
        }
      }
    }
  }
}