/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.instancesPageContainer.risk_insightContainer {
  .instanceVisualModeToggle {
    position: fixed;
    top: 7em;
    right: 5.5em;
    z-index: 1002;
  }
}
.categoryLabel {
  padding: 1em 1.5em;
  border-radius: $borderRadius--input;
  box-shadow: $boxShadow--light;
  position: fixed;
  background: #fff;
  width: 30em;
  z-index: 999999999;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: $sans;
  color: $darkBlue;
  h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 1.125em;
    margin-bottom: 1em;
    .labelColor {
      width: 1em;
      height: 1em;
      border-radius: $borderRadius--full;
      margin-right: 0.5em;
    }
    strong {
      font-weight: 600;
      margin-left: 0.25em;
    }
  }
  &.visible {
    opacity: 1;
  }
}

#chartWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 25em;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  .icicleColumnWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: hidden;
    .icicleItem {
      white-space: nowrap;
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-around;
      overflow: hidden;
      box-sizing: border-box;
      border: 2px solid #fff;
      border-radius: 0.375em;
      transition: all 0.3s ease-in-out;
      .inlineLabel {
        font-size: 0.875em;
        color: #fff;
        text-align: center;
        opacity: 0;
        font-weight: 400;
        strong {
          margin-bottom: 0.5em;
          font-weight: 600;
        }
        &.dark {
          color: #333;
        }
        &.visible {
          opacity: 1;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.riskInsightTableWrapper {
  .riskInsightDataTable {
    .tableRow {
      .tableCell {
        &.tableCell_risk {
          padding-right: 0.5em;
          .ratingBadge {
            margin-right: 0.75em;
          }
        }
      }
    }
  }
  .instance_riskInsightDataTable {
    .tableRow {
      position: relative;
      grid-template-columns:
        1fr
        8em
        18em
        1fr
        17.75em
        9.5em;
        &.tableHeader {
          position: -webkit-sticky;
          position: sticky;
        }
      .tableCell {
        .VulnerabilityDescriptionContainer {
          display: flex;
          flex-direction: row;
          span {
            color: $primaryBlue;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &.headerCell_Last,
        &.headerCell_description,
        &.headerCell_vulns {
          border-right: 1px solid #fff;
        }
        &.tableCell_description {
          pre {
            flex-grow: 1;
            padding: 0;
            margin: 0;
            background: none;
            font-family: 'Monospace', monospace;
            font-weight: 600;
          }
        }
        &.tableCell_addresses {
          max-height: 5em;
          overflow: hidden;
          align-items: flex-start;
        }
        &.tableCell_actions {
          button {
            flex-shrink: 0;
            &.addFilterButton,
            &.removeFilterButton {
              background: none;
              border: none;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              border-radius: $borderRadius--full;
              width: 2em;
              height: 2em;
              display: flex;
              justify-content: space-around;
              align-items: center;
              flex-shrink: 0;
              margin-right: 0.5em;
              .inlineSVG {
                width: 1.25em;
                height: 1.25em;
                flex-shrink: 0;
                path {
                  fill: $status--red;
                }
              }
              &:hover {
                background: $grey--divider;
              }
            }
            &.addFilterButton {
              .inlineSVG {
                path {
                  fill: $status--green;
                }
              }
            }
          }
        }
        &.tableCell_vulns,
        &.headerCell_vulns,
        &.tableCell_hosts,
        &.headerCell_hosts {
          text-align: center;
          justify-content: space-around;
        }
        &.tableCell_Last, &.headerCell_Last {
          text-align: center;
          justify-content: space-around;
        }
      }
    }
    &.include_risk--true {
      .tableRow {
        grid-template-columns:
          12.5em
          1fr
          8em
          18em
          1fr
          17.75em
          9.5em;
      }
    }
    &.host_groupBy.hasRemediation {
      .tableRow {
        grid-template-columns:
          3em
          1fr
          8em
          18em
          1fr
          17.75em
          9.5em;
      }
      &.include_risk--true {
        .tableRow {
          grid-template-columns:
            3em
            12.5em
            1fr
            8em
            18em
            1fr
            17.75em
            9.5em;
        }
      }
    }
    &.patch_groupBy, &.patch_cumulative_groupBy {
      .tableRow {
        grid-template-columns:
          minmax(4em, 16em)
          8em
          8em
          minmax(6em, 1fr)
          9.5em;
      }
      &.include_risk--true {
        .tableRow {
          grid-template-columns:
            12.5em
            minmax(4em, 16em)
            8em
            8em
            minmax(6em, 1fr)
            9.5em;
        }
      }
      &.hasRemediation {
        .tableRow {
          grid-template-columns:
            3em
            minmax(4em, 16em)
            8em
            8em
            minmax(6em, 1fr)
            9.5em;
        }
        &.include_risk--true {
          .tableRow {
            grid-template-columns:
              3em
              12.5em
              minmax(4em, 16em)
              8em
              8em
              minmax(6em, 1fr)
              9.5em;
          }
        }
      }
    }
    &.vulnerability_groupBy {
      .tableRow {
        grid-template-columns:
          minmax(4em, 16em)
          9em
          10em
          5em
          minmax(6em, 1fr)
          9.5em;
      }
      &.include_risk--true {
        .tableRow {
          grid-template-columns:
            12.5em
            minmax(4em, 16em)
            9em
            4em
            5em
            minmax(6em, 1fr)
            9.5em;
        }
      }
      &.hasRemediation {
        .tableRow {
          grid-template-columns:
            3em
            minmax(4em, 16em)
            9em
            10em
            5em
            minmax(6em, 1fr)
            9.5em;
        }
        &.include_risk--true {
          .tableRow {
            grid-template-columns:
              3em
              12.5em
              minmax(4em, 16em)
              9em
              4em
              5em
              minmax(6em, 1fr)
              9.5em;
          }
        }
      }
    }
    &.signature_groupBy {
      .tableRow {
        grid-template-columns:
          1fr
          2fr
          6em
          6em
          6.5em;
      }
      &.include_risk--true {
        .tableRow {
          grid-template-columns:
            12.5em
            1fr
            2fr
            6em
            6em
            6.5em;
        }
      }
      .tableCell.tableCell_name {
        p {
          width: unset;
          white-space: normal;
          cursor: default;
          margin: 0;
        }
      }
      .tableCell.tableCell_description {
        min-width: 20em;
      }
      .tableCell.tableCell_rating {
        text-align: center;
      }
    }
  }
}