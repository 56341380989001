/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.setupRecordItem.remediation_plan.item {
  position: relative;
  grid-template-columns: 40em 1fr !important;
  color: $darkBlue;
  .itemActions {
    align-items: flex-start !important;
    padding-top: 0 !important;
    padding-right: 0 !important;
    position: absolute;
    top: 1em;
    right: 1em;
    height: 2.5em;
    .exportMenuTrigger {
      margin-right: 0;
    }
  }
  .expandingTasksContainer {
    position: relative;
    grid-column: 1 / 3;
    .showTasksButton {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0;
      margin: 0;
      cursor: pointer;
      opacity: 0.6;
      background: none;
      border: none;
      transition: all 0.3s ease-in-out;
      font-size: 0.875em;
      color: $darkBlue;
      z-index: 1;
      margin-top: -1.25em;
      .inlineSVG {
        margin-left: 0.5em;
        width: 1.25em;
        height: 1.25em;
      }
      &:hover {
        opacity: 1;
      }
    }
    section {
      border-top: 1px solid $grey--divider;
      padding-top: 1em;
      padding-left: 0;
      border-left: none;
      h2 {
        margin-bottom: 1em;
        font-size: 1em;
        color: $darkBlue;
      }
      ul {
        margin: 0;
        width: 100%;
      }
    }
    &.expanded {
      section {
        margin-top: 1em;
      }
      .showTasksButton {
        opacity: 1;
        .inlineSVG {
          transform: rotate(180deg);
        }
      }
    }
    .emptyState {
      font-size: 1.125em;
      padding: 1.125em;
    }
  }
}

.remediationPlanInformation {
  border-right: 1px solid $grey--divider;
  padding-right: 2em;
  h2, h3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .ratingBadge {
      margin-left: 0.5em;
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .recordIconWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 1.75em;
        height: 1.75em;
        border-radius: $borderRadius--full;
        background: $grey--background;
        margin-right: 0.5em;
        .inlineSVG {
          width: 1.125em;
          height: 1.125em;
          path {
            fill: $grey;
          }
        }
      }
    }
  }
  .planStatisticsWrapper, .actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
    .planStatistic, .lowerStat {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      label {
        font-weight: 600;
        margin-right: 0.5em;
      }
      span {
        font-weight: 400;
      }
    }
  }
  &.risk--critical {
    h2, h3, h3 {
      span {
        .recordIconWrapper {
          background: $critical--10;
          .inlineSVG {
            path {
              fill: $critical;
            }
          }
        }
      }
    }
  }
  &.risk--high {
    h2, h3 {
      span {
        .recordIconWrapper {
          background: $high--10;
          .inlineSVG {
            path {
              fill: $high;
            }
          }
        }
      }
    }
  }
  &.risk--moderate {
    h2, h3 {
      span {
        .recordIconWrapper {
          background: $moderate--10;
          .inlineSVG {
            path {
              fill: $moderate;
            }
          }
        }
      }
    }
  }
  &.risk--low {
    h2, h3 {
      span {
        .recordIconWrapper {
          background: $low--10;
          .inlineSVG {
            path {
              fill: $low;
            }
          }
        }
      }
    }
  }
  &.risk--minimal {
    h2, h3 {
      span {
        .recordIconWrapper {
          background: $minimal--10;
          .inlineSVG {
            path {
              fill: $minimal;
            }
          }
        }
      }
    }
  }
  &.risk--primary {
    h2, h3 {
      span {
        .recordIconWrapper {
          background: $primary--10;
          .inlineSVG {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
  }
}
.remediationPlanProgress {
  padding-left: 2em;
  padding-right: 12em;
  .sectionLabel {
    font-weight: 600;
    margin-bottom: 1em;
    font-size: 1em;
  }
  .completionWrapper {
    display: grid;
    grid-template-columns: 1fr 16em;
    grid-column-gap: 1em;
    margin-bottom: 0.5em;
    .completionLabel {
      order: 2;
      text-align: right;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      strong, span {
        margin-left: 0.25em;
      }
      strong {
        font-weight: 600;
      }
    }
    .completionBarWrapper {
      order: 1;
      height: 1em;
      background: $grey--background;
      .completionBar {
        height: 1em;
        min-width: 0.25em;
        background: $grey;
      }
    }
    &.instances {
      .completionBarWrapper {
        .completionBar {
          background: $grey--icon;
        }
      }
    }
  }
  &.risk--critical {
    .completionWrapper {
      .completionLabel {
        strong {
          &.percentage {
            color: $critical;
          }
        }
      }
      .completionBarWrapper {
        .completionBar {
          background: $critical;
        }
      }
      &.instances {
        .completionBarWrapper {
          .completionBar {
            background: $critical--50;
          }
        }
        strong {
          &.percentage {
            color: $critical--50;
          }
        }
      }
    }
  }
  &.risk--high {
    .completionWrapper {
      .completionLabel {
        strong {
          &.percentage {
            color: $high;
          }
        }
      }
      .completionBarWrapper {
        .completionBar {
          background: $high;
        }
      }
      &.instances {
        .completionBarWrapper {
          .completionBar {
            background: $high--50;
          }
        }
        strong {
          &.percentage {
            color: $high--50;
          }
        }
      }
    }
  }
  &.risk--moderate {
    .completionWrapper {
      .completionLabel {
        strong {
          &.percentage {
            color: $moderate;
          }
        }
      }
      .completionBarWrapper {
        .completionBar {
          background: $moderate;
        }
      }
      &.instances {
        .completionBarWrapper {
          .completionBar {
            background: $moderate--50;
          }
        }
        strong {
          &.percentage {
            color: $moderate--50;
          }
        }
      }
    }
  }
  &.risk--low {
    .completionWrapper {
      .completionLabel {
        strong {
          &.percentage {
            color: $low;
          }
        }
      }
      .completionBarWrapper {
        .completionBar {
          background: $low;
        }
      }
      &.instances {
        .completionBarWrapper {
          .completionBar {
            background: $low--50;
          }
        }
        strong {
          &.percentage {
            color: $low--50;
          }
        }
      }
    }
  }
  &.risk--minimal {
    .completionWrapper {
      .completionLabel {
        strong {
          &.percentage {
            color: $minimal;
          }
        }
      }
      .completionBarWrapper {
        .completionBar {
          background: $minimal;
        }
      }
      &.instances {
        .completionBarWrapper {
          .completionBar {
            background: $minimal--50;
          }
        }
        strong {
          &.percentage {
            color: $minimal--50;
          }
        }
      }
    }
  }
}