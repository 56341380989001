/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.axisLinesContainer {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: calc( 100% + 0.5em + 2px );
  width: 100%;
  margin-right: 0.5em;
  padding-bottom: calc( 0.5em + 2px );
  &.yAxis {
    .YAxisLineBox {
      width: 100%;
      border-top: 2px solid $grey--divider;
    }
    &.dashed {
      .YAxisLineBox {
        border-top: 2px dashed $grey--divider;
      }
    }
    &.lineCountClass--4 {
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }
  }
  &.xAxis {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    height:100%;
    width: 100%;
    padding-bottom: calc( 0.5em + 2px );
    .XAxisLineBox {
      height: 100%;
      border-right: 2px solid $grey--divider;
    }
    &.dashed {
      .XAxisLineBox {
        border-right: 2px dashed $grey--divider;
      }
    }
    &.lineCountClass--5 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    &.lineCountClass--4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}